import axios from 'axios';
import { ENV } from '../utils/env';
import AuthService from './KeycloakApi';

export const getAllRtspServerSettings = async () => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/settings/get-rtsp-settings`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getAllSettings = async () => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/settings/get-all`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const saveNewSetting = async (data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/settings/create`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
