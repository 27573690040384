import { Grid } from '@mui/material';
import React from 'react';
import { URLS } from '../../utils/urls.util';
import { CompanyBigItem } from '../CompanyBigItem/CompanyBigItem';
import { AddItem } from '../Default/AddItem/AddItem';

export const CompaniesBigList = ({ companies, selectState, page, permission }) => {
  return (
    <Grid container spacing={2}>
      {
        permission.create && <Grid item md={3}>
          <AddItem
            title="Добавить организацию"
            url={URLS.COMPANIES + '/' + 'create/data?page=' + page}
          />
        </Grid>
      }
      {companies.map((company) => (
        <Grid item md={3} key={company.id}>
          <CompanyBigItem
            company={company}
            selectState={selectState}
            page={page}
          />
        </Grid>
      ))}
    </Grid>
  );
};
