import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { FormValidator } from '../FormValidator/FormValidator';
import { DeviceParameterDefaultData } from '../DeviceParameterDefaultData/DeviceParameterDefaultData';
import { DeviceParameterValidationConfig } from '../../utils/formValidatorConfigs/config';
import { v4 as uuidv4 } from 'uuid';

export const DeviceParameterItemForm = ({
  index,
  parameter,
  disabledField,
  updateParameterHandler,
  closeHandler,
  hideButtons,
  editableParameter,
  setEditableParameter,
  listOfTitle,
  setListOfTitle,
  permission,
  showTitle = true,
}) => {
  const [wasChange, setChange] = useState(false);
  const [defaultDataDisabled, setDefaultDataDisabled] = useState(false);
  const [validateErrors, setValidateErrors] = useState([]);
  const [isError, setIsError] = useState(false);

  const handlerError = (data) => {
    setValidateErrors(data);
  };

  useEffect(() => {
    if (parameter) {
      setEditableParameter(parameter);
    }
  }, [parameter]);

  const config = useMemo(
    () => DeviceParameterValidationConfig({ customUniq: listOfTitle }),
    [],
  );

  const onChange = (value, fieldName) => {
    if (!wasChange) {
      setChange(true);
    }
    setEditableParameter({ ...editableParameter, [fieldName]: value });
  };
  return (
    <>
      {showTitle && (
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 5 }}
        >
          {editableParameter?.id
            ? 'Редактирование параметра'
            : 'Добавление параметра'}
        </Typography>
      )}
      <FormValidator
        name={`rtsp-server-form-name-${uuidv4()}`}
        validationConfig={config}
        changed={editableParameter}
        setErrorsHandler={(data) => {
          if (wasChange) {
            handlerError(data);
          }
        }}
      >
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="Техническое название"
            variant="outlined"
            value={editableParameter?.name}
            name="name"
            required={config?.name?.isRequired}
            error={
              validateErrors?.name?.text ||
              (listOfTitle?.some(
                (item) =>
                  item.id !== editableParameter?.id &&
                  item.name == editableParameter?.name,
              ) &&
                wasChange)
            }
            helperText={
              validateErrors?.name?.text
                ? validateErrors?.name?.text
                : listOfTitle?.some(
                    (item) =>
                      item.id !== editableParameter?.id &&
                      item.name == editableParameter?.name,
                  ) && wasChange
                ? 'Название параметра должно быть уникальным в рамках одной версии и ревизии'
                : ''
            }
            disabled={disabledField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) => {
              onChange(target.value, 'name');
            }}
          />
          <TextField
            fullWidth
            label="Название для отображения"
            variant="outlined"
            value={editableParameter?.description}
            name="description"
            required={config?.description?.isRequired}
            error={validateErrors?.description?.text}
            helperText={validateErrors?.description?.text}
            disabled={disabledField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) => onChange(target.value, 'description')}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <FormControl
            fullWidth
            error={validateErrors?.type?.text}
            required={config?.type?.isRequired}
            disabled={disabledField}
          >
            <InputLabel id="demo-simple-select-label">Тип параметра</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editableParameter?.type || ''}
              name="type"
              label="Тип параметра"
              disabled={disabledField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'type')}
            >
              <MenuItem value="SOFTWARE">SOFTWARE</MenuItem>
              <MenuItem value="HARDWARE">HARDWARE</MenuItem>
            </Select>
            {validateErrors?.type?.text && (
              <FormHelperText>{validateErrors?.type?.text}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            fullWidth
            error={validateErrors?.fieldType?.text}
            required={config?.fieldType?.isRequired}
            disabled={disabledField}
          >
            <InputLabel id="demo-simple-select-label">Тип данных</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editableParameter?.restricts?.type || ''}
              name="fieldType"
              disabled={disabledField}
              label="Тип данных"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => {
                setEditableParameter({
                  ...editableParameter,
                  defaultValue: target.value == 'Boolean' ? false : '',
                  restricts: {
                    type: target.value,
                    defaultValue: target.value == 'Boolean' ? false : '',
                    restricts: {},
                  },
                });
              }}
            >
              <MenuItem value="Boolean">Логическое значение</MenuItem>
              <MenuItem value="Number">Число</MenuItem>
              <MenuItem value="String">Строка</MenuItem>
              <MenuItem value="NumberRange">Диапазон чисел</MenuItem>
              <MenuItem value="Array">Список</MenuItem>
              <MenuItem value="ArrayNumberRange">
                Список диапазона чисел
              </MenuItem>
            </Select>
            {validateErrors?.fieldType?.text && (
              <FormHelperText>{validateErrors?.fieldType?.text}</FormHelperText>
            )}
          </FormControl>
        </Stack>
      </FormValidator>

      {(editableParameter?.type || editableParameter?.restricts) && (
        <DeviceParameterDefaultData
          fieldType={editableParameter?.restricts?.type}
          restrict={{
            ...editableParameter?.restricts,
            defaultValue:
              editableParameter?.restricts?.defaultValue ||
              editableParameter?.defaultValue,
          }}
          disabledField={disabledField}
          parameterIndex={index}
          changeHandler={(restricts) => {
            setEditableParameter({
              ...editableParameter,
              restricts: {
                ...restricts,
              },
            });
          }}
          setDisabled={(data) => setDefaultDataDisabled(data)}
          setIsError={setIsError}
        />
      )}
      <div style={{ marginTop: '20px' }}>
        <FormControlLabel
          control={
            <Switch
              disabled={disabledField}
              checked={editableParameter?.isRequired || false}
              onChange={({ target }) => onChange(target.checked, 'isRequired')}
            />
          }
          label="Обязательно"
        />

        <FormControlLabel
          control={
            <Switch
              disabled={disabledField}
              checked={editableParameter?.readOnly || false}
              onChange={({ target }) => onChange(target.checked, 'readOnly')}
            />
          }
          label="Только для чтения"
        />
      </div>
      {!hideButtons && (
        <Stack direction="row" spacing={2} sx={{ mt: 10 }} justifyContent="end">
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => {
              closeHandler(false);
            }}
          >
            Отмена
          </Button>

          <Button
            variant="contained"
            color="primary"
            disabled={
              Object.keys(validateErrors)?.length ||
              (listOfTitle?.some(
                (item) =>
                  item.id !== editableParameter?.id &&
                  item.name == editableParameter?.name,
              ) &&
                wasChange) ||
              !editableParameter?.name ||
              !editableParameter?.type ||
              !editableParameter?.restricts ||
              !editableParameter?.description ||
              defaultDataDisabled ||
              disabledField
            }
            onClick={() => {
              const id = editableParameter?.id || uuidv4();

              updateParameterHandler({
                id,
                ...editableParameter,
                order: +editableParameter?.order || 0,
              });
              if (listOfTitle?.some((item) => item.id == id)) {
                const listOfTitleNew = listOfTitle.filter(
                  (item) => item.id == id,
                );
                setListOfTitle([
                  ...listOfTitleNew,
                  { id: id, name: editableParameter?.name },
                ]);
              } else {
                setListOfTitle([
                  ...listOfTitle,
                  { id: id, name: editableParameter?.name },
                ]);
              }

              closeHandler(false);
            }}
          >
            Сохранить
          </Button>
        </Stack>
      )}
    </>
  );
};
