import { Button, IconButton, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import styles from './ActionApprove.module.scss';
import CloseIcon from '@mui/icons-material/Close';
export const ActionApproveV2 = ({
  target,
  open,
  title,
  description,
  handleClose,
  handleSuccess,
  showCloseButton = true,
  actionButtonText = 'Удалить',
  handleSuccessText,
  handleCloseText,

  showDescription = false,
}) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.ActionApprove}
    >
      <Box className={styles.ActionApprove__Box} sx={{ textAlign: 'left' }}>
        <IconButton
          aria-label="close"
          color="inherit"
          sx={{ opacity: 0.2 }}
          className={styles.ActionApprove__CloseButton}
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 1, fontSize: '16px', fontWeight: 'bold', pr: '35px' }}
        >
          {title}
        </Typography>
        {showDescription && (
          <Typography variant="p" as="div" sx={{ my: 1 }}>
            {description}
          </Typography>
        )}

        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'end',
            marginTop: '10px',
          }}
        >
          {showCloseButton && (
            <Button
              variant="outlined"
              color="inherit"
              sx={{ mt: 3, ml: 'auto' }}
              onClick={() => handleClose()}
            >
              {handleCloseText || 'Отменить'}
            </Button>
          )}
          <Button
            variant={actionButtonText == 'Удалить' ? 'outlined' : 'contained'}
            color={actionButtonText == 'Удалить' ? 'error' : 'primary'}
            sx={{ mt: 3, ml: 2 }}
            onClick={() => handleSuccess(target)}
          >
            {handleSuccessText || actionButtonText || 'Удалить'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
