import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

export default function ChangeAlert({
  title,
  successHandler,
  cancelHandler,
  isOpen,
}) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button onClick={cancelHandler}>Open modal</Button>
      <Modal
        open={isOpen}
        onClose={cancelHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 3 }}
          >
            {title}
          </Typography>
          <Button
            color="inherit"
            variant="outlined"
            sx={{ mr: 2 }}
            onClick={successHandler}
          >
            Да
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ mr: 2 }}
            onClick={cancelHandler}
          >
            Нет
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
