import { Card, CardContent, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './CompanyBigItem.module.scss';
import { URLS } from '../../utils/urls.util';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';

const Wrapper = ({ children, selectState, company, page }) => {
  const [selected, setSelected] = useState(false);
  const handleSelect = () => {
    setSelected(!selected);
  };
  return !selectState ? (
    <Link
      to={URLS.COMPANIES + '/' + company.id + '/data?page=' + page}
      className={styles.CompanyBigItem}
    >
      {children}
    </Link>
  ) : (
    <div onClick={handleSelect} className={styles.CompanyBigItem__Selected}>
      {selected && (
        <div className={styles.CompanyBigItem__Selected__Popover}></div>
      )}

      {children}
    </div>
  );
};
export const CompanyBigItem = ({
  company,
  selectState,
  handleSelectAction,
  page,
}) => {
  const [title, setTitle] = useState('');
  useEffect(() => {
    setTitle(
      company?.title?.length > 20
        ? company?.title.slice(0, 20) + '...'
        : company?.title,
    );
  }, []);
  return (
    <Wrapper
      company={company}
      selectState={selectState}
      handleSelectAction={handleSelectAction}
      page={page}
    >
      <Tooltip title={company.title}>
        <Card variant="outlined" className={styles.CompanyBigItem__Card}>
          <CardContent>
            <Typography className={styles.CompanyBigItem__Title}>
              {title}
            </Typography>
            <Stack direction="row" spacing={2}>
              <Typography variant="caption">
                <CheckCircleOutlineOutlinedIcon
                  fontSize="13px"
                  color={company.isActive ? 'success' : 'warning'}
                  className={styles.CompanyBigItem__Icon}
                />
                <span>{company.isActive ? 'Опубликована' : 'Черновик'}</span>
              </Typography>
              <Typography variant="caption">
                <SettingsSuggestOutlinedIcon
                  fontSize="13px"
                  color={company.createdInAws ? 'success' : 'error'}
                  className={styles.CompanyBigItem__Icon}
                />
                <span>{company.createdInAws ? 'Есть в АРМ' : 'Нет в Арм'}</span>
              </Typography>
            </Stack>
            <Typography variant="caption" as="div" sx={{ mt: 2 }}>
              Последнее обновление:
            </Typography>
            <Typography variant="caption">
              {format(new Date(company.updated), 'dd.MM.yyyy HH:mm:ss')}
            </Typography>
          </CardContent>
        </Card>
      </Tooltip>
    </Wrapper>
  );
};
