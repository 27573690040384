import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';
import { DeviceParameterItem } from '../DeviceParameterItem/DeviceParameterItem';
import { v4 as uuidv4 } from 'uuid';
import { Stack } from '@mui/system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { DeviceParameterItemForm } from '../DeviceParameterItem/DeviceParameterItemForm';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import arrayMove from 'array-move';

export const DeviceParametersCategoriesList = ({
  category,
  removeHandler,
  updateCategoryParamsHandler,
  updateCategoryHandler,
  isExist,
  parameterCheckerHandler,
  permission,
  finalData,
  listOfTitle,
  setListOfTitle,
}) => {
  const [deviceParametersState, setDeviceParametersState] = useState([]);
  const [updateCategory, setUpdateCategory] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [editableParameter, setEditableParameter] = useState({});
  const [editParameter, setEditParameter] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);

  const ref = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    setUpdateCategory(category);
    setDeviceParametersState(
      category?.accessPointTypeParams.sort((a, b) =>
        a.order > b.order ? 1 : -1,
      ),
    );
  }, [category]);

  const onSortEnd = (oldIndex, newIndex) => {
    setDeviceParametersState((array) => {
      let newArray = arrayMove(array, oldIndex, newIndex);
      newArray = newArray.map((item, index) => ({
        ...item,
        order: index,
      }));

      updateCategoryParamsHandler({
        ...category,
        accessPointTypeParams: newArray,
      });
      return newArray;
    });
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        id={`category-${category?.groupNameOrder}`}
        ref={ref}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Grid container>
            <Grid item xs="10">
              <Stack
                direction="row"
                spacing={2}
                onClick={() => setExpanded(!expanded)}
              >
                {!expanded ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                {!!!category?.accessPointTypeParams?.length ? (
                  <Tooltip title="Группа не содержит в себе параметров">
                    <Stack direction="row">
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          color: '#d32f2f',
                        }}
                      >
                        {category.groupName}
                      </Typography>

                      <InfoOutlinedIcon
                        color="error"
                        sx={{ ml: 1 }}
                        size="small"
                      />
                    </Stack>
                  </Tooltip>
                ) : (
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    {category.groupName}
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs="2" sx={{ display: 'flex', justifyContent: 'end' }}>
              <Stack
                direction="row"
                spacing={1}
                sx={{ m: 0, p: 0, height: '25px' }}
              >
                {permission?.edit && (
                  <IconButton
                    aria-label="delete"
                    size="small"
                    sx={{ m: 0, p: 0 }}
                    onClick={() => updateCategoryHandler(category)}
                    disabled={isExist || !permission?.edit}
                  >
                    <CreateOutlinedIcon />
                  </IconButton>
                )}
                {permission?.edit && (
                  <IconButton
                    aria-label="delete"
                    size="small"
                    sx={{ m: 0, p: 0 }}
                    onClick={() => setOpenApprove(true)}
                    disabled={isExist || !permission?.edit}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                )}
                {permission?.edit && (
                  <SortableKnob>
                    <IconButton
                      aria-label="dragndrop"
                      disabled={isExist || !permission?.edit}
                      size="small"
                      sx={{ m: 0, p: 0 }}
                      ref={buttonRef}
                    >
                      <DragIndicatorOutlinedIcon />
                    </IconButton>
                  </SortableKnob>
                )}
              </Stack>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <SortableList
            onSortEnd={onSortEnd}
            className="list"
            draggedItemClassName="dragged"
          >
            {deviceParametersState?.map((parameter, deviceIndex) => (
              <SortableItem key={deviceIndex}>
                <div>
                  <DeviceParameterItem
                    permission={permission}
                    index={parameter?.order}
                    id={parameter.id || parameter.sortId}
                    parameter={parameter}
                    isExist={isExist}
                    allParams={deviceParametersState}
                    parameterCheckerHandler={(data) =>
                      parameterCheckerHandler(data)
                    }
                    setListOfTitle={(data) => setListOfTitle(data)}
                    listOfTitle={listOfTitle}
                    removeParameterHandler={(id) => {
                      let accessPointTypeParams;
                      accessPointTypeParams = [
                        ...(category?.accessPointTypeParams || [])?.filter(
                          (p, i) => p.id !== id,
                        ),
                      ];
                      updateCategoryParamsHandler({
                        ...category,
                        accessPointTypeParams,
                      });
                    }}
                    updateParameterHandler={(data) => {
                      setEditParameter(true);
                      setEditableParameter({ ...data, editIndex: deviceIndex });
                    }}
                  />
                </div>
              </SortableItem>
            ))}
          </SortableList>

          {permission?.edit && (
            <Button
              variant="text"
              color="primary"
              size="small"
              sx={{ mt: 2 }}
              startIcon={<AddOutlinedIcon />}
              disabled={isExist || !permission?.edit}
              onClick={() => setEditParameter(true)}
            >
              Добавить параметр
            </Button>
          )}

          <Modal
            open={editParameter}
            onClose={() => {
              setEditableParameter(null);
              setEditParameter(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              overflow: 'scroll',
            }}
          >
            <Box
              className="ModalStyle"
              sx={{ minWidth: '750px', height: 'auto', margin: '4% auto' }}
            >
              <DeviceParameterItemForm
                disabledField={!permission?.edit}
                parameter={editableParameter}
                editableParameter={editableParameter}
                setEditableParameter={(data) => {
                  data.defaultValue = data?.restricts?.defaultValue;
                  setEditableParameter(data);
                }}
                currentData={finalData}
                updateParameterHandler={(data) => {
                  let result;
                  const sortableAccessPointTypeParams = [
                    ...deviceParametersState?.filter(
                      (p, i) => data?.id != p?.id,
                    ),
                    {
                      ...data,
                      groupName: category?.groupName,
                      id: data?.id || uuidv4(),
                      order:
                        data?.order == null || data?.order == undefined
                          ? deviceParametersState?.length
                          : data?.order,
                    },
                  ].sort((a, b) => (a.order > b.order ? 1 : -1));
                  result = {
                    ...category,
                    accessPointTypeParams: sortableAccessPointTypeParams,
                  };
                  setEditableParameter(null);

                  updateCategoryParamsHandler(result);
                }}
                closeHandler={() => {
                  setEditableParameter(null);
                  setEditParameter(false);
                }}
                listOfTitle={listOfTitle}
                setListOfTitle={(data) => setListOfTitle(data)}
              />
            </Box>
          </Modal>
        </AccordionDetails>
      </Accordion>
      <ActionApproveV2
        target={category}
        open={openApprove}
        title="Вы уверены что хотите удалить группу параметров из списка?"
        description="При удалении группы будут удалены вложенные в нее параметры"
        handleClose={() => setOpenApprove(false)}
        handleSuccess={() => {
          removeHandler(category);
          setOpenApprove(false);
        }}
        showDescription={true}
      />
    </>
  );
};
