import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { SingleCompanyEmployees } from '../components/SingleCompany/SingleCompanyEmployees';
import { pagesIds } from '../utils/pages';
import { URLS } from '../utils/urls.util';

export const SingleCompanyEmployeesPage = () => {
  const { id } = useParams();

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  return (
    <MainContainer pageId={pagesIds.company} setPermission={setPermission}>
      <SingleCompanyEmployees permission={permission} id={id} tab={1} />
    </MainContainer>
  );
};
