import React, { useEffect, useMemo, useState } from 'react';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { URLS } from '../utils/urls.util';
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import {
  createRole,
  getAllKeycloakGroups,
  getAllUsersByGroupId,
  getOneKeycloakGroup,
  updateRole,
} from '../api/UserApi';
import { RoleFooterButtons } from '../components/RoleFooterButtons/RoleFooterButtons';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/alert.store';
import { ActionApproveV2 } from '../components/Default/ActionApprove/ActionApproveV2';
import { FormValidator } from '../components/FormValidator/FormValidator';
import { SingleRoleValidationConfig } from '../utils/formValidatorConfigs/config';
import { FullPageLayout } from '../components/Default/Layout/FullPageLayout';
import AuthService from '../api/KeycloakApi';
import { RoleInfo } from '../components/RoleInfo/RoleInfo';
import { pagesIds } from '../utils/pages';

export const SingleRolePage = () => {
  const [role, setRole] = useState({});
  const [selectedRole, setSelectedRole] = useState('');
  const [groups, setGroups] = useState([]);
  const [modal, setModal] = useState({});
  const [isReadOnly, setIsReadOnly] = useState(true);
  const getOneKeycloakGroupApi = useApi(getOneKeycloakGroup);
  const getAllKeycloakGroupsApi = useApi(getAllKeycloakGroups);
  const [users, setUsers] = useState([]);
  const [validateData, setValidateData] = useState({});
  const getAllUsersByGroupIdApi = useApi(getAllUsersByGroupId);

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  const fetchUsers = async () => {
    const result = await getAllUsersByGroupIdApi.sendRequest(id, {
      first: 0,
      max: -1,
    });

    setUsers(result);
  };
  const { id } = useParams();
  const createRoleApi = useApi(createRole);
  const updateRoleApi = useApi(updateRole);
  const dispatch = useDispatch();
  const createNewRole = async () => {
    const user = await AuthService.getUserInfo();

    const result = await createRoleApi.sendRequest({
      ...role,
      attributes: {
        ...role?.attributes,
        UpdatedBy: [user.email],
        CreatedBy: [user.email],
      },
    });
    dispatch(
      setAlert({
        status: 201,
        text: `Роль «${
          role?.attributes?.RoleName[0] || role?.name
        }» успешно добавлена`,
      }),
    );
    getOneKeycloakGroupApi.sendRequest(result.id).then((result) => {
      setRole(result);
    });
    navigate(URLS.ROLES + '/' + result.id);
  };
  const updateRoleHandler = async () => {
    const user = await AuthService.getUserInfo();
    const result = await updateRoleApi.sendRequest({
      ...role,
      attributes: {
        ...role?.attributes,
        UpdatedBy: [user.email],
      },
    });
    dispatch(
      setAlert({
        status: 201,
        text: `Роль «${
          role?.attributes?.RoleName[0] || role?.name
        }» успешно обновлена`,
      }),
    );
  };

  useEffect(() => {
    getAllKeycloakGroupsApi
      .sendRequest({
        first: 0,
        max: -1,
      })
      .then(([result, count]) => setGroups(result));
    getOneKeycloakGroupApi.sendRequest(id).then((result) => {
      if (
        result?.attributes?.IsReadOnly &&
        result?.attributes?.IsReadOnly[0] == 'True'
      ) {
        setIsReadOnly(true);
      } else {
        setIsReadOnly(false);
      }

      setRole(result);
    });
    if (id !== 'create') {
      fetchUsers();
    }
  }, []);
  const changeAdminRoleMap = (item, checked, paramKey) => {
    const filtredMap = role?.adminRolesMap?.map((i) => {
      if (item?.params?.some((p) => p.key == paramKey)) {
        item?.params?.map((pp) => {
          if (pp.key == paramKey) {
            pp.value = checked;
          }
          if (
            paramKey == 'View' &&
            ['Edit', 'Create', 'Delete'].includes(pp.key) &&
            !checked
          ) {
            pp.value = false;
          } else if (
            ['Edit', 'Create', 'Delete'].includes(paramKey) &&
            pp.key == 'View' &&
            !pp.disabled &&
            checked
          ) {
            pp.value = true;
          }

          if (paramKey == 'Edit' && ['Create'].includes(pp.key) && !checked) {
            pp.value = false;
          } else if (
            ['Create'].includes(paramKey) &&
            pp.key == 'Edit' &&
            !pp.disabled &&
            checked
          ) {
            pp.value = true;
          }

          return pp;
        });
      }
      return i;
    });
    setRole({ ...role, adminRolesMap: filtredMap });
  };
  const changeAwsRoleMap = (item, checked, paramKey) => {
    const filtredMap = role?.awsRolesMap?.map((i) => {
      if (item?.params?.some((p) => p.key == paramKey)) {
        item?.params?.map((pp) => {
          if (pp.key == paramKey) {
            pp.value = checked;
          }
          if (
            paramKey == 'View' &&
            ['Edit', 'Delete', 'Export', 'Create'].includes(pp.key) &&
            !checked
          ) {
            pp.value = false;
          } else if (
            ['Edit', 'Delete', 'Export', 'Create'].includes(paramKey) &&
            pp.key == 'View' &&
            !pp.disabled &&
            checked
          ) {
            pp.value = true;
          }
          return pp;
        });
      }
      return i;
    });
    setRole({ ...role, awsRolesMap: filtredMap });
  };

  const [validateErrors, setValidateErrors] = useState([]);

  const config = useMemo(() => SingleRoleValidationConfig(), []);

  const handlerError = (data) => {
    setValidateErrors(data);
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="1" color="inherit" to={URLS.ROLES}>
      Роли
    </Link>,
    <Typography key="2" color="text.primary">
      {id == 'create'
        ? 'Новая роль'
        : (role?.attributes?.RoleName[0] || role?.name)?.replace(
            'Eltis-admin:',
            '',
          ) || 'Без названия'}
    </Typography>,
  ];

  return (
    <MainContainer pageId={pagesIds.roles} setPermission={setPermission}>
      <FullPageLayout
        titleAs="h4"
        obj={{}}
        titleStyles={{ marginTop: '30px' }}
        infoComponent={<RoleInfo role={role} />}
        seoTitle={
          'Роль - ' + id == 'create'
            ? 'Новая роль'
            : role?.attributes?.RoleName[0] || role?.name
        }
        pageTitle={
          id == 'create'
            ? 'Добавить роль'
            : (role?.attributes?.RoleName[0] || role?.name)?.replace(
                'Eltis-admin:',
                '',
              ) || 'Без названия'
        }
        isCreated={id == 'create'}
        breadcrumbs={breadcrumbs}
        showHiddenData={false}
      >
        {id != 'create' && (
          <Stack direction="row" spacing={2} sx={{ mb: 4, mt: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate(URLS.ROLES + '/' + role?.id)}
            >
              Основная информация
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={() => navigate(URLS.ROLES + '/' + role?.id + '/users')}
            >
              Пользователи
            </Button>
          </Stack>
        )}

        <FormValidator
          name="role-form"
          validationConfig={config}
          changed={validateData}
          setErrorsHandler={(data) => handlerError(data)}
        >
          <Stack direction="row" spacing={2} sx={{ width: '100%', mb: 4 }}>
            {id == 'create' && (
              <FormControl sx={{ width: '30%' }}>
                <InputLabel id="demo-simple-select-label">
                  Предзаполнить как
                </InputLabel>
                <Select
                  disabled={isReadOnly}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedRole}
                  label="Предзаполнить как"
                  onChange={async ({ target: { value } }) => {
                    setModal({
                      title:
                        'Доступы предзаполнятся значениями из выбранной роли',

                      open: true,
                      handleClose: () => setModal({ ...modal, open: false }),
                      actionButtonText: 'Продолжить',
                      handleSuccess: async () => {
                        setSelectedRole(value);
                        const group = await getOneKeycloakGroupApi.sendRequest(
                          value,
                          true,
                        );
                        setRole({
                          ...role,
                          awsRolesMap: group?.awsRolesMap,
                          adminRolesMap: group?.adminRolesMap,
                        });
                        setModal({ ...modal, open: false });
                      },
                      showDescription: false,
                    });
                  }}
                >
                  {groups?.map((g) => (
                    <MenuItem value={g.id}>{g?.roleName || g?.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <TextField
              variant="outlined"
              sx={{ width: id == 'create' ? '70%' : '100%' }}
              label="Название роли"
              name="RoleName"
              required={config?.RoleName?.isRequired}
              error={validateErrors?.RoleName?.text}
              helperText={validateErrors?.RoleName?.text}
              disabled={isReadOnly || !permission?.edit}
              value={
                role?.attributes?.RoleName ? role?.attributes?.RoleName[0] : ''
              }
              onChange={({ target: { value } }) => {
                setValidateData({ ...validateData, RoleName: value });
                setRole({
                  ...role,
                  name: 'Eltis-admin:' + value,
                  attributes: { ...role.attributes, RoleName: [value] },
                });
              }}
            />
          </Stack>
        </FormValidator>

        <Typography as="h4" variant="h5" sx={{ fontWeight: 'normal', mb: 4 }}>
          Настройка прав доступа в панели администратора
        </Typography>
        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '250px' }}>
                  <b>Раздел/функция</b>
                </TableCell>
                <TableCell align="center">
                  <b>Просмотр</b>
                </TableCell>
                <TableCell align="center">
                  <b>Создание</b>
                </TableCell>
                <TableCell align="center">
                  <b>Редактирование</b>
                </TableCell>
                <TableCell align="center">
                  <b>Удаление</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {role?.adminRolesMap?.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{ width: '250px' }}>
                    {item?.name}
                  </TableCell>
                  <TableCell align="center">
                    {item?.params?.some((p) => p?.key == 'View') && (
                      <Checkbox
                        disabled={
                          isReadOnly ||
                          item?.params?.find((p) => p.key == 'View')
                            ?.disabled ||
                          !permission?.edit
                        }
                        checked={
                          item?.params?.find((p) => p.key == 'View')?.value
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={({ target: { checked } }) => {
                          changeAdminRoleMap(item, checked, 'View');
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {item?.params?.some((p) => p?.key == 'Create') && (
                      <Checkbox
                        disabled={
                          isReadOnly ||
                          item?.params?.find((p) => p.key == 'Create')
                            ?.disabled ||
                          !permission?.edit
                        }
                        checked={
                          item?.params?.find((p) => p.key == 'Create')?.value
                        }
                        onChange={({ target: { checked } }) => {
                          changeAdminRoleMap(item, checked, 'Create');
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {item?.params?.some((p) => p?.key == 'Edit') && (
                      <Checkbox
                        disabled={
                          isReadOnly ||
                          item?.params?.find((p) => p.key == 'Edit')
                            ?.disabled ||
                          !permission?.edit
                        }
                        checked={
                          item?.params?.find((p) => p.key == 'Edit')?.value
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={({ target: { checked } }) => {
                          changeAdminRoleMap(item, checked, 'Edit');
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {item?.params?.some((p) => p?.key == 'Delete') && (
                      <Checkbox
                        disabled={
                          isReadOnly ||
                          item?.params?.find((p) => p.key == 'Delete')
                            ?.disabled ||
                          !permission?.edit
                        }
                        checked={
                          item?.params?.find((p) => p.key == 'Delete')?.value
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={({ target: { checked } }) => {
                          changeAdminRoleMap(item, checked, 'Delete');
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography as="h4" variant="h5" sx={{ fontWeight: 'normal', mb: 4 }}>
          Настройка прав доступа в АРМ
        </Typography>
        <TableContainer component={Paper} sx={{ mb: 4, minWidth: '650px' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '250px' }}>
                  <b>Раздел/функция</b>
                </TableCell>
                <TableCell align="center">
                  <b>Просмотр</b>
                </TableCell>
                <TableCell align="center">
                  <b>Создание</b>
                </TableCell>
                <TableCell align="center">
                  <b>Экспорт</b>
                </TableCell>
                <TableCell align="center">
                  <b>Редактирование</b>
                </TableCell>
                <TableCell align="center">
                  <b>Удаление</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {role?.awsRolesMap?.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{ width: '250px' }}>
                    {item?.name}
                  </TableCell>
                  <TableCell align="center">
                    {item?.params?.some((p) => p?.key == 'View') && (
                      <Checkbox
                        disabled={
                          isReadOnly ||
                          item?.params?.find((p) => p.key == 'View')
                            ?.disabled ||
                          !permission?.edit
                        }
                        checked={
                          item?.params?.find((p) => p.key == 'View')?.value
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={({ target: { checked } }) => {
                          changeAwsRoleMap(item, checked, 'View');
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {item?.params?.some((p) => p?.key == 'Create') && (
                      <Checkbox
                        disabled={
                          isReadOnly ||
                          item?.params?.find((p) => p.key == 'Create')?.disabled
                        }
                        checked={
                          item?.params?.find((p) => p.key == 'Create')?.value
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={({ target: { checked } }) => {
                          changeAwsRoleMap(item, checked, 'Create');
                        }}
                      />
                    )}
                  </TableCell>

                  <TableCell align="center">
                    {item?.params?.some((p) => p?.key == 'Export') && (
                      <Checkbox
                        disabled={
                          isReadOnly ||
                          item?.params?.find((p) => p.key == 'Export')
                            ?.disabled ||
                          !permission?.edit
                        }
                        checked={
                          item?.params?.find((p) => p.key == 'Export')?.value
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={({ target: { checked } }) => {
                          changeAwsRoleMap(item, checked, 'Export');
                        }}
                      />
                    )}
                  </TableCell>

                  <TableCell align="center">
                    {item?.params?.some((p) => p?.key == 'Edit') && (
                      <Checkbox
                        disabled={
                          isReadOnly ||
                          !item?.key?.toLowerCase()?.includes('faq') ||
                          item?.params?.find((p) => p.key == 'Edit')
                            ?.disabled ||
                          !permission?.edit
                        }
                        checked={
                          item?.params?.find((p) => p.key == 'Edit')?.value
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={({ target: { checked } }) => {
                          changeAwsRoleMap(item, checked, 'Edit');
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {item?.params?.some((p) => p?.key == 'Delete') && (
                      <Checkbox
                        disabled={
                          isReadOnly ||
                          !item?.key?.toLowerCase()?.includes('faq') ||
                          item?.params?.find((p) => p.key == 'Delete')
                            ?.disabled ||
                          !permission?.edit
                        }
                        checked={
                          item?.params?.find((p) => p.key == 'Delete')?.value
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={({ target: { checked } }) => {
                          changeAwsRoleMap(item, checked, 'Delete');
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <RoleFooterButtons
          role={role}
          isReadOnly={isReadOnly}
          canBeRemove={!!!users[0]?.length}
          handleSuccessAction={
            id == 'create' ? createNewRole : updateRoleHandler
          }
          permission={permission}
          successButtonText={id == 'create' ? 'Создать' : 'Обновить'}
          showSuccessButton={isReadOnly ? false : true}
          successDisabled={!!Object.keys(validateErrors).length}
          loading={
            getOneKeycloakGroupApi?.loading ||
            getAllKeycloakGroupsApi?.loading ||
            getAllUsersByGroupIdApi?.loading ||
            createRoleApi?.loading ||
            updateRoleApi?.loading
          }
        />
        <ActionApproveV2
          target={modal.target}
          open={modal.open}
          title={modal.title}
          handleClose={modal.handleClose}
          actionButtonText={modal.actionButtonText}
          handleSuccess={modal.handleSuccess}
          showDescription={modal.showDescription}
          showCloseButton={modal?.showCloseButton}
        />
      </FullPageLayout>
    </MainContainer>
  );
};
