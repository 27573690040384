import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getOneCompany } from '../../api/CompanyApi';

import { URLS } from '../../utils/urls.util';
import { CompanyFrom } from '../CompanyFrom/CompanyFrom';
import { useApi } from '../../hooks/useApi';

import { getAppSettings } from '../../api/AppSettings';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';
import { SingleCompanyNavigation } from './SingleCompanyNavigation';

export const SingleCompany = ({ id, permission }) => {
  const getOneCompanyApi = useApi(getOneCompany);
  const getAppSettingsApi = useApi(getAppSettings);
  const [isCreate, setIsCreate] = useState(false);
  const [company, setCompany] = useState({});
  const [appSettings, setAppSettings] = useState({});
  const [wasChange, setChange] = useState(false);
  useEffect(() => {
    if (id !== 'create') {
      getOneCompanyApi
        .sendRequest(id, ['meta'])
        .then((company) => setCompany(company));
    }
    getAppSettingsApi.sendRequest().then((result) => setAppSettings(result));
  }, [id]);

  useState(() => {
    if (id == 'create') {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [id, company]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={URLS.COMPANIES}>
      Организации
    </Link>,
    <Typography key="3" color="text.primary">
      {isCreate ? 'Новая организация' : company.title}
    </Typography>,
  ];

  return (
    (id || isCreate) && (
      <FullPageLayout
        obj={company}
        seoTitle={'Организация - ' + (company?.title || 'Добавить организацию')}
        pageTitle={id == 'create' ? 'Добавить организацию' : company.title}
        isCreated={id == 'create'}
        breadcrumbs={breadcrumbs}
        showHiddenData={true}
      >
        <SingleCompanyNavigation
          company={company}
          activeTab={1}
          wasChange={wasChange}
          appSettings={appSettings}
        />
        <CompanyFrom
          permission={permission}
          appSettings={appSettings}
          isCreate={isCreate}
          company={company}
          key="0"
          wasChangeHandler={(data) => setChange(data)}
          afterUpdateHandler={(data) => setCompany(data)}
        />
      </FullPageLayout>
    )
  );
};
