import { TextField, Grid } from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  deleteVideoServer,
  saveNewVideoServer,
  updateVideoServer,
} from '../../api/VideoServers';
import { checkApiUrl } from '../../api/RtspServerApi';
import { setAlert } from '../../store/alert.store';
import { RtspServerFormActionButtons } from '../RtspServerFormActionButtons/RtspServerFormActionButtons';
import { FormValidator } from '../FormValidator/FormValidator';
import { VideoServerValidationConfig } from '../../utils/formValidatorConfigs/config';
import { useApi } from '../../hooks/useApi';
import { URLS } from '../../utils/urls.util';

export const VideoServerFrom = ({
  isCreate,
  rtspServer,
  permission,
  ...props
}) => {
  const [videoServerState, setVideoServerState] = useState({});
  const [validateErrors, setValidateErrors] = useState([]);
  const [customApiUrlError, setCustomApiUrlError] = useState('');
  const [wasChange, setChange] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [rangeMask, setRageMask] = useState('');
  const saveNewVideoServerApi = useApi(saveNewVideoServer);
  const upadteVideoServerApi = useApi(updateVideoServer);
  const deleteVideoServerApi = useApi(deleteVideoServer);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const config = useMemo(() => VideoServerValidationConfig(), []);

  useEffect(() => {
    const configKeys = Object.keys(config);

    if (wasChange && Object.keys(validateErrors).length === 0) {
      let canSave = true;
      configKeys.forEach((key) => {
        if (canSave && config[key]?.isRequired && !(key in videoServerState)) {
          canSave = false;
        }
      });
      setFormDisabled(!canSave && !customApiUrlError);
    } else if (!formDisabled) {
      setFormDisabled(true);
    }
  }, [videoServerState, validateErrors]);

  const handlerSaveNewVideoServer = async () => {
    try {
      await saveNewVideoServerApi.sendRequest(videoServerState);
      navigate(URLS.VIDEO_SERVERS);
      dispatch(
        setAlert({
          type: 'success',
          text: 'Видео сервер добавлен',
          status: 201,
        }),
      );
    } catch (error) {}
  };

  const handlerUpdateVideoServer = async () => {
    try {
      await upadteVideoServerApi.sendRequest(videoServerState);
      navigate(URLS.VIDEO_SERVERS);
      dispatch(
        setAlert({
          type: 'success',
          text: 'Видео сервер обновлен',
          status: 200,
        }),
      );
    } catch (error) {}
  };

  const handlerDeleteVideoServer = async () => {
    const result = await deleteVideoServerApi.sendRequest(videoServerState.id);
    navigate(URLS.VIDEO_SERVERS);
    dispatch(setAlert({ status: 201, text: 'Видео сервер удален' }));
  };

  useEffect(() => {
    setVideoServerState(rtspServer);
  }, [rtspServer]);

  useEffect(() => {
    setRageMask(videoServerState?.rangeMask);
  }, [videoServerState]);

  const handlerError = (data) => {
    setValidateErrors(data);
  };

  const onChange = (value, fieldName) => {
    if (!wasChange) {
      setChange(true);
    }
    setVideoServerState({ ...videoServerState, [fieldName]: value });
  };

  const handlerChancel = () => {
    navigate(URLS.VIDEO_SERVERS);
  };

  return (
    <div {...props}>
      <FormValidator
        name="video-server-form-name"
        validationConfig={config}
        changed={{
          ...videoServerState,
          rangeMask: rangeMask || videoServerState?.rangeMask,
        }}
        setErrorsHandler={(data) => handlerError(data)}
      >
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Название"
              disabled={!permission?.edit}
              placeholder="Название"
              variant="outlined"
              sx={{ mt: 2 }}
              name="name"
              required={config.name.isRequired}
              error={validateErrors?.name?.text}
              helperText={validateErrors?.name?.text}
              value={videoServerState?.name}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'name')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Url"
              placeholder="Url"
              variant="outlined"
              sx={{ mt: 2 }}
              name="address"
              disabled={!permission?.edit}
              required={config.address.isRequired}
              error={validateErrors?.address?.text || customApiUrlError}
              helperText={validateErrors?.address?.text || customApiUrlError}
              value={videoServerState?.address}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={async ({ target }) => {
                onChange(target.value, 'address');
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              type="number"
              label="Открытый порт"
              placeholder="Открытый порт"
              variant="outlined"
              sx={{ mt: 2 }}
              name="openedPort"
              disabled={!permission?.edit}
              required={config.openedPort.isRequired}
              error={validateErrors?.openedPort?.text}
              helperText={validateErrors?.openedPort?.text}
              value={videoServerState?.openedPort}
              onWheel={(e) => e.target.blur()}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'openedPort')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              type="number"
              label="Порт для связи с СК"
              placeholder="Порт для связи с СК"
              variant="outlined"
              disabled={!permission?.edit}
              sx={{ mt: 2 }}
              name="encryptedPort"
              required={config.encryptedPort.isRequired}
              error={validateErrors?.encryptedPort?.text}
              helperText={validateErrors?.encryptedPort?.text}
              value={videoServerState?.encryptedPort}
              onWheel={(e) => e.target.blur()}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'encryptedPort')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Логин"
              placeholder="Логин"
              variant="outlined"
              sx={{ mt: 2 }}
              name="login"
              disabled={!permission?.edit}
              required={config.login.isRequired}
              error={validateErrors?.login?.text}
              helperText={validateErrors?.login?.text}
              value={videoServerState?.login}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'login')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Пароль"
              placeholder="Пароль"
              variant="outlined"
              sx={{ mt: 2 }}
              name="password"
              disabled={!permission?.edit}
              required={config.password.isRequired}
              error={validateErrors?.password?.text}
              helperText={validateErrors?.password?.text}
              value={videoServerState?.password}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'password')}
            />
          </Grid>
        </Grid>

        <RtspServerFormActionButtons
          showDeleteButton={false}
          rtspServer={rtspServer}
          permission={permission}
          loading={
            saveNewVideoServerApi.loading ||
            upadteVideoServerApi.loading ||
            deleteVideoServerApi.loading
          }
          formDisabled={formDisabled}
          handlerSaveNewRtspServer={() => handlerSaveNewVideoServer()}
          handlerUpdateRtspServer={() => handlerUpdateVideoServer()}
          // handlerRemoveRtspServer={() => handlerDeleteVideoServer()}
          handlerChancel={() => handlerChancel()}
        />
      </FormValidator>
    </div>
  );
};
