export const URLS = {
  DASHBOARD: '/dashboard',
  MAIN: '/',
  LOGIN: '/login',
  COMPANIES: '/companies',
  COMPANY_PAGE: '/companies/:id/data',
  COMPANY_DETAILS_PAGE: '/companies/:id/details',
  COMPANY_RATES_PAGE: '/companies/:id/rates',
  COMPANY_EMPLOYEES_PAGE: '/companies/:id/employees',
  COMPANY_SMS_PAGE: '/companies/:id/sms',
  EMPLOYEE_PAGE: '/companies/:companyId/employees/:employeeId',

  USER_PAGE: '/users/:id',
  USERS: '/users',
  ROLES: '/roles',
  ROLE_SINGLE_PAGE: '/roles/:id',
  ROLE_SINGLE_USERS_PAGE: '/roles/:id/users',

  INVITE: '/invite/:inviteId',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:forgotId',
  DEVICES: '/devices',
  DEVICE_PAGE: '/devices/:id/details',
  DEVICE_PAGE_REVISIONS: '/devices/:id/revisions',
  DEVICE_PAGE_REVISION_SINGLE:
    '/devices/:id/revisions/:revisionId/:versionId/:versionRevisionId',
  SETTINGS: '/dashboard/:param',
  FAQ: '/faq',
  FAQ_SINGLE: '/faq/:id',
  FAQ_CATEGORIES: '/faq-categories',
  FAQ_CATEGORIES_SINGLE: '/faq-categories/:id',
  INSTRUCTION: '/instruction',
  INSTRUCTION_CATEGORIES: '/instruction-categories',
  INSTRUCTION_CATEGORIES_SINGLE: '/instruction-categories/:id',
  INSTRUCTION_SINGLE: '/instruction/:id',
  DEFAULT_TARIFF: '/default-tariff',
  KEY_WRITER: '/key-writer',
  VIDEO_SERVERS_BASE: '/video-servers/base',
  VIDEO_SERVERS_RTSP: '/video-servers/rtsp',
  VIDEO_SERVERS_RTSP_PAGE: '/video-servers/rtsp/:id',
  VIDEO_SERVERS: '/video-servers/servers',
  VIDEO_SERVERS_PAGE: '/video-servers/servers/:id',

  DEVICE_UPDATES_PAGE: '/devices-updates',
  SINGLE_DEVICE_UPDATES_PAGE: '/devices-updates/:id',
};
