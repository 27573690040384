import { Card, CardContent, Typography } from '@mui/material';
import styles from './AddItem.module.scss';
import React from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Link } from 'react-router-dom';

export const AddItem = ({ title, url }) => {
  return (
    <Link to={url} className={styles.AddItem}>
      <Card variant="outlined" className={styles.AddItem__Card}>
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <AddCircleOutlineOutlinedIcon fontSize="large" />
          <br />
          <Typography as="div">{title}</Typography>
        </CardContent>
      </Card>
    </Link>
  );
};
