import { Button, Grid, Stack } from '@mui/material';
import React from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { URLS } from '../../utils/urls.util';
import { useNavigate } from 'react-router-dom';
import { FooterButtonBar } from '../Default/FooterButtonBar/FooterButtonBar';

export const UserFormActionButtons = ({
  user,
  handlerSaveNewUser,
  handlerUpdateUser,
  handlerDeleteUser,
  disabled,
  permission,
  loading,
}) => {
  const navigate = useNavigate();
  return (
    <FooterButtonBar
      createButtonText="Пригласить"
      createButtonHandler={() => handlerSaveNewUser()}
      updateButtonHandler={() => handlerUpdateUser()}
      cancelButtonHandler={() => navigate(-1)}
      showCreateButton={permission?.create && !user?.id}
      showUpdateButton={permission?.edit && user?.id}
      showDeleteButton={false}
      showCancelButton={true}
      disabledUpdateButton={disabled || !permission?.edit}
      disabledCreateButton={disabled || !permission?.create}
      loading={loading}
    />
  );
};
