import {
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import styles from './ActionApprove.module.scss';
import CloseIcon from '@mui/icons-material/Close';
export const ActionApprove = ({
  target,
  open,
  handleClose,
  handleSuccess,
  showDescription = false,
}) => {
  const [checkWord, setCheckWord] = useState('');
  const handleChangeWord = ({ value }) => {
    setCheckWord(value);
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setCheckWord('');
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.ActionApprove}
    >
      <Box className={styles.ActionApprove__Box}>
        <IconButton
          aria-label="close"
          color="error"
          className={styles.ActionApprove__CloseButton}
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Вы уверены, что хотите удалить сущность - "
          {target.title?.length > 75
            ? target.title.slice(0, 75) + '...'
            : target.title}
          "
        </Typography>
        {showDescription && (
          <Typography variant="p">
            Вместе с сущностью будут удалены все вложенные в нее элементы
          </Typography>
        )}

        <Button
          variant="outlined"
          color="error"
          fullWidth
          sx={{ mt: 3 }}
          onClick={() => handleSuccess(target)}
        >
          Удалить
        </Button>
      </Box>
    </Modal>
  );
};
