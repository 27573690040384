import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  createCompanyDetails,
  updateCompanyDetails,
} from '../../api/CompanyApi';
import { setAlert } from '../../store/alert.store';
import { ru } from 'date-fns/locale';
import InputMask from 'react-input-mask';
import { FormValidator } from '../FormValidator/FormValidator';
import { CompanyValidationConfig } from '../../utils/formValidatorConfigs/config';
import { useApi } from '../../hooks/useApi';
import { getAddressData } from '../../api/DaDataApi';
import styles from '../CompanyFrom/CompanyFrom.module.scss';
import { countryIsoCodeAlpha3 } from '../../utils/countryIsoCodeAlpha3';
import { DatePicker, LocalizationProvider, ruRU } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';
import { FooterButtonBar } from '../Default/FooterButtonBar/FooterButtonBar';
import { useNavigate } from 'react-router-dom';

export const CompanyDetailsFrom = ({
  isCreate,
  company,
  appSettings,
  wasChangeHandler,
  afterUpdateHandler,
  permission,
  ...props
}) => {
  const companyDetailsApi = useApi(createCompanyDetails);
  const updateCompanyDetailsApi = useApi(updateCompanyDetails);
  const getAddressDataApi = useApi(getAddressData);
  const [companyState, setCompanyState] = useState({});
  const [formDisabled, setFormDisabled] = useState(true);
  const [validateErrors, setValidateErrors] = useState([]);
  const [addressHelpList, setAddressHelpList] = useState([]);
  const [openApprove, setOpenApprove] = useState(false);
  const [ogrnIsRequired, setOgrnIsRequired] = useState(true);
  const [showOgrnError, setShowOgrnError] = useState(true);
  const [smz, setSmz] = useState(false);
  const [dateError, setDateError] = useState(false);

  const dispatch = useDispatch();
  const [wasChange, setChange] = useState(false);

  const config = useMemo(
    () =>
      CompanyValidationConfig({
        oneOfList: [],
        customUniq: [],
      }),
    [],
  );

  useEffect(() => {
    let inputs =
      document.forms['company-details-form-name'].getElementsByTagName('input');
    let elements = Array.prototype.slice.call(inputs);
    if (elements.length) {
      elements = elements.filter((i) => i.name);
      const array = elements.map((i) => ({
        required: i.required,
        value: i.value,
      }));

      setFormDisabled(array.some((i) => i.required && !i.value));
    }
  }, [companyState, validateErrors]);

  const handlerCompanyDetails = async () => {
    try {
      let c;
      wasChangeHandler(false);
      if (company.shopCode) {
        c = await updateCompanyDetailsApi.sendRequest(companyState);
      } else {
        c = await companyDetailsApi.sendRequest(companyState);
      }

      dispatch(
        setAlert({
          status: 201,
          text: 'Организация обновлена',
        }),
      );
      afterUpdateHandler(c);
      setOpenApprove(false);
    } catch (error) {
      setOpenApprove(false);
    }
  };

  useEffect(() => {
    setSmz(company?.meta?.find((m) => m.key == 'Smz')?.value);
    setCompanyState(company);
  }, [company]);

  const changeMetaData = (meta, key, value) => {
    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    } else {
      value = value;
    }
    const metaItem = meta?.find((m) => m.key == key);
    if (metaItem) {
      meta = meta?.map((m) => {
        if (m.key == key) {
          m.value = value;
        }
        return m;
      });
    } else {
      meta.push({ key, value });
    }

    return meta;
  };

  const handlerError = (data) => {
    setValidateErrors(data);
  };

  const onChangeMetaInput = (target, name) => {
    if (!wasChange) {
      setChange(true);
      wasChangeHandler(true);
    }
    setCompanyState({
      ...companyState,
      meta: changeMetaData(companyState?.meta || [], name, target.value),
    });
  };

  const clickAddressHelper = (address) => {
    const street = address.unrestricted_value;
    const zip = address.data.postal_code;
    const city = address.data.city;
    onChangeMetaInput({ value: street }, 'Street');
    onChangeMetaInput({ value: zip }, 'Zip');
    onChangeMetaInput({ value: city }, 'City');
    setAddressHelpList([]);
  };

  const fieldInObject = (name) =>
    !!companyState?.meta?.find((item) => item.key === name);

  const changeSMZ = (target) => {
    if (target.checked) {
      setSmz(true);

      setOgrnIsRequired(false);
      setShowOgrnError(false);
    } else {
      setSmz(false);
      setOgrnIsRequired(true);
      setShowOgrnError(false);
    }
    target.value = target.checked;
    target.value = target.value == 'true' ? true : false;
    onChangeMetaInput(target, 'Smz');
  };

  const changeOgrn = (target) => {
    if (target.value) {
      setShowOgrnError(true);
    } else {
      setShowOgrnError(false);
    }
    onChangeMetaInput(target, 'Ogrn');
  };

  const navigate = useNavigate();

  return (
    <div {...props} style={{ paddingBottom: '40px' }}>
      <FormValidator
        name="company-details-form-name"
        validationConfig={config}
        changed={companyState}
        setErrorsHandler={(data) => handlerError(data)}
        fieldInObject={fieldInObject}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              as="p"
              variant="p"
              sx={{ mt: 4, mb: 3, color: '#949494', fontSize: '16px' }}
            >
              {company?.shopCode
                ? 'Чтобы изменить данные в заблокированных полях, вам необходимо связаться с поддержкой банка Тинькофф. Изменённые через Тинькофф данные не будут обновлены в панели администратора.'
                : 'Заполните данные для интеграции с платежной системой Тинькофф.'}
            </Typography>
            <Typography as="p" variant="h5" sx={{ mt: 0, mb: 3 }}>
              Данные организации
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Tooltip title='Параметр fullName необходимо заполнить кириллицей и обязательно указать организационно-правовую форму (например "Открытое aкционерное общество", "Закрытое aкционерное общество", "Индивидуальный предприниматель"'>
              <TextField
                fullWidth
                label={
                  <span className={styles.CompanyFrom__Text_field_required}>
                    <span>Полное наименование организации (fullName)</span>{' '}
                    <span> *</span>
                  </span>
                }
                variant="outlined"
                value={
                  companyState?.meta?.find((m) => m.key == 'FullName')?.value
                }
                disabled={!permission?.edit}
                name="FullName"
                required={config.FullName.isRequired}
                error={validateErrors?.FullName?.text}
                helperText={validateErrors?.FullName?.text}
                className={styles.CompanyFrom__Text_field}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target }) => onChangeMetaInput(target, 'FullName')}
              />
            </Tooltip>
          </Grid>

          <>
            <Grid item xs={3}>
              <Tooltip title="Параметр name необходимо заполнить кириллицей и обязательно указать организационно-правовую форму (например ОАО, ЗАО, ИП)">
                <TextField
                  fullWidth
                  label={
                    <span className={styles.CompanyFrom__Text_field_required}>
                      <span>Сокращенное наименование организации (name)</span>
                      <span> *</span>
                    </span>
                  }
                  disabled={!permission?.edit}
                  variant="outlined"
                  name="ShortName"
                  required={config?.ShortName?.isRequired}
                  error={validateErrors?.ShortName?.text}
                  helperText={validateErrors?.ShortName?.text}
                  className={styles.CompanyFrom__Text_field}
                  value={
                    companyState?.meta?.find((m) => m.key == 'ShortName')?.value
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target }) =>
                    onChangeMetaInput(target, 'ShortName')
                  }
                />
              </Tooltip>
            </Grid>

            <Grid item xs={3}>
              <Tooltip title="Укажите название вашей организации на латинице">
                <TextField
                  fullWidth
                  label={
                    <span className={styles.CompanyFrom__Text_field_required}>
                      <span>Название на латинице (billingDescriptor)</span>{' '}
                      <span> *</span>
                    </span>
                  }
                  disabled={!permission?.edit}
                  variant="outlined"
                  name="billingDescriptor"
                  required={config?.billingDescriptor?.isRequired}
                  error={validateErrors?.billingDescriptor?.text}
                  helperText={validateErrors?.billingDescriptor?.text}
                  className={styles.CompanyFrom__Text_field}
                  value={
                    companyState?.meta?.find(
                      (m) => m.key == 'billingDescriptor',
                    )?.value
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target }) =>
                    onChangeMetaInput(target, 'billingDescriptor')
                  }
                />
              </Tooltip>
            </Grid>
          </>
        </Grid>
        <Grid container spacing={2} alignItems="top">
          <Grid item xs={3}>
            <TextField
              fullWidth
              label={
                <span className={styles.CompanyFrom__Text_field_required}>
                  <span>ИНН (inn)</span>
                  <span> *</span>
                </span>
              }
              name="inn"
              required={config.inn.isRequired}
              error={validateErrors?.inn?.text}
              helperText={validateErrors?.inn?.text}
              className={styles.CompanyFrom__Text_field}
              variant="outlined"
              type="number"
              onWheel={(e) => e.target.blur()}
              value={companyState?.meta?.find((m) => m.key == 'Inn')?.value}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChangeMetaInput(target, 'Inn')}
              disabled={!!companyState.shopCode || !permission?.edit}
            />
          </Grid>

          <>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label={<span>КПП (kpp)</span>}
                variant="outlined"
                name="Kpp"
                type="number"
                onWheel={(e) => e.target.blur()}
                required={config?.Kpp?.isRequired}
                error={validateErrors?.Kpp?.text}
                helperText={validateErrors?.Kpp?.text}
                className={styles.CompanyFrom__Text_field}
                value={companyState?.meta?.find((m) => m.key == 'Kpp')?.value}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target }) => onChangeMetaInput(target, 'Kpp')}
                disabled={!!companyState.shopCode || !permission?.edit}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label={
                  <span className={styles.CompanyFrom__Text_field_required}>
                    <span>ОГРН (ogrn)</span>
                    {ogrnIsRequired && <span> *</span>}
                  </span>
                }
                type="number"
                onWheel={(e) => e.target.blur()}
                variant="outlined"
                name="Ogrn"
                required={ogrnIsRequired}
                error={showOgrnError && validateErrors?.Ogrn?.text}
                helperText={showOgrnError && validateErrors?.Ogrn?.text}
                className={styles.CompanyFrom__Text_field}
                value={companyState?.meta?.find((m) => m.key == 'Ogrn')?.value}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target }) => changeOgrn(target)}
                disabled={!!companyState.shopCode || !permission?.edit}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                sx={{ mt: 1 }}
                control={
                  <Checkbox
                    checked={smz}
                    onChange={({ target }) => changeSMZ(target)}
                    disabled={!!companyState.shopCode || !permission?.edit}
                  />
                }
                label={<span>Самозанятый</span>}
              />
            </Grid>
          </>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label={
                <span className={styles.CompanyFrom__Text_field_required}>
                  <span>Расчетный счет (bankAccount.Account)</span>{' '}
                  <span> *</span>
                </span>
              }
              variant="outlined"
              type="number"
              onWheel={(e) => e.target.blur()}
              name="Account"
              required={config?.Account?.isRequired}
              error={validateErrors?.Account?.text}
              helperText={validateErrors?.Account?.text}
              className={styles.CompanyFrom__Text_field}
              value={companyState?.meta?.find((m) => m.key == 'Account')?.value}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChangeMetaInput(target, 'Account')}
              disabled={!permission?.edit}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label={
                <span className={styles.CompanyFrom__Text_field_required}>
                  <span>Корреспондентский счет (bankAccount.korAccount)</span>
                  <span> *</span>
                </span>
              }
              type="number"
              onWheel={(e) => e.target.blur()}
              variant="outlined"
              name="KorAccount"
              required={config?.KorAccount?.isRequired}
              error={validateErrors?.KorAccount?.text}
              helperText={validateErrors?.KorAccount?.text}
              className={styles.CompanyFrom__Text_field}
              value={
                companyState?.meta?.find((m) => m.key == 'KorAccount')?.value
              }
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChangeMetaInput(target, 'KorAccount')}
              disabled={!permission?.edit}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label={
                <span className={styles.CompanyFrom__Text_field_required}>
                  <span>БИК банка (bankAccount.bik)</span>
                  <span> *</span>
                </span>
              }
              variant="outlined"
              type="number"
              onWheel={(e) => e.target.blur()}
              name="Bik"
              required={config?.Bik?.isRequired}
              error={validateErrors?.Bik?.text}
              helperText={validateErrors?.Bik?.text}
              className={styles.CompanyFrom__Text_field}
              value={companyState?.meta?.find((m) => m.key == 'Bik')?.value}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChangeMetaInput(target, 'Bik')}
              disabled={!permission?.edit}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label={
                <span className={styles.CompanyFrom__Text_field_required}>
                  <span>Наименование банка (bankAccount.bankName)</span>
                  <span> *</span>
                </span>
              }
              variant="outlined"
              name="BankName"
              required={config?.BankName?.isRequired}
              error={validateErrors?.BankName?.text}
              helperText={validateErrors?.BankName?.text}
              className={styles.CompanyFrom__Text_field}
              value={
                companyState?.meta?.find((m) => m.key == 'BankName')?.value
              }
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChangeMetaInput(target, 'BankName')}
              disabled={!permission?.edit}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label={
                <span className={styles.CompanyFrom__Text_field_required}>
                  <span>Веб-сайт (siteUrl)</span>
                  <span> *</span>
                </span>
              }
              variant="outlined"
              name="SiteUrl"
              required={config?.SiteUrl?.isRequired}
              error={validateErrors?.SiteUrl?.text}
              helperText={validateErrors?.SiteUrl?.text}
              className={styles.CompanyFrom__Text_field}
              value={companyState?.meta?.find((m) => m.key == 'SiteUrl')?.value}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChangeMetaInput(target, 'SiteUrl')}
              disabled={!!companyState.shopCode || !permission?.edit}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label={
                <span className={styles.CompanyFrom__Text_field_required}>
                  <span>Email (email)</span>
                  <span> *</span>
                </span>
              }
              variant="outlined"
              name="CompanyEmail"
              required={config?.CompanyEmail?.isRequired}
              error={validateErrors?.CompanyEmail?.text}
              helperText={validateErrors?.CompanyEmail?.text}
              className={styles.CompanyFrom__Text_field}
              value={
                companyState?.meta?.find((m) => m.key == 'CompanyEmail')?.value
              }
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) =>
                onChangeMetaInput(target, 'CompanyEmail')
              }
              disabled={!!companyState.shopCode || !permission?.edit}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Typography as="p" variant="h5" sx={{ mt: 4, mb: 3 }}>
              Юридический адрес организации
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={
                <span className={styles.CompanyFrom__Text_field_required}>
                  <span>Полный адрес</span>
                  <span> *</span>
                </span>
              }
              variant="outlined"
              name="Street"
              required={config?.Street?.isRequired}
              error={validateErrors?.Street?.text}
              helperText={validateErrors?.Street?.text}
              className={styles.CompanyFrom__Text_field}
              value={companyState?.meta?.find((m) => m.key == 'Street')?.value}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChangeMetaInput(target, 'Street')}
              onInput={({ target }) => {
                getAddressDataApi
                  .sendRequest({ query: target.value })
                  .then((result) => setAddressHelpList(result));
              }}
              disabled={!!companyState.shopCode || !permission?.edit}
            />
            <Card
              sx={{
                position: 'absolute',
                zIndex: 3,
                mt: '-30px',
                backgoround: '#fff',
              }}
            >
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {addressHelpList?.map((address) => (
                  <li
                    className={styles.AddressList__Item}
                    onClick={() => clickAddressHelper(address)}
                  >
                    {address.unrestricted_value}
                  </li>
                ))}
              </ul>
            </Card>
          </Grid>
        </Grid>

        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography as="p" variant="h5" sx={{ mt: 4, mb: 3 }}>
                Данные руководителя организации
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label={
                  <span className={styles.CompanyFrom__Text_field_required}>
                    <span>Фамилия (ceo.lastName)</span>
                    <span> *</span>
                  </span>
                }
                variant="outlined"
                name="CeoLastName"
                required={config?.CeoLastName?.isRequired}
                error={validateErrors?.CeoLastName?.text}
                helperText={validateErrors?.CeoLastName?.text}
                className={styles.CompanyFrom__Text_field}
                value={
                  companyState?.meta?.find((m) => m.key == 'CeoLastName')?.value
                }
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target }) =>
                  onChangeMetaInput(target, 'CeoLastName')
                }
                disabled={!!companyState.shopCode || !permission?.edit}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label={
                  <span className={styles.CompanyFrom__Text_field_required}>
                    <span>Имя (ceo.firstName)</span>
                    <span> *</span>
                  </span>
                }
                variant="outlined"
                name="CeoFirstName"
                required={config?.CeoFirstName?.isRequired}
                error={validateErrors?.CeoFirstName?.text}
                helperText={validateErrors?.CeoFirstName?.text}
                className={styles.CompanyFrom__Text_field}
                value={
                  companyState?.meta?.find((m) => m.key == 'CeoFirstName')
                    ?.value
                }
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target }) =>
                  onChangeMetaInput(target, 'CeoFirstName')
                }
                disabled={!!companyState.shopCode || !permission?.edit}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label={
                  <span className={styles.CompanyFrom__Text_field_required}>
                    <span>Отчество (ceo.middleName)</span>
                    <span> *</span>
                  </span>
                }
                variant="outlined"
                name="CeoMiddleName"
                required={config?.CeoMiddleName?.isRequired}
                error={validateErrors?.CeoMiddleName?.text}
                className={styles.CompanyFrom__Text_field}
                helperText={validateErrors?.CeoMiddleName?.text}
                value={
                  companyState?.meta?.find((m) => m.key == 'CeoMiddleName')
                    ?.value
                }
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target }) =>
                  onChangeMetaInput(target, 'CeoMiddleName')
                }
                disabled={!!companyState.shopCode || !permission?.edit}
              />
            </Grid>

            <Grid item xs={3}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ru}
                localeText={
                  ruRU.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
              >
                <DatePicker
                  label={
                    <span className={styles.CompanyFrom__Text_field_required}>
                      <span>Дата рождения (ceo.birthDate)</span>
                      <span> *</span>
                    </span>
                  }
                  name="CeoBirthDate"
                  defaultValue={new Date()}
                  required={config?.CeoBirthDate?.isRequired}
                  error={validateErrors?.CeoBirthDate?.text}
                  helperText={validateErrors?.CeoBirthDate?.text}
                  className={styles.CompanyFrom__Text_field}
                  views={['day', 'month', 'year']}
                  format="dd.MM.yyyy"
                  onError={(newError) => {
                    setDateError(!!newError);
                  }}
                  value={
                    companyState?.meta?.find((m) => m.key == 'CeoBirthDate')
                      ?.value
                      ? new Date(
                          companyState?.meta?.find(
                            (m) => m.key == 'CeoBirthDate',
                          )?.value,
                        )
                      : companyState?.meta?.find((m) => m.key == 'CeoBirthDate')
                          ?.value
                  }
                  onChange={(value) => {
                    const target = {
                      value,
                    };
                    onChangeMetaInput(target, 'CeoBirthDate');
                  }}
                  disabled={!permission?.edit}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <InputMask
                mask="+7(999)999-99-99"
                value={
                  companyState?.meta?.find((m) => m.key === 'CeoPhone')?.value
                }
                fullWidth
                label={
                  <span className={styles.CompanyFrom__Text_field_required}>
                    <span>Телефон (ceo.phone)</span>
                    <span> *</span>
                  </span>
                }
                variant="outlined"
                name="CeoPhone"
                required={config?.CeoPhone?.isRequired}
                error={validateErrors?.CeoPhone?.text}
                helperText={validateErrors?.CeoPhone?.text}
                className={styles.CompanyFrom__Text_field}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target }) =>
                  permission?.edit && onChangeMetaInput(target, 'CeoPhone')
                }
                disabled={!permission?.edit}
              >
                {(props) => (
                  <TextField disabled={!permission?.edit} {...props} />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label={
                  <span className={styles.CompanyFrom__Text_field_required}>
                    <span>Email</span>
                    <span> *</span>
                  </span>
                }
                variant="outlined"
                name="CeoEmail"
                required={config?.CeoEmail?.isRequired}
                error={validateErrors?.CeoEmail?.text}
                helperText={validateErrors?.CeoEmail?.text}
                className={styles.CompanyFrom__Text_field}
                value={
                  companyState?.meta?.find((m) => m.key == 'CeoEmail')?.value
                }
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target }) => onChangeMetaInput(target, 'CeoEmail')}
                disabled={!!companyState.shopCode || !permission?.edit}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Гражданство (ceo.country)
                </InputLabel>
                {Object.keys(companyState)?.length && (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="CeoCountry"
                    required={config?.CeoCountry?.isRequired}
                    error={validateErrors?.CeoCountry?.text}
                    helperText={validateErrors?.CeoCountry?.text}
                    className={styles.CompanyFrom__Text_field}
                    value={
                      companyState?.meta?.find((m) => m.key == 'CeoCountry')
                        ?.value
                    }
                    defaultValue={'rus'}
                    fullWidth
                    label={
                      <span className={styles.CompanyFrom__Text_field_required}>
                        <span>Гражданство (ceo.country)</span> <span> *</span>
                      </span>
                    }
                    variant="outlined"
                    onChange={({ target }) =>
                      onChangeMetaInput(target, 'CeoCountry')
                    }
                    disabled={!permission?.edit}
                  >
                    {countryIsoCodeAlpha3.map((country, key) => (
                      <MenuItem key={key} value={country.iso.toUpperCase()}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </>

        <FooterButtonBar
          showDeleteButton={false}
          cancelButtonHandler={() => navigate(-1)}
          showCancelButton={true}
          updateButtonHandler={() => handlerCompanyDetails(true)}
          showUpdateButton={permission?.edit && company?.shopCode}
          disabledUpdateButton={
            (wasChange ? dateError || Object.keys(validateErrors).length : 1) ||
            !permission?.edit
          }
          showCreateButton={permission?.create && !company?.shopCode}
          createButtonHandler={() => setOpenApprove(true)}
          disabledCreateButton={formDisabled || !permission?.create}
          createButtonText="Сохранить"
          cancelButtonText="Назад"
          loading={companyDetailsApi.loading || updateCompanyDetailsApi.loading}
        />
      </FormValidator>
      <ActionApproveV2
        open={openApprove}
        title="Подтверждая сохранение этих данных,вы отправляете их в Тинькофф, дальнейшее редактирование некоторых данных будет недоступно. Для изменения их необходимо самостоятельно обратиться к представителю банка."
        description="Сохранить и отправить данные?"
        handleClose={() => setOpenApprove(false)}
        showDescription={true}
        handleSuccess={() => handlerCompanyDetails()}
        handleSuccessText="Да"
        handleCloseText="Нет"
      />
    </div>
  );
};
