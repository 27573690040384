import {
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../utils/urls.util';
import { getVideoServersList } from '../../api/VideoServers';
import styles from './VideoServersList.module.scss';
import { format } from 'date-fns';

export const VideoServersList = ({ permission }) => {
  const videoServersApi = useApi(getVideoServersList);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);

  const getVideoServers = async (page) => {
    const result = await videoServersApi.sendRequest([], page, count);
    setRows(result.items);
    setTotalCount(result.totalCount);
  };

  useEffect(() => {
    getVideoServers(page);
  }, []);

  const handleChange = (event, value) => {
    setPage(value - 1);
    getVideoServers(value - 1);
  };

  const navigate = useNavigate();
  return (
    <>
      {permission?.create && (
        <Button
          color="success"
          variant="outlined"
          sx={{ mb: 3, mt: 3 }}
          disabled={!permission?.create}
          onClick={() => {
            navigate(URLS.VIDEO_SERVERS + '/create');
          }}
        >
          ДОБАВИТЬ ВИДЕО СЕРВЕР
        </Button>
      )}

      <Table
        sx={{
          minWidth: 650,
          border: 'solid 1px #eee',
          borderRadius: '5px',
          mt: 3,
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell alignt="left">
              <b>Название</b>
            </TableCell>
            <TableCell align="left">
              <b>Адрес сервера</b>
            </TableCell>
            <TableCell align="left">
              <b>Дата создания</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              className={styles?.VideoServersList__Row}
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={() => navigate(URLS.VIDEO_SERVERS + `/${row.id}`)}
            >
              <TableCell align="left" component="th" scope="row">
                {row?.name}
              </TableCell>
              <TableCell align="left">{row?.address}</TableCell>
              <TableCell align="left">
                {format(new Date(row.createDate), 'dd.MM.yyyy HH:mm:ss')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {count > 0 && (
        <Pagination
          count={Math.ceil(totalCount / count)}
          page={+page + 1}
          onChange={handleChange}
          sx={{ mt: 3, mb: 4, ml: 'auto', display: 'block' }}
        />
      )}
    </>
  );
};
