import React from 'react';
import { MetaData } from '../Default/MetaData/MetaData';
import { EmployeeForm } from '../EmployeeForm/EmployeeForm';
import { Breadcrumbs, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { URLS } from '../../utils/urls.util';
import styles from '../Default/Title/Title.module.scss';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';
import { UserInfo } from '../UserInfo/UserInfo';

export const SingleEmployee = ({
  company,
  employee,
  changeEmployeeAfterCreateOrUpdateHandler,
  permission,
}) => {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={URLS.COMPANIES}>
      Организации
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={`/companies/${company?.id}/employees`}
    >
      {company.title}
    </Link>,
    <Typography key="3" color="text.primary">
      {!employee?.id
        ? 'Новый сотрудник'
        : employee?.firstName + ' ' + employee?.lastName}
    </Typography>,
  ];
  return (
    <FullPageLayout
      seoTitle={
        'Администриатор организации - ' +
        ((employee?.firstName &&
          employee?.lastName &&
          employee?.firstName + ' ' + employee?.lastName) ||
          employee?.email ||
          'Добавление администратора')
      }
      pageTitle={
        !employee?.id
          ? 'Добавить сотрудника'
          : employee?.firstName + ' ' + employee?.lastName
      }
      obj={{}}
      infoComponent={<UserInfo user={employee} />}
      isCreated={!!!employee?.id}
      breadcrumbs={breadcrumbs}
    >
      <EmployeeForm
        employee={employee}
        company={company}
        permission={permission}
        key="1"
        changeEmployeeAfterCreateOrUpdateHandler={(data) =>
          changeEmployeeAfterCreateOrUpdateHandler(data)
        }
      />
    </FullPageLayout>
  );
};
