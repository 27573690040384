import React, { useEffect, useState } from 'react';
import { MetaData } from '../MetaData/MetaData';
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from '../../Default/Title/Title.module.scss';
import s from './FullPageLayout.module.scss';
import { getMe } from '../../../api/AuthApi';
import AuthService from '../../../api/KeycloakApi';
import { HttpError } from '../HttpError/HttpError';
import { useSelector } from 'react-redux';
import { useApi } from '../../../hooks/useApi';

export const FullPageLayout = ({
  children,
  seoTitle,
  pageTitle,
  isCreated,
  breadcrumbs,
  showHiddenData,
  obj,
  showAwsStatus = true,
  showStatus = true,
  infoComponent = <></>,
  setPermission = () => {},
  pageId = '',
  setMeInfo = () => {},
}) => {
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const getMeApi = useApi(getMe);
  const token = AuthService.getToken();
  const [forbidden, setForbidden] = useState(false);
  const [loading, setLoading] = useState(false);
  const [me, setMe] = useState({});
  useEffect(() => {
    if (token) {
      getMeApi
        .sendRequest(['meta'])
        .then((me) => {
          setMe(me);
          setMeInfo(me);
          const permission = me.permissions.admin.find(
            (item) => item.key === pageId,
          );
          if (permission) {
            setPermission(permission);
          }
        })
        .catch((error) => {
          if (error?.response?.status == 403) {
            setForbidden(true);
            setLoading(false);
          } else {
            setForbidden(false);
            setLoading(false);
          }
        });
    }
  }, []);
  return !forbidden ? (
    !loading ? (
      <div style={{ paddingBottom: '30px' }}>
        <MetaData title={seoTitle} />

        <Grid container spacing={2} alignItems="end">
          <Grid item md={10}>
            <Breadcrumbs
              separator="-"
              aria-label="breadcrumb"
              sx={{ mt: 3 }}
              className={styles.Title__Breadcrumbs}
            >
              {breadcrumbs}
            </Breadcrumbs>
            <Typography variant="h4" as="h1" sx={{ mt: '30px', mb: 1 }}>
              {pageTitle}
            </Typography>
          </Grid>
          <Grid item md={2} textAlign="right">
            {!isCreated && (
              <Button
                variant="contained"
                color="grey"
                sx={{ boxShadow: 'none' }}
                onClick={() => setIsOpenInfo(!isOpenInfo)}
                endIcon={
                  isOpenInfo ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
                // className={isOpenInfo && s.InfoButton__Open}
              >
                Инфо
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid>
          {isOpenInfo && !isCreated && (
            <Card
              sx={{
                background: '#f4f4f4',
                borderRadius: '0 0 4px 4px',
                boxShadow: 'none',
              }}
            >
              <CardContent>
                {Object.keys(obj)?.length ? (
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      {obj?.id && (
                        <Grid container>
                          <Grid item xs={4} sx={{ fontSize: '11px' }}>
                            <p>ID</p>
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            sx={{ fontSize: '11px', fontWeight: 'bold' }}
                          >
                            <p>{obj?.id}</p>
                          </Grid>
                        </Grid>
                      )}
                      {(obj?.shopCode || showHiddenData) && (
                        <Grid container>
                          <Grid item xs={4} sx={{ fontSize: '11px' }}>
                            <p>ShopeCode</p>
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            sx={{ fontSize: '11px', fontWeight: 'bold' }}
                          >
                            <p>
                              {obj?.shopCode ? obj?.shopCode : 'Отсутствует'}
                            </p>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      {showStatus && (
                        <Grid container spacing={2}>
                          <Grid item xs={6} sx={{ fontSize: '11px' }}>
                            <p>Статус</p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{ fontSize: '11px', fontWeight: 'bold' }}
                          >
                            <p>
                              {obj?.isActive
                                ? 'Опубликован(а)'
                                : 'Не опубликован(а)'}
                            </p>
                          </Grid>
                        </Grid>
                      )}

                      {showAwsStatus && (
                        <Grid container spacing={2}>
                          <Grid item xs={6} sx={{ fontSize: '11px' }}>
                            <p>Статус в АРМ</p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{ fontSize: '11px', fontWeight: 'bold' }}
                          >
                            <p>
                              {obj?.createdInAws ? 'Создан(а)' : 'Не создан(а)'}
                            </p>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {obj?.createdUser && (
                        <Grid container spacing={2}>
                          <Grid item xs={4} sx={{ fontSize: '11px' }}>
                            <p>Создатель</p>
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            sx={{ fontSize: '11px', fontWeight: 'bold' }}
                          >
                            <p>{obj?.createdUser}</p>
                          </Grid>
                        </Grid>
                      )}
                      {obj?.updatedUser && (
                        <Grid container spacing={2}>
                          <Grid item xs={4} sx={{ fontSize: '11px' }}>
                            <p>Редактор</p>
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            sx={{ fontSize: '11px', fontWeight: 'bold' }}
                          >
                            <p>{obj?.updatedUser}</p>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {obj?.created && (
                        <Grid container spacing={2}>
                          <Grid item xs={4} sx={{ fontSize: '11px' }}>
                            <p>Создан(а)</p>
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            sx={{ fontSize: '11px', fontWeight: 'bold' }}
                          >
                            <p>
                              {format(
                                new Date(obj.created),
                                'dd.MM.yyyy HH:mm:ss',
                              )}
                            </p>
                          </Grid>
                        </Grid>
                      )}
                      {obj.updated && (
                        <Grid container spacing={2}>
                          <Grid item xs={4} sx={{ fontSize: '11px' }}>
                            <p>Обновлен(а)</p>
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            sx={{ fontSize: '11px', fontWeight: 'bold' }}
                          >
                            <p>
                              {format(
                                new Date(obj.updated),
                                'dd.MM.yyyy HH:mm:ss',
                              )}
                            </p>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  infoComponent
                )}
              </CardContent>
            </Card>
          )}
        </Grid>
        {children}
      </div>
    ) : (
      <></>
    )
  ) : (
    <HttpError
      statusCode={403}
      showAction={true}
      messageText={`У вас нет доступа к этой странице`}
    />
  );
};
