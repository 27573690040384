import { Breadcrumbs, Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import styles from './Title.module.scss';
export const Title = ({
  breadcrumbs,
  children,
  selectButtonAction,
  titleAs,
  titleStyles,
}) => {
  const [selectButtonActionState, setSelectButtonAction] = useState(false);
  const handlerClick = () => {
    selectButtonAction();
    setSelectButtonAction(!selectButtonActionState);
  };
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item md={8}>
        <Breadcrumbs
          separator="-"
          aria-label="breadcrumb"
          sx={{ mt: 3 }}
          className={styles.Title__Breadcrumbs}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Typography
          variant={titleAs ? titleAs : 'h3'}
          as="h1"
          sx={{ mb: 4 }}
          style={titleStyles}
        >
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
};
