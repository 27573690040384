import React, { useEffect, useMemo, useState } from 'react';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useApi } from '../hooks/useApi';

import { URLS } from '../utils/urls.util';
import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { pagesIds } from '../utils/pages';
import { FaqInstructionLayoutV2 } from '../components/FaqInstructionLayoutV2/FaqInstructionLayoutV2';
import { FormValidator } from '../components/FormValidator/FormValidator';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/alert.store';
import { InstructionCategoryValidationConfig } from '../utils/formValidatorConfigs/config';
import { ActionApproveV2 } from '../components/Default/ActionApprove/ActionApproveV2';
import arrayMove from 'array-move';
import {
  createInstructionCategory,
  deleteInstruction,
  deleteInstructionCategory,
  getAllInstruction,
  getAllInstructionCategories,
  updateInstructionCategory,
} from '../api/InstructionApi';
export const InstructionPageV2 = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Typography key="2" color="text.primary">
      Инструкции
    </Typography>,
  ];

  const categoriesApi = useApi(getAllInstructionCategories);
  const instructionApi = useApi(getAllInstruction);
  const updateApi = useApi(updateInstructionCategory);
  const createApi = useApi(createInstructionCategory);
  const deleteApi = useApi(deleteInstructionCategory);
  const deleteInstructionApi = useApi(deleteInstruction);

  const [selectCategory, setSelectCategory] = useState(null);
  const [search, setSearch] = useState('');
  const [deleteCategoryCandidate, setDeleteCategoryCandidate] = useState({});
  const [deleteItemCandidate, setDeleteItemCandidate] = useState({});
  const [instructionCount, setInstructionCount] = useState(0);
  const [instructionPage, setInstructionPage] = useState(1);
  const [openApproveRemoveItem, setOpenApproveRemoveItem] = useState(false);
  const [openApproveRemoveCategory, setOpenApproveRemoveCategory] =
    useState(false);
  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);
  const [instruction, setInstructions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sortData, setSortData] = useState({});
  const [updatedSort, setUpdatedSort] = useState(false);
  const [wasChangedCategory, setWasChangedCategory] = useState(false);
  const location = useLocation();

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  useEffect(() => {
    categoriesApi.sendRequest([], 999, 0).then((result) => {
      setCategories(result[0]);
      setSelectCategory(result[0][0]?.id);
    });
  }, [location]);

  useEffect(() => {
    if (selectCategory) {
      getInstructionByCategoryId(selectCategory);
    }
  }, [selectCategory]);
  const getInstructionByCategoryId = async (
    categoryId,
    page = instructionPage,
    search = '',
    orderObj,
  ) => {
    const [result, count] = await instructionApi.sendRequest(
      ['category'],
      10,
      (page - 1) * 10,
      categoryId,
      search,
      orderObj,
    );

    setInstructionCount(count);
    setInstructions(result);
  };

  const paginationHandler = (p) => {
    setInstructionPage(p);

    getInstructionByCategoryId(
      search ? null : selectCategory,
      p,
      search,
      sortData,
    );
  };

  //   --

  const [categoryState, setInstructionCategoryState] = useState({});
  const [validateErrors, setValidateErrors] = useState([]);

  const dispatch = useDispatch();

  const updateHandler = async () => {
    await updateApi.sendRequest(categoryState);
    dispatch(setAlert({ status: 201, text: 'Раздел обновлен' }));
    categoriesApi.sendRequest([], 999, 0).then((result) => {
      setCategories(result[0]);
    });
    setOpenAddCategoryModal(false);
    setInstructionCategoryState({});
  };

  const createHandler = async () => {
    await createApi.sendRequest(categoryState);
    categoriesApi.sendRequest([], 999, 0).then((result) => {
      setCategories(result[0]);
    });
    dispatch(setAlert({ status: 201, text: 'Раздел добавлен' }));
    setOpenAddCategoryModal(false);
    setInstructionCategoryState({});
  };
  const removeCategoryHandler = async () => {
    const result = await deleteApi.sendRequest(deleteCategoryCandidate?.id);
    if (result) {
      categoriesApi.sendRequest([], 999, 0).then((result) => {
        setCategories(result[0]);
      });
      dispatch(setAlert({ status: 201, text: 'Раздел удален' }));
      setOpenApproveRemoveCategory(false);
    }
  };

  const handlerError = (data) => {
    setValidateErrors(data);
  };
  const config = useMemo(() => InstructionCategoryValidationConfig(), []);

  const onSortEnd = (oldIndex, newIndex) => {
    setCategories((array) => {
      let newArray = arrayMove(array, oldIndex, newIndex);
      newArray = newArray.map((item, index) => ({
        ...item,
        order: index,
      }));

      for (const category of newArray) {
        updateApi.sendRequest(category);
      }
      return newArray;
    });
  };

  const removeInstructionHandler = async () => {
    setOpenApproveRemoveItem(false);
    await deleteInstructionApi.sendRequest(deleteItemCandidate?.id);

    getInstructionByCategoryId(deleteItemCandidate?.category?.id);
    dispatch(setAlert({ status: 200, text: 'Инструкция удалена' }));
  };

  const editInstructionHandler = (f) => {
    navigate(URLS.INSTRUCTION + '/' + f.id);
  };
  return (
    <MainContainer
      pageId={pagesIds.instructions}
      setPermission={setPermission}
      title="Инструкции"
      breadcrumbs={breadcrumbs}
      actionButton={
        permission.create
          ? {
              text: 'Добавить инструкцию',
              action: () => navigate(URLS.INSTRUCTION + '/create'),
            }
          : null
      }
    >
      <FaqInstructionLayoutV2
        categories={categories}
        items={instruction}
        setSelectCategory={(data) => setSelectCategory(data)}
        addCategoryActionButtonHandler={() => setOpenAddCategoryModal(true)}
        editCategoryActionButtonHandler={(data) => {
          setOpenAddCategoryModal(true);
          setInstructionCategoryState(data);
        }}
        deleteCategoryActionButtonHandler={(data) => {
          setDeleteCategoryCandidate(data);
          setOpenApproveRemoveCategory(true);
        }}
        onSortEnd={onSortEnd}
        deleteItemActionButtonHandler={(data) => {
          setDeleteItemCandidate(data);
          setOpenApproveRemoveItem(true);
        }}
        paginationCount={Math.ceil(+instructionCount / 10)}
        page={instructionPage}
        paginationHandler={(e, value) => paginationHandler(value)}
        editItemActionButtonHandler={(data) => editInstructionHandler(data)}
        searchActionHandler={(data) => {
          setInstructionPage(1);
          getInstructionByCategoryId(null, 1, data);
          setSearch(data);
        }}
        search={search}
        categoryLoading={categoriesApi.loading}
        itemsLoading={instructionApi?.loading}
        permission={permission}
        sortItemsHandler={(field, order) => {
          setSortData({ field, order });
          getInstructionByCategoryId(
            search ? null : selectCategory,
            1,
            search,
            {
              field,
              order,
            },
          );
        }}
        selectCategory={selectCategory}
        searchLabel="Поиск по инструкции"
        entityName="Инструкция"
      />

      <Modal
        open={openAddCategoryModal}
        onClose={() => {
          setOpenAddCategoryModal(false);
          setInstructionCategoryState({});
        }}
        sx={{
          width: '100%',
          overflow: 'scroll',
        }}
      >
        <Box
          sx={{
            maxWidth: '540px',
            margin: '14% auto',
            width: '100%',
            borderRadius: '8px',
            background: '#fff',
            padding: '20px',
          }}
        >
          <Typography variant="h5">
            {categoryState?.id
              ? 'Редактирование раздела'
              : 'Добавление нового раздела'}
          </Typography>
          <FormValidator
            name="instruction-category-form-name"
            validationConfig={config}
            changed={categoryState}
            setErrorsHandler={(data) => handlerError(data)}
          >
            <TextField
              fullWidth
              label="Название раздела"
              variant="outlined"
              name="title"
              error={validateErrors?.title?.text}
              helperText={validateErrors?.title?.text}
              required={config?.title?.isRequired}
              sx={{ my: 3 }}
              value={categoryState?.title}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value } }) => {
                setInstructionCategoryState({
                  ...categoryState,
                  title: value,
                });
                setWasChangedCategory(true);
              }}
              onPaste={({ target: { value } }) => {
                setInstructionCategoryState({
                  ...categoryState,
                  title: value,
                });
                setWasChangedCategory(true);
              }}
              disabled={!permission?.edit}
            />
          </FormValidator>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ width: '100%' }}
          >
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => {
                setOpenAddCategoryModal(false);
                setInstructionCategoryState({});
                setWasChangedCategory(false);
              }}
            >
              Отмена
            </Button>

            {categoryState?.id ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  updateHandler();
                  setWasChangedCategory(false);
                }}
                disabled={
                  !categoryState?.title?.trim() ||
                  Object.keys(validateErrors).length ||
                  !wasChangedCategory
                }
              >
                Обновить
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  createHandler();
                  setWasChangedCategory(false);
                }}
                disabled={
                  !categoryState?.title?.trim() ||
                  Object.keys(validateErrors).length
                }
              >
                Создать
              </Button>
            )}
          </Stack>
        </Box>
      </Modal>
      <ActionApproveV2
        target={deleteCategoryCandidate}
        open={openApproveRemoveCategory}
        title="Вы действительно хотите удалить данный раздел?"
        handleClose={() => {
          setOpenApproveRemoveCategory(false);
          setDeleteCategoryCandidate({});
        }}
        handleSuccess={() => removeCategoryHandler()}
        showDescription={false}
      />
      <ActionApproveV2
        target={deleteItemCandidate}
        open={openApproveRemoveItem}
        title="Вы действительно хотите удалить данную инструкцию?"
        handleClose={() => {
          setOpenApproveRemoveItem(false);
          setDeleteItemCandidate({});
        }}
        handleSuccess={() => removeInstructionHandler()}
        showDescription={false}
      />
    </MainContainer>
  );
};
