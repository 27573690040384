import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { deleteFaqCategory } from '../../api/FaqApi';
import { useApi } from '../../hooks/useApi';
import { setAlert } from '../../store/alert.store';
import { URLS } from '../../utils/urls.util';
import { ActionApprove } from '../Default/ActionApprove/ActionApprove';
import { FaqCategoryForm } from '../FaqCategoryForm/FaqCategoryForm';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';

export const SingleFaqCategory = ({ id, category, permission }) => {
  const deleteApi = useApi(deleteFaqCategory);
  const [isCreate, setIsCreate] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const deleteFaqCategoryHandler = async () => {
    try {
      await deleteApi.sendRequest(category?.id);
      navigate(URLS.FAQ_CATEGORIES);
      dispatch(setAlert({ status: 200, text: 'Раздел удален' }));
    } catch (error) {}
  };

  const clickToRemove = (item) => {
    setOpenModal(true);
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={URLS.FAQ}>
      Частые вопросы (FAQ)
    </Link>,
    <Typography key="3" color="text.primary">
      {isCreate ? 'Новый раздел частых вопросов' : category?.title}
    </Typography>,
  ];
  useState(() => {
    if (id == 'create') {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [id]);
  return (
    <FullPageLayout
      seoTitle={
        'Раздел частых вопросов - ' +
        (category?.title || 'Добавление FAQ раздела')
      }
      pageTitle={isCreate ? 'Добавить раздел частых вопросов' : category?.title}
      obj={category}
      showComapnyId={false}
      showAwsStatus={false}
      showStatus={false}
      breadcrumbs={breadcrumbs}
      isCreated={isCreate}
    >
      <ActionApprove
        target={category}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleSuccess={(data) => deleteFaqCategoryHandler()}
        showDescription={true}
      />

      <FaqCategoryForm
        category={category}
        handlerRemove={(data) => clickToRemove()}
        permission={permission}
      />
    </FullPageLayout>
  );
};
