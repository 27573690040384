import * as React from 'react';
import { useApi } from '../hooks/useApi';
import {
  editDefaultTariffs,
  getDefaultTariffs,
  getTariffDependencies,
} from '../api/TariffApi';
import { useEffect } from 'react';
import { useState } from 'react';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { Link, useNavigate } from 'react-router-dom';
import { URLS } from '../utils/urls.util';
import { floatMask } from 'float-mask';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Button,
  Checkbox,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { setAlert } from '../store/alert.store';
import { useDispatch } from 'react-redux';
import { DefaultTariffConfig } from '../utils/formValidatorConfigs/config';
import { FormValidator } from '../components/FormValidator/FormValidator';
import ChangeAlert from '../components/Default/ChangeAlert/ChangeAlert';
import { pagesIds } from '../utils/pages';
import { FooterButtonBar } from '../components/Default/FooterButtonBar/FooterButtonBar';

export const DefaultTariffPage = () => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [edit, setEdit] = useState({});
  const [change, setChange] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [customValidateErrors, setCustomValidateErrors] = useState({
    Lite: { text: null },
    Medium: { text: null },
    Comfort: { text: null },
  });
  const [defaultPrices, setDefaultPrices] = useState({});
  const [tariffFunctiondependencies, setTariffFunctionDependencies] = useState(
    [],
  );
  const [tariffVersionDependencies, setTariffVersionDependencies] = useState([
    { name: 'Lite', dependencies: ['Medium', 'Comfort'] },
    { name: 'Medium', dependencies: ['Comfort'] },
    { name: 'Comfort', dependencies: [] },
  ]);
  const getDefaultTariffsApi = useApi(getDefaultTariffs);
  const getTariffDependenciesApi = useApi(getTariffDependencies);
  const editDefaultTariffsApi = useApi(editDefaultTariffs);
  const dispatch = useDispatch();

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  const getDefaultCommision = (key, data) => {
    let commision = data?.find((r) => r.version == key)?.defaultCommission;

    if (commision) {
      if (('' + commision)?.split('.')[1]?.length < 2) {
        commision = commision + '0';
      } else if (('' + commision)?.indexOf('.') == -1) {
        commision = commision + '.00';
      }
      return commision;
    } else {
      return '00.00';
    }
  };
  useEffect(() => {
    getDefaultTariffsApi.sendRequest().then((result) => {
      setDefaultPrices({
        Lite: getDefaultCommision('Lite', result),
        Medium: getDefaultCommision('Medium', result),
        Comfort: getDefaultCommision('Comfort', result),
      });
      const newColumns = result.map((r) => ({
        field: r.version,
        headerName: r.version,
        width: 200,
        sortable: false,
      }));
      newColumns.unshift({
        field: 'name',
        headerName: 'Функции',
        width: 200,
        sortable: false,
      });

      setColumns(newColumns);
      const test = result
        .map((r) => {
          const mapper = r.functions.map((f) => ({
            name: f.name,
            function: f.function,
            version: r.version,
            enabled: f.enabled,
            description: f.description,
          }));
          return mapper;
        })
        .reduce((a, b) => [...a, ...b]);

      var map = test.reduce((acc, cur) => {
        acc[cur.function] = acc[cur.function] || {
          function: cur.function,
          name: cur.name,
          description: cur.description,
          [cur.version]: cur.enabled,
        };
        acc[cur.function][cur.version] = cur.enabled;
        return acc;
      }, {});

      var result = Object.values(map);
      const isChanged = result.some((r) => !r.Lite && !r.Medium && !r.Comfort);
      setChange(isChanged);
      setRows(result);
    });
    getTariffDependenciesApi
      .sendRequest()
      .then((result) => setTariffFunctionDependencies(result));
  }, []);

  const changeCheckboxHandler = (row, checked, key) => {
    let tariffVersionDependenciesArray = [];
    let newRows = [];
    let tariffFunctionDependenciesArray = [];
    if (checked) {
      tariffFunctionDependenciesArray = tariffFunctiondependencies.find(
        (d) => d.name == row.function,
      ).dependencies;
      tariffVersionDependenciesArray = tariffVersionDependencies.find(
        (d) => d.name == key,
      ).dependencies;
    } else {
      tariffFunctionDependenciesArray = tariffFunctiondependencies
        .map((d) => d.dependencies.includes(row.function) && d.name)
        .filter((r) => r);
      tariffVersionDependenciesArray = tariffVersionDependencies
        .map((d) => d.dependencies.includes(key) && d.name)
        .filter((r) => r);
    }
    newRows = [
      ...rows.map((r) => {
        if (
          r.function == row.function ||
          tariffFunctionDependenciesArray.includes(r.function)
        ) {
          r[key] = checked;

          for (const dep of tariffVersionDependenciesArray) {
            r[dep] = checked;
          }
        }
        return r;
      }),
    ];
    const isChanged = newRows.some((r) => !r.Lite && !r.Medium && !r.Comfort);
    setChange(isChanged);
    setRows(newRows);
  };

  const getMeSuccess = () => {
    if (change) {
      setOpenModal(true);
    } else {
      editHandler();
    }
  };
  const editHandler = async () => {
    const candidate = [
      {
        version: 'Lite',
        DefaultCommission: defaultPrices.Lite,
        functions: [
          ...rows.map((r) => ({
            function: r.function,
            name: r.name,
            description: r.description,
            enabled: r.Lite,
          })),
        ],
      },
      {
        version: 'Medium',
        DefaultCommission: defaultPrices.Medium,
        functions: [
          ...rows.map((r) => ({
            function: r.function,
            name: r.name,
            description: r.description,
            enabled: r.Medium,
          })),
        ],
      },
      {
        version: 'Comfort',
        DefaultCommission: defaultPrices.Comfort,
        functions: [
          ...rows.map((r) => ({
            function: r.function,
            name: r.name,
            description: r.description,
            enabled: r.Comfort,
          })),
        ],
      },
    ];
    const result = await editDefaultTariffsApi.sendRequest(candidate);
    dispatch(setAlert({ status: 201, text: 'Тарифы успешно обновлены' }));
  };

  useEffect(() => {
    setCustomValidateErrors({
      Lite:
        defaultPrices?.Lite < 1
          ? { text: 'Значение не должно быть меньше 1' }
          : { text: null },
      Medium:
        defaultPrices?.Medium < 1
          ? { text: 'Значение не должно быть меньше 1' }
          : { text: null },
      Comfort:
        defaultPrices?.Comfort < 1
          ? { text: 'Значение не должно быть меньше 1' }
          : { text: null },
    });
  }, [defaultPrices]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Typography key="2" color="text.primary">
      Тарифы
    </Typography>,
  ];

  const [validateErrors, setValidateErrors] = useState([]);

  const config = React.useMemo(() => DefaultTariffConfig(), []);

  const handlerError = (data) => {
    setValidateErrors(data);
  };

  return (
    <MainContainer
      title="Настройка стоимости тарифов по умолчанию"
      breadcrumbs={breadcrumbs}
      titleAs="h4"
      pageId={pagesIds.tariff}
      setPermission={setPermission}
    >
      {openModal && (
        <ChangeAlert
          isOpen={openModal}
          title="Есть функции, которые не попадут в тариф. Вы уверены, что хотите сохранить настройки?"
          successHandler={() => {
            editHandler();
            setOpenModal(false);
          }}
          cancelHandler={() => setOpenModal(false)}
        />
      )}

      <FormValidator
        name="default-system-rate-form-name"
        validationConfig={config}
        changed={defaultPrices}
        setErrorsHandler={(data) => handlerError(data)}
      >
        <Stack direction="row" spacing={2} sx={{ my: 3 }}>
          <TextField
            sx={{ width: '33%' }}
            name="Lite"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!permission?.edit}
            value={defaultPrices.Lite}
            id="Lite"
            label="Стоимость тарифа «Lite» (₽)"
            placeholder="00.00"
            required={config.Lite?.isRequired}
            error={customValidateErrors?.Lite?.text}
            helperText={customValidateErrors?.Lite?.text}
            type="number"
            defaultValue="00.00"
            onChange={({ target }) => {
              setDefaultPrices({
                ...defaultPrices,
                Lite: floatMask(target.value, '0.00'),
              });
            }}
            onWheel={(e) => e.target.blur()}
          />
          <TextField
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: '33%' }}
            value={defaultPrices.Medium}
            disabled={!permission?.edit}
            name="Medium"
            id="Medium"
            placeholder="00.00"
            defaultValue="00.00"
            label="Стоимость тарифа «Medium» (₽)"
            required={config.Medium?.isRequired}
            error={customValidateErrors?.Medium?.text}
            helperText={customValidateErrors?.Medium?.text}
            type="number"
            onChange={({ target }) => {
              setDefaultPrices({
                ...defaultPrices,
                Medium: floatMask(target.value, '0.00'),
              });
            }}
            onWheel={(e) => e.target.blur()}
          />
          <TextField
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: '33%' }}
            value={defaultPrices.Comfort}
            disabled={!permission?.edit}
            name="Comfort"
            id="Comfort"
            placeholder="00.00"
            defaultValue="00.00"
            label="Стоимость тарифа «Comfort» (₽)"
            required={config.Comfort?.isRequired}
            error={customValidateErrors?.Comfort?.text}
            helperText={customValidateErrors?.Comfort?.text}
            type="number"
            onChange={({ target }) => {
              setDefaultPrices({
                ...defaultPrices,
                Comfort: floatMask(target.value, '0.00'),
              });
            }}
            onWheel={(e) => e.target.blur()}
          />
        </Stack>
      </FormValidator>
      <Typography as="h2" variant="h4" sx={{ mt: 5, mb: 3 }}>
        Настройка состава тарифов МП
      </Typography>
      <TableContainer component={Paper} sx={{ mb: permission?.edit ? 0 : 6 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((c, index) => (
                <TableCell key={index}>
                  <b>{c.headerName}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" width={680}>
                  {edit.function == row.function ? (
                    <>
                      <FormValidator
                        name={`${row.function}-form`}
                        validationConfig={config}
                        changed={edit}
                        setErrorsHandler={(data) => handlerError(data)}
                      >
                        <IconButton
                          aria-label="save"
                          size="small"
                          disabled={
                            Object.keys(validateErrors).length ||
                            !permission?.edit
                          }
                          onClick={() => {
                            const newRows = [
                              ...rows.map((r) => {
                                if (r.function == edit.function) {
                                  r = { ...r, ...edit };
                                }
                                return r;
                              }),
                            ];
                            setEdit({});
                            setRows(newRows);
                          }}
                          color="success"
                        >
                          <CheckCircleOutlinedIcon fontSize="inherit" />
                        </IconButton>
                        {permission?.edit && (
                          <IconButton
                            disabled={!permission?.edit}
                            aria-label="cancel"
                            size="small"
                            onClick={() => setEdit({})}
                            color="error"
                          >
                            <CancelOutlinedIcon fontSize="inherit" />
                          </IconButton>
                        )}

                        <TextField
                          disabled={!permission?.edit}
                          fullWidth
                          label="Название"
                          name="name"
                          variant="outlined"
                          required={config.name.isRequired}
                          sx={{ mt: 2 }}
                          error={validateErrors?.name?.text}
                          helperText={validateErrors?.name?.text}
                          value={edit?.name}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={({ target }) =>
                            setEdit({ ...edit, name: target.value })
                          }
                        />
                        <TextField
                          disabled={!permission?.edit}
                          fullWidth
                          label="Описание"
                          name="description"
                          variant="outlined"
                          required={config.description.isRequired}
                          sx={{ mt: 2 }}
                          error={validateErrors?.description?.text}
                          helperText={validateErrors?.description?.text}
                          value={edit?.description}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={({ target }) =>
                            setEdit({ ...edit, description: target.value })
                          }
                        />
                      </FormValidator>
                    </>
                  ) : (
                    <div style={{ display: 'flex' }}>
                      <div>
                        {permission?.edit && (
                          <IconButton
                            aria-label="delete"
                            size="small"
                            sx={{ mr: 2 }}
                            onClick={() => {
                              setEdit(row);
                            }}
                            disabled={!permission?.edit}
                          >
                            <CreateOutlinedIcon fontSize="inherit" />
                          </IconButton>
                        )}
                      </div>
                      <div>
                        {}
                        <Typography as="p">
                          {row.name}{' '}
                          <Typography variant="caption">
                            ({row.function})
                          </Typography>
                        </Typography>
                        <Typography as="p" variant="caption">
                          {row.description}
                        </Typography>
                      </div>
                    </div>
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Checkbox
                    checked={row.Lite}
                    disabled={
                      edit.function === row.function || !permission?.edit
                    }
                    onChange={({ target }) =>
                      changeCheckboxHandler(row, target.checked, 'Lite')
                    }
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Checkbox
                    checked={row.Medium}
                    disabled={
                      edit.function === row.function || !permission?.edit
                    }
                    onChange={({ target }) =>
                      changeCheckboxHandler(row, target.checked, 'Medium')
                    }
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Checkbox
                    checked={row.Comfort}
                    disabled={
                      edit.function === row.function || !permission?.edit
                    }
                    onChange={({ target }) =>
                      changeCheckboxHandler(row, target.checked, 'Comfort')
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <FooterButtonBar
        showUpdateButton={false}
        showCancelButton={false}
        showDeleteButton={false}
        showCreateButton={permission?.edit}
        disabledCreateButton={
          Object.values(validateErrors).some((i) => i) ||
          Object.values(customValidateErrors).some((i) => i?.text) ||
          !permission?.edit
        }
        createButtonHandler={() => getMeSuccess()}
        createButtonText="Сохранить"
        loading={
          getDefaultTariffsApi?.loading ||
          getTariffDependenciesApi?.loading ||
          editDefaultTariffsApi?.loading
        }
      />
    </MainContainer>
  );
};
