import React from 'react';
import { Link } from 'react-router-dom';
import { URLS } from '../../utils/urls.util';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';
import { VideoServersNavigation } from './VideoServersNavigation';
import { RTSPServersList } from '../RTSPServersList/RTSPServersList';
import { Typography } from '@mui/material';

export const VideoServersRTSP = ({ permission }) => {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={URLS.VIDEO_SERVERS_BASE}
    >
      Настройки камеры
    </Link>,
    <Typography key="3" color="text.primary">
      RTSP сервера
    </Typography>,
  ];

  return (
    <FullPageLayout
      seoTitle="Настройки камеры"
      pageTitle="Настройки камеры"
      breadcrumbs={breadcrumbs}
      showHiddenData={false}
      isCreated={true}
    >
      <VideoServersNavigation activeTab={2} />

      <RTSPServersList permission={permission} />
    </FullPageLayout>
  );
};
