import { Button, Grid, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteDevice, editDevice } from '../../api/DeviceApi.js';
import { useApi } from '../../hooks/useApi.js';
import { URLS } from '../../utils/urls.util.js';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2.js';
import { setAlert } from '../../store/alert.store.js';
import { useDispatch } from 'react-redux';
import { FooterButtonBar } from '../Default/FooterButtonBar/FooterButtonBar.js';

export const DeviceFormActionButtons = ({
  device,
  disabled,
  setIsCreate,
  permission,
}) => {
  const [modal, setModal] = useState({});
  const editDeviceApi = useApi(editDevice);
  const removeDeviceApi = useApi(deleteDevice);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handlerCreateDevice = async () => {
    try {
      device.parametersCategories =
        device?.parametersCategories?.map((c, i) => ({
          ...c,
          index: i,
          parameters:
            c?.parameters?.map((p, pi) => ({ ...p, index: pi })) || [],
        })) || [];
      const deviceId = await editDeviceApi.sendRequest(device);

      dispatch(
        setAlert({ status: 200, text: 'Тип оборудования успешно добавлен' }),
      );

      navigate(URLS.DEVICES + '/' + deviceId + '/details');
      setIsCreate(false);
    } catch (error) {}
  };
  const handlerUpdateDevice = async () => {
    try {
      device.parametersCategories =
        device?.parametersCategories?.map((c, i) => ({
          ...c,
          index: i,
          parameters: c.parameters.map((p, pi) => ({ ...p, index: pi })),
        })) || [];
      await editDeviceApi.sendRequest(device);
      dispatch(
        setAlert({ status: 200, text: 'Тип оборудования успешно обновлен' }),
      );
    } catch (error) {}
  };

  const handlerRemoveDevice = async () => {
    try {
      await removeDeviceApi.sendRequest(device.id);
      dispatch(
        setAlert({ status: 200, text: 'Тип оборудования успешно удален' }),
      );
      navigate(URLS.DEVICES);
    } catch (error) {
      dispatch(setAlert({ status: 200, text: error.message }));
    }
  };
  return (
    <>
      <ActionApproveV2
        open={modal.open}
        title={modal.title}
        handleClose={modal.handleClose}
        showDescription={modal.showDescription}
        handleSuccess={modal.handleSuccess}
        handleSuccessText={modal.actionButtonText}
        showCloseButton={modal.showCloseButton}
      />
      <FooterButtonBar
        showDeleteButton={device?.id && permission?.delete}
        deleteButtonHandler={() => {
          if (device?.isVersionRevisionExist) {
            setModal({
              title:
                'Вы не можете удалить тип оборудования, так как в нем есть созданные версии и ревизии',
              open: true,
              showCloseButton: false,
              handleClose: () => setModal({ ...modal, open: false }),
              actionButtonText: 'Понятно',
              handleSuccess: () => {
                setModal({ ...modal, open: false });
              },
              showDescription: false,
            });
          } else {
            setModal({
              title: 'Вы действительно хотите удалить Тип оборудования?',
              open: true,
              handleClose: () => setModal({ ...modal, open: false }),
              actionButtonText: 'Удалить',
              handleSuccess: () => {
                handlerRemoveDevice();
                setModal({ ...modal, open: false });
              },
              showDescription: false,
            });
          }
        }}
        disabledDeleteButton={!permission?.delete}
        cancelButtonHandler={() => navigate(URLS?.DEVICES)}
        showCancelButton={true}
        updateButtonHandler={handlerUpdateDevice}
        showUpdateButton={permission?.edit && device?.id}
        disabledUpdateButton={!permission?.edit || disabled}
        showCreateButton={permission?.create && !device?.id}
        createButtonHandler={handlerCreateDevice}
        disabledCreateButton={disabled || !permission?.create}
        loading={editDeviceApi.loading || removeDeviceApi.loading}
      />
    </>
  );
};
