import React, { useEffect, useState } from 'react';
import { SingleEmployee } from '../components/SingleEmployee/SingleEmployee';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { useApi } from '../hooks/useApi';
import { getOneCompany } from '../api/CompanyApi';
import { useParams, useNavigate } from 'react-router-dom';
import { getOneUser } from '../api/UserApi';
import { pagesIds } from '../utils/pages';
import { URLS } from '../utils/urls.util';

export const SingleEmployeePage = () => {
  const [company, setCompany] = useState({});
  const [employee, setEmployee] = useState({});
  const getOneUserApi = useApi(getOneUser);
  const getOneCompanyApi = useApi(getOneCompany);
  const { companyId, employeeId } = useParams();

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  useEffect(() => {
    getOneCompanyApi
      .sendRequest(companyId, ['meta'])
      .then((company) => setCompany(company));
    if (employeeId !== 'create') {
      getOneUserApi.sendRequest(employeeId).then((admin) => setEmployee(admin));
    }
  }, []);
  return (
    <MainContainer pageId={pagesIds.company} setPermission={setPermission}>
      <SingleEmployee
        permission={permission}
        company={company}
        employee={employee}
        changeEmployeeAfterCreateOrUpdateHandler={(data) =>
          setEmployee({ ...data, id: 'unknown' })
        }
      />
    </MainContainer>
  );
};
