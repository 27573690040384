import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  deleteInstruction,
} from '../../api/InstructionApi';
import { useApi } from '../../hooks/useApi';
import { setAlert } from '../../store/alert.store';
import { URLS } from '../../utils/urls.util';
import { ContentDetail } from '../ContentDetail/ContentDetail';
import { ActionApprove } from '../Default/ActionApprove/ActionApprove';
import { InstructionForm } from '../InstructionForm/InstructionForm';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';

export const SingleInstruction = ({ id, instruction, permission }) => {
  const [isCreate, setIsCreate] = useState(false);
  const deleteApi = useApi(deleteInstruction);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const deleteInstructionCategoryHandler = async () => {
    try {
      await deleteApi.sendRequest(instruction?.id);
      navigate(URLS.INSTRUCTION);
      dispatch(setAlert({ status: 200, text: 'Инструкция удалена' }));
    } catch (error) { }
  };

  const clickToRemove = (item) => {
    setOpenModal(true);
  };

  const stepsArray = [
    {
      label: 'Сводка',
      awsRequired: false,
      activeStep: 0,
      component: (action) => (
        <ContentDetail
          data={instruction}
          handlerNextStep={() => action(1)}
          handlerDelete={() => clickToRemove()}
          conditionFirst={instruction?.id}
          conditionSecond={instruction?.id}
          key="0"
        />
      ),
    },
  ];
  useState(() => {
    if (id == 'create') {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [id]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={URLS.INSTRUCTION}>
      Инструкции
    </Link>,
    <Typography key="3" color="text.primary">
      {isCreate ? 'Новая инструкция' : instruction.title}
    </Typography>,
  ];
  return (
    <FullPageLayout
      seoTitle={
        'Инструкции - ' + (instruction?.title || 'Добавление инструкции')
      }
      pageTitle={isCreate ? 'Добавить инструкцию' : instruction?.title}
      obj={instruction}
      showComapnyId={false}
      showAwsStatus={false}
      showStatus={false}
      breadcrumbs={breadcrumbs}
      isCreated={isCreate}
    >
      <ActionApprove
        target={instruction}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleSuccess={(data) => deleteInstructionCategoryHandler()}
      />

      <InstructionForm
        instruction={instruction}
        handlerDelete={() => clickToRemove()}
        permission={permission}
      />
    </FullPageLayout>
  );
};
