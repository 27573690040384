import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createFaq, getAllFaqCategories, updateFaq } from '../../api/FaqApi';
import { useApi } from '../../hooks/useApi';
import { setAlert } from '../../store/alert.store';
import { URLS } from '../../utils/urls.util';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './FaqForm.module.scss';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { FormValidator } from '../FormValidator/FormValidator';
import { FaqValidationConfig } from '../../utils/formValidatorConfigs/config';
import { FooterButtonBar } from '../Default/FooterButtonBar/FooterButtonBar';

export const FaqForm = ({ faq, handlerDelete, permission }) => {
  const [faqState, setFaqState] = useState({});
  const [categories, setCategories] = useState([]);
  const [editorError, setEditorError] = useState({});
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [validateErrors, setValidateErrors] = useState([]);
  const [wasChange, setChange] = useState(false);

  const updateApi = useApi(updateFaq);
  const createApi = useApi(createFaq);
  const getFaqCategoriesApi = useApi(getAllFaqCategories);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    getFaqCategoriesApi
      .sendRequest([], 999, 0)
      .then((result) => setCategories(result[0]));

    // if (!permission?.edit) {
    //   document
    //     .getElementsByClassName('rdw-link-wrapper')[0]
    //     .children[0].classList.add('rdw-option-disabled');
    // }
  }, []);

  useEffect(() => {
    setFaqState(faq);
    const contentBlock = htmlToDraft(faq?.description || '');
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const editor = EditorState.createWithContent(contentState);
      setEditorState(editor);
    }
  }, [faq]);

  useEffect(() => {
    let id = {};
    if (faqState?.id || faq.id) {
      id = {
        id: faqState?.id || faq.id,
      };
    }
    let description = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    );
    description = description.replace(/s?style=("(.+?);")|("(.+?);")/i, '');

    if (
      description == '<p></p>' ||
      description == '<p></p>\n' ||
      description?.replaceAll(`&nbsp;`, '')?.replaceAll(`<p></p>`, '')?.trim()
    ) {
      setEditorError({
        title: '',
        // 'Поле обязательно для заполнения'
      });
    } else {
      setEditorError({});
    }
    let data = {
      ...id,
      title: faqState?.title || faq.title || '',
      category: {
        id: faqState?.category?.id || faq?.category?.id || '',
      },
      description: description,
    };
    for (const d in data) {
      if (!data[d]) {
        delete data[d];
      }
      if (d === 'category') {
        if (!data[d]?.id) {
          delete data[d];
        }
      }
    }

    if (faqState) {
      getFaqCategoriesApi.sendRequest([], 999, 0).then((result) => {
        setCategories(result[0]);
        setFaqState(data);
      });
    }
  }, [editorState, faq]);

  const updateHandler = async () => {
    await updateApi.sendRequest(faqState);
    dispatch(setAlert({ status: 201, text: 'Вопрос обновлен' }));
    navigate(URLS.FAQ);
    wasChange(false);
  };

  const createHandler = async () => {
    const result = await createApi.sendRequest(faqState);
    if (result) {
      dispatch(setAlert({ status: 201, text: 'Вопрос добавлен' }));

      navigate(URLS.FAQ);
    }
    wasChange(false);
  };

  const config = useMemo(() => FaqValidationConfig(), []);

  const handlerError = (data) => {
    if (wasChange) {
      setValidateErrors(data);
    }
  };

  const disabled = useMemo(
    () =>
      !!validateErrors?.title ||
      !faqState?.title ||
      !!editorError?.title ||
      !faqState?.category?.id,
    [
      editorError?.title,
      faqState?.category?.id,
      faqState?.title,
      validateErrors?.title,
    ],
  );

  return (
    <FormValidator
      name="faq-form-name"
      validationConfig={config}
      changed={faqState}
      setErrorsHandler={(data) => handlerError(data)}
    >
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <FormControl disabled={!permission?.edit} sx={{ width: '410px' }}>
          <InputLabel
            required={true}
            id="demo-simple-select-label"
            disabled={!permission?.edit}
          >
            Раздел
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="category"
            error={validateErrors?.category?.text}
            required={validateErrors?.category?.required}
            helperText={validateErrors?.category?.text}
            value={faqState?.category?.id || ''}
            label="Раздел"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) => {
              setFaqState({ ...faqState, category: { id: target.value } });
              setChange(true);
            }}
            disabled={!permission?.edit}
          >
            {categories.map((c) => (
              <MenuItem value={c.id}>{c.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Название вопроса"
          name="title"
          error={validateErrors?.title?.text}
          helperText={validateErrors?.title?.text}
          variant="outlined"
          required={true}
          value={faqState?.title}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={({ target }) => {
            setFaqState({ ...faqState, title: target.value });
            setChange(true);
          }}
          onPaste={({ target }) => {
            setFaqState({ ...faqState, title: target.value });
            setChange(true);
          }}
          disabled={!permission?.edit}
        />
      </Stack>

      <div className={styles.FaqForm__Editor}>
        <Editor
          editorState={editorState}
          onEditorStateChange={(e) => {
            if (permission.edit) {
              setEditorState(e);
              setChange(true);
            }
          }}
          placeholder="Ответ"
          editorClassName={
            permission?.edit ? 'editor-class' : 'editor-class-disabled'
          }
          readOnly={!permission?.edit}
        />
      </div>
      {wasChange && editorError.title && (
        <Typography variant="caption" as="div" color="error">
          {editorError.title}
        </Typography>
      )}

      <FooterButtonBar
        showDeleteButton={permission?.delete && faqState?.id}
        disabledDeleteButton={!permission?.delete}
        deleteButtonHandler={() => handlerDelete()}
        cancelButtonHandler={() => navigate(URLS.FAQ)}
        showCancelButton={true}
        showUpdateButton={permission?.edit && faqState?.id}
        disabledUpdateButton={disabled || !permission?.edit || !wasChange}
        updateButtonHandler={updateHandler}
        showCreateButton={permission?.create && !faqState?.id}
        disabledCreateButton={
          disabled ||
          !permission?.create ||
          !faqState?.title?.trim() ||
          !faqState?.description
            ?.replaceAll(`&nbsp;`, '')
            ?.replaceAll(`<p></p>`, '')
            ?.trim() ||
          !wasChange
        }
        createButtonHandler={createHandler}
        loading={updateApi.loading || createApi.loading}
      />
    </FormValidator>
  );
};
