import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './BigNavigationItem.module.scss';

export const BigNavigationItem = ({ nav }) => {
  return (
    <Link to={nav.url} className={styles.BigNavigationItem}>
      <Card variant="outlined" className={styles.BigNavigationItem__Card}>
        <CardContent>
          <div className={styles.BigNavigationItem__Card__Image}>
            <img src={nav.img} />
          </div>
          <Typography className={styles.BigNavigationItem__Title}>
            {nav.title}
          </Typography>
          {nav.showCount && (
            <Typography variant="caption">
              Всего элементов: {nav?.count || 0}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Link>
  );
};
