import {
  Button,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { getAllUsersByOrganizationId } from '../../api/UserApi';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

export const EmployeeList = ({ company, permission }) => {
  const userApi = useApi(getAllUsersByOrganizationId);
  const [employees, setEmployees] = useState([]);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const getEmployees = async (offset = 0) => {
    const [result, count] = await userApi.sendRequest(
      limit,
      offset,
      {
        q: `organizationId:${company.id}`,
      },
      ['/OrganizationAdministrator'],
    );

    setEmployees(result);

    setCount(Math.ceil(count / limit));
  };
  useEffect(() => {
    if (company) {
      getEmployees(0);
    }
  }, [company]);

  const handleChange = (event, value) => {
    setPage(value);
    getEmployees(limit * (value - 1));
  };

  const navigate = useNavigate();
  return (
    <>
      {permission?.edit && (
        <Button
          variant="outlined"
          color="success"
          sx={{ mt: 3 }}
          onClick={() => navigate(`/companies/${company.id}/employees/create`)}
          disabled={!permission?.edit}
        >
          Добавить сотрудника
        </Button>
      )}

      <Table
        sx={{
          minWidth: 650,
          border: 'solid 1px #eee',
          borderRadius: '5px',
          mt: 3,
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell alignt="left">
              <b>ФИО</b>
            </TableCell>
            <TableCell align="left">
              <b>Email</b>
            </TableCell>
            <TableCell align="left">
              <b>Роль</b>
            </TableCell>
            <TableCell align="left">
              <b>Статус</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {employees.map((employee) => (
            <TableRow
              key={employee.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={() =>
                navigate(`/companies/${company.id}/employees/${employee.id}`)
              }
            >
              <TableCell align="left" component="th" scope="row">
                <Stack direction="row" spacing={1} alignItems="center">
                  {employee.enabled ? (
                    <Tooltip title="Пользователь активен" placement="left">
                      <CheckCircleOutlineOutlinedIcon
                        fontSize="13px"
                        color="success"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Пользователь неактивен" placement="left">
                      <HighlightOffOutlinedIcon fontSize="13px" color="error" />
                    </Tooltip>
                  )}

                  <Typography>
                    {employee?.firstName + ' ' + employee?.lastName}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="left">{employee?.email}</TableCell>
              <TableCell align="left">Администратор</TableCell>
              <TableCell align="left">
                {employee?.emailVerified
                  ? 'Принял приглашение'
                  : 'Не принял приглашение'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {count > 0 && (
        <Pagination
          count={count}
          page={page}
          onChange={handleChange}
          sx={{ mt: 3, mb: 4, ml: 'auto', display: 'block' }}
        />
      )}
    </>
  );
};
