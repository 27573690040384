import axios from 'axios';
import { ENV } from '../utils/env';
import AuthService from './KeycloakApi';

export const getAllInstructionCategories = async (
  relations,
  limit = 16,
  offset = 0,
) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/instruction/get-all-categories`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        limit: limit,
        offset: offset,
        relations,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const getOneInstructionCategory = async (id) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/instruction/get-one-category/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const deleteInstructionCategory = async (id) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'delete',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/instruction/remove-category`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        id,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createInstructionCategory = async (category) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/instruction/create-category`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: category,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const uploadInstructionFile = async (id, file) => {
  try {
    const token = AuthService.getToken();
    const formdata = new FormData();
    formdata.append('file', file);
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/instruction/upload/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'multipart/form-data',
      },
      data: formdata,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateInstructionCategory = async (category) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'patch',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/instruction/update-category`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: category,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getAllInstruction = async (
  relations,
  limit = 16,
  offset = 0,
  categoryId = null,
  search = '',
  orderObj = {},
) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/instruction/get-all`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        limit: limit,
        offset: offset,
        relations,
        categoryId,
        search,
        order: orderObj,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const getOneInstruction = async (id, relations) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/instruction/get-one/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        relations,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const deleteInstruction = async (id) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'delete',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/instruction/remove`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        id,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createInstruction = async (category) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/instruction/create`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: category,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const editInstruction = async (data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/instruction/edit`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const removeInstructionFile = async (
  categoryId,
  instructionId,
  fileId,
) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'delete',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/instruction/remove-file/${categoryId}/${instructionId}/${fileId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getInstruction = async (id) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/instruction/get-instruction/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
