import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import React, { useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/alert.store';
import { deleteFaqCategory } from '../../api/FaqApi';
import { URLS } from '../../utils/urls.util';
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { ActionApprove } from '../Default/ActionApprove/ActionApprove';

export const FaqCategoriesTable = ({
  categories,
  updateCategoryListHandler,
  permission,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [targetObj, setTargetObj] = useState({});
  const deleteApi = useApi(deleteFaqCategory);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const removeCategoryHandler = async (id) => {
    const result = await deleteApi.sendRequest(id);
    if (result) {
      updateCategoryListHandler(categories.filter((c) => c.id != id));
      dispatch(setAlert({ status: 201, text: 'Раздел удален' }));
      setOpenModal(false);
    }
  };
  const clickToRemove = (item) => {
    setTargetObj(item);
    setOpenModal(true);
  };
  return (
    <>
      <ActionApprove
        target={targetObj}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleSuccess={(data) => removeCategoryHandler(data.id)}
        showDescription={true}
      />
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Название раздела вопроса</b>
              </TableCell>
              <TableCell align="right">
                <b>Дата создания</b>
              </TableCell>
              <TableCell align="right">
                <b>Дата изменения</b>
              </TableCell>
              <TableCell align="right">
                <b>Действия</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories?.map((category) => (
              <TableRow
                key={category.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" width="30%">
                  {category.title}
                </TableCell>
                <TableCell align="right">
                  {format(new Date(category.created), 'dd.MM.yyyy HH:mm:ss')}
                </TableCell>
                <TableCell align="right">
                  {format(new Date(category.updated), 'dd.MM.yyyy HH:mm:ss')}
                </TableCell>
                <TableCell align="right">
                  {permission?.delete && (
                    <IconButton
                      color="error"
                      aria-label="Удалить раздел"
                      component="label"
                      onClick={() => clickToRemove(category)}
                      disabled={!permission?.delete}
                    >
                      <DeleteOutlinedIcon />
                    </IconButton>
                  )}
                  <IconButton
                    color="primary"
                    aria-label="Удалить раздел"
                    component="label"
                    onClick={() => {
                      navigate(URLS.FAQ_CATEGORIES + '/' + category.id);
                    }}
                  >
                    <RemoveRedEyeOutlinedIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
