import { Stack, Typography } from '@mui/material';
import { format, isValid, parse } from 'date-fns';
import React from 'react';

export const UserInfo = ({ user }) => {
  const validDate = (dateString) => {
    if (dateString) {
      const parsedDate = parse(
        dateString,
        'MM/dd/yyyy HH:mm:ss xxx',
        new Date(),
      );
      const isValidDate = isValid(parsedDate);

      if (isValidDate) {
        return format(parsedDate, 'dd.MM.yyyy HH:mm:ss');
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  };
  return (
    <Stack direction="row" spacing={5}>
      <Stack sx={{ width: '33.3%' }}>
        <Typography>
          <Stack direction="row" spacing={2}>
            <div style={{ opacity: '0.6', width: '100px' }}>Создатель</div>
            <div>
              {(user?.attributes?.creatorName &&
                user?.attributes?.creatorName[0]) ||
                (user?.attributes?.CreatedBy &&
                  user?.attributes?.CreatedBy[0]) ||
                '-'}
            </div>
          </Stack>
        </Typography>
        <Typography>
          <Stack direction="row" spacing={2}>
            <div style={{ opacity: '0.6', width: '100px' }}>Редактор</div>
            <div>
              {(user?.attributes?.updaterName &&
                user?.attributes?.updaterName[0]) ||
                (user?.attributes?.UpdatedBy &&
                  user?.attributes?.UpdatedBy[0]) ||
                '-'}
            </div>
          </Stack>
        </Typography>
      </Stack>
      <Stack sx={{ width: '33.3%' }}>
        <Typography>
          <Stack direction="row" spacing={2}>
            <div style={{ opacity: '0.6', width: '100px' }}>Создано</div>
            <div>
              {(user?.attributes?.creatorDate &&
                validDate(user?.attributes?.creatorDate[0])) ||
                '-'}
            </div>
          </Stack>
        </Typography>
        <Typography>
          <Stack direction="row" spacing={2}>
            <div style={{ opacity: '0.6', width: '100px' }}>Обновлено</div>
            <div>
              {(user?.attributes?.updateDate &&
                validDate(user?.attributes?.updateDate[0])) ||
                '-'}
            </div>
          </Stack>
        </Typography>
      </Stack>
      <Stack sx={{ width: '33.3%' }}>
        <Typography>
          <Stack direction="row" spacing={2}>
            <div style={{ opacity: '0.6', width: '100px' }}>Статус</div>
            <div>
              {user?.emailVerified
                ? 'Принял приглашение'
                : 'Не принял приглашение'}
            </div>
          </Stack>
        </Typography>
      </Stack>
    </Stack>
  );
};
