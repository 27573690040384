import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DeviceParameterValidationConfig } from '../../utils/formValidatorConfigs/config';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { DeviceParameterItemForm } from './DeviceParameterItemForm';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';
import { SortableKnob } from 'react-easy-sort';
export const DeviceParameterItem = ({
  id,
  parameter,
  index,
  listOfTitle,
  setListOfTitle,
  parameterCheckerHandler,
  updateParameterHandler,
  removeParameterHandler,
  isExist,
  permission,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [validateErrors, setValidateErrors] = useState([]);
  const ref = useRef(null);
  const [openApprove, setOpenApprove] = useState(false);

  const [formDisabled, setFormDisabled] = useState(true);
  const [wasChange, setChange] = useState(false);

  const config = useMemo(() => {
    return DeviceParameterValidationConfig({
      customUniq: listOfTitle || [],
    });
  }, [listOfTitle, id, parameter]);

  useEffect(() => {
    const configKeys = Object.keys(config);

    if (wasChange && Object.keys(validateErrors).length === 0) {
      let canSave = true;
      configKeys.forEach((key) => {
        if (canSave && config[key]?.isRequired && !(key in parameter)) {
          canSave = false;
        }
      });
      setFormDisabled(!canSave);
      parameterCheckerHandler({
        id: parameter?.id || parameter?.sortId,
        canSave,
      });
    } else if (!formDisabled) {
      setFormDisabled(true);
    }
  }, [parameter, validateErrors]);

  return (
    <>
      <Accordion
        expanded={expanded}
        variant="outlined"
        ref={ref}
        id={id}
        sx={{ mt: 0, pr: 0 }}
      >
        <AccordionSummary
          aria-controls="panel4bh-content"
          id="panel4bh-header"
          sx={{ mt: 0, pr: 0 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Stack
                direction="row"
                onClick={() => setExpanded(!expanded)}
                sx={{ mt: 0 }}
              >
                {!expanded ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                <Typography sx={{ width: '80%', flexShrink: 0, mt: 0 }}>
                  {parameter?.name || 'Новый параметр'}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack
                direction="row"
                spacing={1}
                sx={{ display: 'flex', justifyContent: 'end' }}
              >
                {permission?.edit && (
                  <IconButton
                    aria-label="delete"
                    size="small"
                    disabled={isExist || !permission?.edit}
                    sx={{ m: 0, p: 0 }}
                    onClick={() => updateParameterHandler(parameter)}
                  >
                    <CreateOutlinedIcon />
                  </IconButton>
                )}
                {permission?.edit && (
                  <IconButton
                    aria-label="delete"
                    size="small"
                    disabled={isExist || !permission?.edit}
                    sx={{ m: 0, p: 0 }}
                    onClick={() => setOpenApprove(true)}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                )}
                {permission?.edit && (
                  <SortableKnob>
                    <IconButton
                      aria-label="dragndrop"
                      size="small"
                      disabled={isExist || !permission?.edit}
                      sx={{ m: 0, p: 0 }}
                      ref={ref}
                    >
                      <DragIndicatorOutlinedIcon />
                    </IconButton>
                  </SortableKnob>
                )}
              </Stack>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ mt: 0 }}>
          <DeviceParameterItemForm
            permission={permission}
            parameter={parameter}
            editableParameter={parameter}
            index={index}
            currentData={{}}
            disabledField={true}
            showTitle={false}
            hideButtons={true}
            setListOfTitle={(data) => setListOfTitle(data)} //12
            setEditableParameter={() => {
              return;
            }}
            changeFinalData={(data) => {
              return;
            }}
            closeHandler={() => {
              return;
            }}
          />
        </AccordionDetails>
      </Accordion>
      <ActionApproveV2
        target={parameter}
        open={openApprove}
        title="Вы уверены что хотите удалить параметр из списка?"
        handleClose={() => setOpenApprove(false)}
        handleSuccess={() => {
          removeParameterHandler(parameter.id);
          setOpenApprove(false);
        }}
        showDescription={false}
      />
    </>
  );
};
