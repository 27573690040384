import React, { useState } from 'react';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { VideoServersRTSP } from '../components/VideoServers/VideoServersRTSP';
import { pagesIds } from '../utils/pages';

export const VideoServersRTSPPage = () => {
  const [permission, setPermission] = useState({});

  return (
    <MainContainer
      pageId={pagesIds.defaultCameraSettings}
      setPermission={setPermission}
    >
      <VideoServersRTSP permission={permission} />
    </MainContainer>
  );
};
