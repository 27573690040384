import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { SingleRevision } from '../components/SingleRevision/SingleRevision';
import { useApi } from '../hooks/useApi';
import {
  getOneDevice,
  getOneDeviceRevisionMap,
  getOneDeviceVersionRevision,
} from '../api/DeviceApi';
import { v4 as uuidV4 } from 'uuid';
import { pagesIds } from '../utils/pages';
import { URLS } from '../utils/urls.util';

export const SingleRevisionPage = () => {
  const { id, versionRevisionId, versionId, revisionId } = useParams();
  const [device, setDevice] = useState({});
  const [versionRevision, setVersionRevision] = useState({});
  const [version, setVersion] = useState({});
  const [revision, setRevison] = useState({});
  const [isExist, setIsExist] = useState(false);
  const getOneDeviceApi = useApi(getOneDevice);
  const getOneDeviceVersionRevisionApi = useApi(getOneDeviceVersionRevision);
  const getOneDeviceRevisionMapApi = useApi(getOneDeviceRevisionMap);

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);


  useEffect(() => {
    getOneDeviceApi.sendRequest(id).then((result) => setDevice(result));
    getOneDeviceRevisionMapApi.sendRequest(id).then((result) => {
      setVersion(result?.rows?.find((r) => r.versionId == versionId));
      setRevison(result?.headers?.find((h) => h.revisionId == revisionId));
    });
    if (versionRevisionId !== 'create') {
      getOneDeviceVersionRevisionApi
        .sendRequest(id, versionRevisionId)
        .then((result) => {
          setVersionRevision(result);
          setIsExist(result?.isUsed || result?.isExist || false);
        });
    }
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        background: '#f8f8fc',
      }}
    >
      <MainContainer pageId={pagesIds.devices} setPermission={setPermission}>
        <SingleRevision
          device={device}
          versionRevision={versionRevision}
          setVersionRevision={(data) =>
            getOneDeviceVersionRevisionApi
              .sendRequest(data.deviceId, data.fromVersionRevisionId)
              .then((result) => {
                delete data.accessPointTypeParams;
                const final = JSON.parse(JSON.stringify(data));

                setVersionRevision({
                  ...final,
                  accessPointTypeParams: result?.accessPointTypeParams?.map(
                    (p) => {
                      p.id = uuidV4();
                      return p;
                    },
                  ),
                });
              })
          }
          revision={revision}
          version={version}
          isExist={isExist}
          isCreate={versionRevisionId == 'create'}
          permission={permission}
        />
      </MainContainer>
    </div>
  );
};
