import React, { useEffect, useMemo, useState } from 'react';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import {
  createFaqCategory,
  deleteFaq,
  deleteFaqCategory,
  getAllFaq,
  getAllFaqCategories,
  updateFaqCategory,
} from '../api/FaqApi';
import { URLS } from '../utils/urls.util';
import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { pagesIds } from '../utils/pages';
import { FaqInstructionLayoutV2 } from '../components/FaqInstructionLayoutV2/FaqInstructionLayoutV2';
import { FormValidator } from '../components/FormValidator/FormValidator';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/alert.store';
import { FaqCategoryValidationConfig } from '../utils/formValidatorConfigs/config';
import { ActionApproveV2 } from '../components/Default/ActionApprove/ActionApproveV2';
import arrayMove from 'array-move';
import { FooterButtonBar } from '../components/Default/FooterButtonBar/FooterButtonBar';
export const FaqPageV2 = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Typography key="2" color="text.primary">
      Частые вопросы (FAQ)
    </Typography>,
  ];

  const categoriesApi = useApi(getAllFaqCategories);
  const faqApi = useApi(getAllFaq);
  const updateApi = useApi(updateFaqCategory);
  const createApi = useApi(createFaqCategory);
  const deleteApi = useApi(deleteFaqCategory);
  const deleteFaqApi = useApi(deleteFaq);

  const [selectCategory, setSelectCategory] = useState(null);
  const [search, setSearch] = useState('');
  const [sortData, setTortData] = useState({});
  const [deleteCategoryCandidate, setDeleteCategoryCandidate] = useState({});
  const [deleteItemCandidate, setDeleteItemCandidate] = useState({});
  const [faqCount, setFaqCount] = useState(0);
  const [faqPage, setFaqPage] = useState(1);
  const [openApproveRemoveItem, setOpenApproveRemoveItem] = useState(false);
  const [openApproveRemoveCategory, setOpenApproveRemoveCategory] =
    useState(false);
  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);
  const [faq, setFaq] = useState([]);
  const [categories, setCategories] = useState([]);
  const [wasChangedCategory, setWasChangedCategory] = useState(false);
  const location = useLocation();

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  useEffect(() => {
    categoriesApi.sendRequest([], 999, 0).then((result) => {
      setCategories(result[0]);
      setSelectCategory(result[0][0]?.id);
    });
  }, [location]);

  useEffect(() => {
    if (selectCategory) {
      getFaqByCategoryId(selectCategory);
    }
  }, [selectCategory]);
  const getFaqByCategoryId = async (
    categoryId,
    page = faqPage,
    search = '',
    orderObj,
  ) => {
    const [result, count] = await faqApi.sendRequest(
      ['category'],
      10,
      (page - 1) * 10,
      categoryId,
      search,
      orderObj,
    );

    setFaqCount(count);
    setFaq(result);
  };

  const paginationHandler = (p) => {
    setFaqPage(p);

    getFaqByCategoryId(search ? null : selectCategory, p, search, sortData);
  };

  //   --

  const [categoryState, setFaqCategoryState] = useState({});
  const [validateErrors, setValidateErrors] = useState([]);

  const dispatch = useDispatch();

  const updateHandler = async () => {
    await updateApi.sendRequest(categoryState);
    dispatch(setAlert({ status: 201, text: 'Раздел обновлен' }));
    categoriesApi.sendRequest([], 999, 0).then((result) => {
      setCategories(result[0]);
    });
    setOpenAddCategoryModal(false);
    setFaqCategoryState({});
  };

  const createHandler = async () => {
    await createApi.sendRequest(categoryState);
    categoriesApi.sendRequest([], 999, 0).then((result) => {
      setCategories(result[0]);
    });
    dispatch(setAlert({ status: 201, text: 'Раздел добавлен' }));
    setOpenAddCategoryModal(false);
    setFaqCategoryState({});
  };
  const removeCategoryHandler = async () => {
    const result = await deleteApi.sendRequest(deleteCategoryCandidate?.id);
    if (result) {
      categoriesApi.sendRequest([], 999, 0).then((result) => {
        setCategories(result[0]);
      });
      dispatch(setAlert({ status: 201, text: 'Раздел удален' }));
      setOpenApproveRemoveCategory(false);
    }
  };

  const handlerError = (data) => {
    setValidateErrors(data);
  };
  const config = useMemo(() => FaqCategoryValidationConfig(), []);

  const onSortEnd = (oldIndex, newIndex) => {
    setCategories((array) => {
      let newArray = arrayMove(array, oldIndex, newIndex);
      newArray = newArray.map((item, index) => ({
        ...item,
        order: index,
      }));

      for (const category of newArray) {
        updateApi.sendRequest(category);
      }
      return newArray;
    });
  };

  const removeFaqHandler = async () => {
    setOpenApproveRemoveItem(false);
    await deleteFaqApi.sendRequest(deleteItemCandidate?.id);

    getFaqByCategoryId(deleteItemCandidate?.category?.id);
    dispatch(setAlert({ status: 200, text: 'Вопрос удален' }));
  };

  const editFaqHandler = (f) => {
    navigate(URLS.FAQ + '/' + f.id);
  };
  return (
    <MainContainer
      pageId={pagesIds.faq}
      setPermission={setPermission}
      title="Частые вопросы (FAQ)"
      breadcrumbs={breadcrumbs}
      actionButton={
        permission.create
          ? {
              text: 'Добавить вопрос',
              action: () => navigate(URLS.FAQ + '/create'),
            }
          : null
      }
    >
      <FaqInstructionLayoutV2
        categories={categories}
        items={faq}
        setSelectCategory={(data) => setSelectCategory(data)}
        addCategoryActionButtonHandler={() => setOpenAddCategoryModal(true)}
        editCategoryActionButtonHandler={(data) => {
          setOpenAddCategoryModal(true);
          setFaqCategoryState(data);
        }}
        deleteCategoryActionButtonHandler={(data) => {
          setDeleteCategoryCandidate(data);
          setOpenApproveRemoveCategory(true);
        }}
        onSortEnd={onSortEnd}
        deleteItemActionButtonHandler={(data) => {
          setDeleteItemCandidate(data);
          setOpenApproveRemoveItem(true);
        }}
        paginationCount={Math.ceil(+faqCount / 10)}
        page={faqPage}
        paginationHandler={(e, value) => paginationHandler(value)}
        editItemActionButtonHandler={(data) => editFaqHandler(data)}
        searchActionHandler={(data) => {
          setFaqPage(1);
          getFaqByCategoryId(null, 1, data);
          setSearch(data);
        }}
        search={search}
        categoryLoading={categoriesApi.loading}
        itemsLoading={faqApi?.loading}
        permission={permission}
        sortItemsHandler={(field, order) => {
          setTortData({ field, order });
          getFaqByCategoryId(search ? null : selectCategory, 1, search, {
            field,
            order,
          });
        }}
        selectCategory={selectCategory}
      />

      <Modal
        open={openAddCategoryModal}
        onClose={() => {
          setOpenAddCategoryModal(false);
          setFaqCategoryState({});
        }}
        sx={{
          width: '100%',
          overflow: 'scroll',
        }}
      >
        <Box
          sx={{
            maxWidth: '540px',
            margin: '14% auto',
            width: '100%',
            borderRadius: '8px',
            background: '#fff',
            padding: '20px',
          }}
        >
          <Typography variant="h5">
            {categoryState?.id
              ? 'Редактирование раздела'
              : 'Добавление нового раздела'}
          </Typography>
          <FormValidator
            name="faq-category-form-name"
            validationConfig={config}
            changed={categoryState}
            setErrorsHandler={(data) => handlerError(data)}
          >
            <TextField
              fullWidth
              label="Название раздела"
              variant="outlined"
              name="title"
              error={validateErrors?.title?.text}
              helperText={validateErrors?.title?.text}
              required={config?.title?.isRequired}
              sx={{ my: 3 }}
              value={categoryState?.title}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value } }) => {
                setFaqCategoryState({
                  ...categoryState,
                  title: value,
                });
                setWasChangedCategory(true);
              }}
              onPaste={({ target: { value } }) => {
                setFaqCategoryState({
                  ...categoryState,
                  title: value,
                });
                setWasChangedCategory(true);
              }}
              disabled={!permission?.edit}
            />
          </FormValidator>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ width: '100%' }}
          >
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => {
                setOpenAddCategoryModal(false);
                setFaqCategoryState({});
                setWasChangedCategory(false);
              }}
            >
              Отмена
            </Button>

            {categoryState?.id ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  updateHandler();
                  setWasChangedCategory(false);
                }}
                disabled={
                  !categoryState?.title?.trim() ||
                  Object.keys(validateErrors).length ||
                  !wasChangedCategory
                }
              >
                Обновить
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  createHandler();
                  setWasChangedCategory(false);
                }}
                disabled={
                  !categoryState?.title?.trim() ||
                  Object.keys(validateErrors).length
                }
              >
                Создать
              </Button>
            )}
          </Stack>
        </Box>
      </Modal>
      <ActionApproveV2
        target={deleteCategoryCandidate}
        open={openApproveRemoveCategory}
        title="Вы действительно хотите удалить данный раздел?"
        handleClose={() => {
          setOpenApproveRemoveCategory(false);
          setDeleteCategoryCandidate({});
        }}
        handleSuccess={() => removeCategoryHandler()}
        showDescription={false}
      />
      <ActionApproveV2
        target={deleteItemCandidate}
        open={openApproveRemoveItem}
        title="Вы действительно хотите удалить данный вопрос?"
        handleClose={() => {
          setOpenApproveRemoveItem(false);
          setDeleteItemCandidate({});
        }}
        handleSuccess={() => removeFaqHandler()}
        showDescription={false}
      />
    </MainContainer>
  );
};
