import { Button, Grid } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { setAlert } from '../../store/alert.store';
import { URLS } from '../../utils/urls.util';
import { inviteUser, inviteUserByCompany, updateUser } from '../../api/UserApi';
import { FooterButtonBar } from '../Default/FooterButtonBar/FooterButtonBar';
export const EmployeeFormActionButtons = ({
  company,
  employeee,
  disabled,
  wasChanged,
  wasChangeHandler,
  changeEmployeeAfterCreateOrUpdateHandler,
  permission,
}) => {
  const navigate = useNavigate();
  const inviteUserApi = useApi(inviteUserByCompany);
  // const updateEmployeeApi = useApi(updateEmployee);
  const updateEmployeeApi = useApi(updateUser);
  const dispatch = useDispatch();

  const handlerCreateEmployee = async () => {
    try {
      const data = {
        ...employeee,
        groups: ['OrganizationAdministrator'],
        attributes: {
          organizationId: company.id,
          phone: employeee.phone,
          middleName: employeee?.middleName,
        },
      };
      delete data?.phone;
      delete data?.middleName;
      const result = await inviteUserApi.sendRequest(data);

      dispatch(
        setAlert({
          status: 201,
          text: 'Администратор добавлен',
        }),
      );
      navigate(URLS.COMPANIES + '/' + company.id + '/employees');
      changeEmployeeAfterCreateOrUpdateHandler(employeee);
      wasChangeHandler();
    } catch (error) {}
  };
  const handlerUpdateEmployee = async () => {
    try {
      console.log(employeee);
      const data = {
        ...employeee,
        groups: ['OrganizationAdministrator'],
        attributes: {
          ...employeee?.attributes,
          organizationId: company.id,
          phone: employeee.phone,
          middleName: employeee?.middleName,
        },
      };
      delete data?.phone;
      delete data?.middleName;
      await updateEmployeeApi.sendRequest(data);
      dispatch(
        setAlert({ status: 201, text: 'Пользователь успешно обновлен' }),
      );
      navigate(URLS.COMPANIES + '/' + company.id + '/employees');
      wasChangeHandler();
      return {
        ...employeee,
        company: { id: company.id },
      };
    } catch (error) {}
  };

  return (
    <>
      <FooterButtonBar
        showDeleteButton={false}
        cancelButtonHandler={() => navigate(-1)}
        showCancelButton={true}
        updateButtonHandler={handlerUpdateEmployee}
        showUpdateButton={permission?.edit && employeee?.id}
        disabledUpdateButton={disabled || !permission?.edit}
        showCreateButton={permission?.create && !employeee?.id}
        createButtonHandler={handlerCreateEmployee}
        disabledCreateButton={disabled || !permission?.edit}
        createButtonText="Добавить"
        cancelButtonText="Отмена"
        loading={inviteUserApi.loading || updateEmployeeApi.loading}
      />
    </>
  );
};
