import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Grid,
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  checkApiUrl,
  deleteRtspServer,
  saveNewRtspServer,
  updateRtspServer,
} from '../../api/RtspServerApi';
import { setAlert } from '../../store/alert.store';
import { RtspServerFormActionButtons } from '../RtspServerFormActionButtons/RtspServerFormActionButtons';
import { FormValidator } from '../FormValidator/FormValidator';
import { RtspServerValidationConfig } from '../../utils/formValidatorConfigs/config';
import { useApi } from '../../hooks/useApi';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const RtspServerFrom = ({
  isCreate,
  prevPage,
  rtspServer,
  permission,
  ...props
}) => {
  const ips = [
    '255.255.255.0',
    '255.255.255.128',
    '255.255.255.192',
    '255.255.255.224',
    '255.255.255.240',
    '255.255.255.248',
    '255.255.255.252',
  ];

  const [rtspServerState, setRtspServerState] = useState({});
  const [validateErrors, setValidateErrors] = useState([]);
  const [customApiUrlError, setCustomApiUrlError] = useState('');
  const [wasChange, setChange] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [rangeMask, setRageMask] = useState('');
  const saveNewRtspServerApi = useApi(saveNewRtspServer);
  const upadteRtspServerApi = useApi(updateRtspServer);
  const deleteRtspServerApi = useApi(deleteRtspServer);
  const [openApprove, setOpenApprove] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const config = useMemo(() => RtspServerValidationConfig(), []);

  useEffect(() => {
    const configKeys = Object.keys(config);

    if (wasChange && Object.keys(validateErrors).length === 0) {
      let canSave = true;
      configKeys.forEach((key) => {
        if (canSave && config[key]?.isRequired && !(key in rtspServerState)) {
          canSave = false;
        }
      });
      setFormDisabled(!canSave && !customApiUrlError);
    } else if (!formDisabled) {
      setFormDisabled(true);
    }
  }, [rtspServerState, validateErrors]);

  const handlerSaveNewRtspServer = async () => {
    try {
      for (const key in rtspServerState) {
        if (key === 'rangeExcludeIpAddresses') {
          if (!rtspServerState[key]) {
            rtspServerState[key] = [];
          } else {
            rtspServerState[key] = rtspServerState[key]?.filter((item) => item);
          }
        }
      }

      await saveNewRtspServerApi.sendRequest(rtspServerState);
      navigate(prevPage);
      dispatch(
        setAlert({
          type: 'success',
          text: 'RTSP сервер добавлен',
          status: 201,
        }),
      );
    } catch (error) {}
  };

  const handlerUpdateRtspServer = async () => {
    try {
      for (const key in rtspServerState) {
        if (key === 'rangeExcludeIpAddresses') {
          if (!rtspServerState[key]) {
            rtspServerState[key] = [];
          } else {
            rtspServerState[key] = rtspServerState[key]?.filter((item) => item);
          }
        }
      }
      await upadteRtspServerApi.sendRequest(rtspServerState);
      navigate(prevPage);
      dispatch(
        setAlert({
          type: 'success',
          text: 'RTSP сервер обновлен',
          status: 200,
        }),
      );
    } catch (error) {}
  };
  const handlerDeleteRtspServer = async () => {
    const result = await deleteRtspServerApi.sendRequest({
      id: rtspServerState.id,
    });
    navigate(prevPage);
    dispatch(setAlert({ status: 201, text: 'RTSP сервер удален' }));
  };

  useEffect(() => {
    setRtspServerState(rtspServer);
  }, [rtspServer]);

  useEffect(() => {
    setRageMask(rtspServerState?.rangeMask);
  }, [rtspServerState]);

  const handlerError = (data) => {
    setValidateErrors(data);
  };

  const onChange = (value, fieldName) => {
    if (!wasChange) {
      setChange(true);
    }
    setRtspServerState({ ...rtspServerState, [fieldName]: value });
  };

  const handlerChancel = () => {
    navigate(prevPage);
  };

  return (
    <div {...props}>
      <FormValidator
        name="rtsp-server-form-name"
        validationConfig={config}
        changed={{
          ...rtspServerState,
          rangeMask: rangeMask || rtspServerState?.rangeMask,
        }}
        setErrorsHandler={(data) => handlerError(data)}
      >
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Название"
              disabled={!permission?.edit}
              placeholder="Название"
              variant="outlined"
              sx={{ mt: 2 }}
              name="name"
              required={config.name.isRequired}
              error={validateErrors?.name?.text}
              helperText={validateErrors?.name?.text}
              value={rtspServerState?.name}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'name')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Описание"
              disabled={!permission?.edit}
              placeholder="Описание"
              variant="outlined"
              sx={{ mt: 2 }}
              name="description"
              required={config.description.isRequired}
              error={validateErrors?.description?.text}
              helperText={validateErrors?.description?.text}
              value={rtspServerState?.description}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'description')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Url"
              placeholder="Url"
              disabled={!permission?.edit}
              variant="outlined"
              sx={{ mt: 2 }}
              name="apiUrl"
              required={config.apiUrl.isRequired}
              error={validateErrors?.apiUrl?.text || customApiUrlError}
              helperText={validateErrors?.apiUrl?.text || customApiUrlError}
              value={rtspServerState?.apiUrl}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={async ({ target }) => {
                onChange(target.value, 'apiUrl');
                try {
                  await checkApiUrl(target.value);
                  setCustomApiUrlError('');
                } catch (error) {
                  setCustomApiUrlError(error.response.data.message);
                }
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              type="number"
              label="Порт"
              placeholder="Порт"
              disabled={!permission?.edit}
              variant="outlined"
              sx={{ mt: 2 }}
              name="rtspConnectPort"
              required={config.rtspConnectPort.isRequired}
              error={validateErrors?.rtspConnectPort?.text}
              helperText={validateErrors?.rtspConnectPort?.text}
              value={rtspServerState?.rtspConnectPort}
              onWheel={(e) => e.target.blur()}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) =>
                onChange(target.value, 'rtspConnectPort')
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Логин"
              placeholder="Логин"
              disabled={!permission?.edit}
              variant="outlined"
              sx={{ mt: 2 }}
              name="login"
              required={config.login.isRequired}
              error={validateErrors?.login?.text}
              helperText={validateErrors?.login?.text}
              value={rtspServerState?.login}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'login')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Пароль"
              placeholder="Пароль"
              disabled={!permission?.edit}
              variant="outlined"
              sx={{ mt: 2 }}
              name="password"
              required={config.password.isRequired}
              error={validateErrors?.password?.text}
              helperText={validateErrors?.password?.text}
              value={rtspServerState?.password}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'password')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <FormControl
              fullWidth
              required={config?.rangeMask?.isRequired}
              sx={{ mt: 2 }}
              disabled={!permission?.edit}
              error={validateErrors?.rangeMask?.text && rangeMask}
              helperText={validateErrors?.rangeMask?.text}
            >
              <InputLabel id="rangeMaskLabel">Маска IP камеры</InputLabel>
              <Select
                labelId="rangeMaskLabel"
                id="rangeMaskSelect"
                name="rangeMask"
                disabled={!permission?.edit}
                label="Маска IP камеры"
                placeholder="Маска IP камеры"
                value={rangeMask}
                onChange={({ target }) => onChange(target.value, 'rangeMask')}
                input={<OutlinedInput label="Маска IP камеры" />}
                MenuProps={MenuProps}
              >
                {ips?.map((item, index) => (
                  <MenuItem key={`ip-${index}`} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Диапазон IP-адресов"
              placeholder="Диапазон IP-адресов"
              variant="outlined"
              sx={{ mt: 2 }}
              disabled={!permission?.edit}
              value={rtspServerState.rangeIpAddress}
              name="rangeIpAddress"
              required={config.rangeIpAddress.isRequired}
              error={validateErrors?.rangeIpAddress?.text}
              helperText={validateErrors?.rangeIpAddress?.text}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) =>
                onChange(target.value, 'rangeIpAddress')
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Исключенные IP адреса для камер"
              placeholder="Исключенные IP адреса для камер"
              variant="outlined"
              disabled={!permission?.edit}
              sx={{ mt: 2 }}
              name="rangeExcludeIpAddresses"
              required={config.rangeExcludeIpAddresses.isRequired}
              error={validateErrors?.rangeExcludeIpAddresses?.text}
              helperText={validateErrors?.rangeExcludeIpAddresses?.text}
              value={rtspServerState.rangeExcludeIpAddresses?.join(',')}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) =>
                onChange(target.value.split(','), 'rangeExcludeIpAddresses')
              }
            />
          </Grid>
        </Grid>

        <RtspServerFormActionButtons
          rtspServer={rtspServer}
          permission={permission}
          formDisabled={formDisabled}
          handlerSaveNewRtspServer={() => handlerSaveNewRtspServer()}
          handlerUpdateRtspServer={() => handlerUpdateRtspServer()}
          handlerRemoveRtspServer={() => setOpenApprove(true)}
          handlerChancel={() => handlerChancel()}
          loading={
            saveNewRtspServerApi?.loading ||
            upadteRtspServerApi?.loading ||
            deleteRtspServerApi?.loading
          }
        />

        <ActionApproveV2
          target={rtspServer}
          open={openApprove}
          title={`Вы действительно хотите удалить данный RTSP сервер?`}
          handleClose={() => setOpenApprove(false)}
          handleSuccess={() => {
            handlerDeleteRtspServer();
            setOpenApprove(false);
          }}
          showDescription={false}
        />
      </FormValidator>
    </div>
  );
};
