export const pagesIds = {
  company: 'Eltis-admin:Company',
  devices: 'Eltis-admin:Devices',
  users: 'Eltis-admin:Users',
  roles: 'Eltis-admin:Roles',
  rtsp: 'Eltis-admin:Rtsp',
  faq: 'Eltis-admin:Faq',
  instructions: 'Eltis-admin:Instructions',
  tariff: 'Eltis-admin:Tariff',
  defaultCameraSettings: 'Eltis-admin:DefaultCameraSettings',
  keyWriter: 'Eltis-admin:KeyWriter',
};
