import { Button, Pagination, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { Link, useNavigate } from 'react-router-dom';
import { URLS } from '../utils/urls.util';
import { KeyWriterTable } from '../components/KeyWriterTable/KeyWriterTable';
import { useApi } from '../hooks/useApi';
import { getAllKeyWriter } from '../api/KeyWriter';
import { UploadFileModal } from '../components/UploadFileModal/UploadFileModal';
import { pagesIds } from '../utils/pages';

export const KeyWriterPage = () => {
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [rows, setRows] = useState([]);
  const getAllKeyWriterApi = useApi(getAllKeyWriter);
  const [sortTableVersion, setSortTableVersion] = useState(false);
  const [lastFilter, setLastFilter] = useState(null);

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Typography key="2" color="text.primary">
      Утилита для работы с ключами
    </Typography>,
  ];
  const getKeyWriter = async (
    page,
    sortFieldArray = [
      {
        field: 'Date',
        descending: true,
      },
    ],
  ) => {
    const result = await getAllKeyWriterApi.sendRequest(
      sortFieldArray,
      page,
      count,
    );
    setRows(result.items);
    setTotalCount(result.totalCount);
    setLastFilter(sortFieldArray);
  };

  useEffect(() => {
    getKeyWriter(page);
  }, []);

  const handleChange = async (event, value) => {
    setPage(value - 1);
    await getKeyWriter(
      value - 1,
      lastFilter || [
        {
          field: 'Date',
          descending: true,
        },
      ],
    );
  };

  const sortableVersionAsc = () => {
    const filter = [
      {
        field: 'Version',
        descending: true,
      },
    ];
    getKeyWriter(page, filter);
  };
  const sortableVersionDesc = () => {
    const filter = [
      {
        field: 'Version',
        descending: false,
      },
    ];
    getKeyWriter(page, filter);
  };

  const sortableDateAsc = () => {
    const filter = [
      {
        field: 'Date',
        descending: true,
      },
    ];
    getKeyWriter(page, filter);
  };
  const sortableDateDesc = () => {
    const filter = [
      {
        field: 'Date',
        descending: false,
      },
    ];
    getKeyWriter(page, filter);
  };

  return (
    <MainContainer
      title="Утилита для работы с ключами"
      breadcrumbs={breadcrumbs}
      pageId={pagesIds.keyWriter}
      setPermission={setPermission}
    >
      {permission?.create && (
        <Button
          color="success"
          variant="outlined"
          sx={{ mb: 3 }}
          onClick={() => {
            setOpenModal(true);
          }}
          disabled={!permission?.create}
        >
          Добавить версию
        </Button>
      )}
      <KeyWriterTable
        rows={rows}
        updateRows={(data) => setRows(data)}
        sortableVersionAsc={() => sortableVersionAsc()}
        sortableVersionDesc={() => sortableVersionDesc()}
        sortableDateAsc={() => sortableDateAsc()}
        sortableDateDesc={() => sortableDateDesc()}
        sortTableVersion={sortTableVersion}
        setSortTableVersion={(data) => setSortTableVersion(data)}
        permission={permission}
      />
      {openModal && (
        <UploadFileModal
          versionList={rows.map((r) => r.version)}
          handleClose={() => {
            setOpenModal(false);
            getKeyWriter();
          }}
          open={openModal}
        />
      )}

      <Pagination
        count={Math.ceil(totalCount / count)}
        page={+page + 1}
        onChange={handleChange}
        sx={{ mt: 3, mb: 4 }}
      />
    </MainContainer>
  );
};
