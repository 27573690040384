import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { FormValidator } from '../FormValidator/FormValidator';
import { DeviceParameterDefaultDataValidationConfig } from '../../utils/formValidatorConfigs/config';
import AddIcon from '@mui/icons-material/Add';
export const DeviceParameterDefaultData = ({
  fieldType,
  restrict,
  changeHandler,
  parameterIndex,
  disabledField,
  setDisabled,
  setIsError,
}) => {
  const [errorValidate, setErrorValidate] = useState({});
  const [showAddArrayButton, setShowAddArrayButton] = useState(true);
  const [validateErrors, setValidateErrors] = useState([]);
  const [fromError, setFromError] = useState(false);
  const [toError, setToError] = useState(false);
  const handlerError = (data) => {
    setValidateErrors(data);
  };

  useEffect(() => {
    setErrorValidate({});
    setValidateErrors([]);
    setFromError(false);
    setToError(false);
    setDisabled(false);
  }, [fieldType]);
  useEffect(() => {
    let isError = false;
    Object.keys(errorValidate).forEach((key) => {
      if (errorValidate[key].error) {
        isError = true;
      }
    });
    setIsError(isError);
  }, [errorValidate]);

  useEffect(() => {
    setDisabled(
      errorValidate?.defaultValue?.error ||
        errorValidate?.defaultValue?.fromError ||
        errorValidate?.defaultValue?.toError ||
        errorValidate?.FromMinValue?.text ||
        errorValidate?.ToMinValue?.text ||
        false,
    );
  }, [restrict]);
  useEffect(() => {
    switch (fieldType) {
      case 'Number':
        setErrorValidate({});
        setValidateErrors([]);
        setFromError(false);
        setToError(false);
        validateNumberDefaultData(restrict, restrict.defaultValue);
        break;
      case 'String':
        setErrorValidate({});
        setValidateErrors([]);
        setFromError(false);
        setToError(false);
        validateStringDefaultData(restrict, restrict.defaultValue);
        break;
      default:
        break;
    }
  }, [restrict]);

  const validateNumberDefaultData = (restrict, data) => {
    if (
      (restrict?.restricts?.minValue || restrict?.restricts?.minValue === 0) &&
      (restrict?.restricts?.maxValue || restrict?.restricts?.maxValue === 0) &&
      restrict?.restricts?.minValue > restrict?.restricts?.maxValue
    ) {
      setErrorValidate({
        ...errorValidate,
        minValueNumber: {
          error: true,
          text: 'Минимальное значение не может быть больше максимального',
        },
      });
    } else {
      setErrorValidate({
        ...errorValidate,
        minValueNumber: { error: false, text: '' },
      });
    }
    if (data || data === 0) {
      if (
        data !== '' &&
        ((!restrict?.restricts?.minValue &&
          restrict?.restricts?.minValue !== 0) ||
          +data >= +restrict?.restricts?.minValue) &&
        ((!restrict?.restricts?.maxValue &&
          restrict?.restricts?.maxValue !== 0) ||
          +data <= +restrict?.restricts?.maxValue)
      ) {
        setErrorValidate({
          ...errorValidate,
          defaultValue: { error: false, text: '' },
        });
      } else {
        setErrorValidate({
          ...errorValidate,
          defaultValue: {
            error: true,
            text: 'Значение не соответствует ограничениям',
          },
        });
      }
    } else {
      setErrorValidate({
        ...errorValidate,
        defaultValue: { error: false, text: '' },
      });
    }
  };

  const validateStringDefaultData = (restrict, data) => {
    if (
      (restrict?.restricts?.minLength ||
        restrict?.restricts?.minLength === 0) &&
      (restrict?.restricts?.maxLength ||
        restrict?.restricts?.maxLength === 0) &&
      restrict?.restricts?.minLength > restrict?.restricts?.maxLength
    ) {
      setErrorValidate({
        ...errorValidate,
        minLengthString: {
          error: true,
          text: 'Минимальное значение не может быть больше максимального',
        },
      });
    } else {
      setErrorValidate({
        ...errorValidate,
        minLengthString: { error: false, text: '' },
      });
    }
    if (data) {
      if (
        ((!restrict?.restricts?.minLength &&
          restrict?.restricts?.minLength !== 0) ||
          data.length >= +restrict?.restricts?.minLength) &&
        ((!restrict?.restricts?.maxLength &&
          restrict?.restricts?.maxLength !== 0) ||
          data.length <= +restrict?.restricts?.maxLength)
      ) {
        setErrorValidate({
          ...errorValidate,
          defaultValue: { error: false, text: '' },
        });
      } else {
        setErrorValidate({
          ...errorValidate,
          defaultValue: {
            error: true,
            text: 'Значение не соответствует ограничениям',
          },
        });
      }
    } else {
      setErrorValidate({
        ...errorValidate,
        minLengthString: { error: false, text: '' },
      });
    }
  };

  const validateNumberRangeFromDefaultData = (restrict, data) => {
    let result = {};
    if (
      restrict?.restricts?.FromMinValue != null &&
      restrict?.restricts?.FromMinValue != undefined &&
      restrict?.restricts?.FromMinValue != '' &&
      restrict?.restricts?.FromMaxValue != null &&
      restrict?.restricts?.FromMaxValue != undefined &&
      restrict?.restricts?.FromMaxValue != '' &&
      +restrict?.restricts?.FromMinValue > +restrict?.restricts?.FromMaxValue
    ) {
      result = {
        ...errorValidate,
        FromMinValue: {
          text: 'Минимальное значение должно быть меньше или равно максимальному значению',
        },
      };
    } else {
      result = {
        ...errorValidate,
        FromMinValue: {
          text: null,
        },
      };
    }
    if (
      data !== '' &&
      data !== undefined &&
      data !== null &&
      !!restrict?.restricts?.FromMinValue &&
      !!restrict?.restricts?.FromMaxValue &&
      !(
        +data >= +restrict?.restricts?.FromMinValue &&
        +data <= +restrict?.restricts?.FromMaxValue
      )
    ) {
      setErrorValidate({
        ...errorValidate,
        ...result,
        defaultValue: { fromError: true },
      });

      setFromError(true);
    } else {
      setErrorValidate({
        ...errorValidate,
        ...result,
        defaultValue: { fromError: false },
      });
      setFromError(false);
    }
    changeHandler({
      ...restrict,
      defaultValue: {
        ...restrict.defaultValue,
        from: data,
      },
    });
  };

  const validateNumberRangeToDefaultData = (restrict, data) => {
    let result = {};
    if (
      restrict?.restricts?.ToMinValue != null &&
      restrict?.restricts?.ToMinValue != undefined &&
      restrict?.restricts?.ToMinValue != '' &&
      restrict?.restricts?.ToMaxValue != null &&
      restrict?.restricts?.ToMaxValue != undefined &&
      restrict?.restricts?.ToMaxValue != '' &&
      +restrict?.restricts?.ToMinValue > +restrict?.restricts?.ToMaxValue
    ) {
      result = {
        ...errorValidate,
        ToMinValue: {
          text: 'Минимальное значение должно быть меньше или равно максимальному значению',
        },
      };
    } else {
      result = {
        ...errorValidate,
        ToMinValue: {
          text: null,
        },
      };
    }
    if (
      data !== '' &&
      data !== undefined &&
      data !== null &&
      !!restrict?.restricts?.ToMinValue &&
      !!restrict?.restricts?.ToMaxValue &&
      !(
        +data >= +restrict?.restricts?.ToMinValue &&
        +data <= +restrict?.restricts?.ToMaxValue
      )
    ) {
      setErrorValidate({
        ...errorValidate,
        ...result,
        defaultValue: { toError: true },
      });
      setToError(true);
    } else {
      setErrorValidate({
        ...errorValidate,
        ...result,
        defaultValue: { toError: false },
      });
      setToError(false);
    }
    changeHandler({
      ...restrict,
      defaultValue: {
        ...restrict.defaultValue,
        to: data,
      },
    });
  };

  const validateArrayDefaultData = (restrict, data) => {
    if (+data < +(restrict?.defaultValue?.length || 0) + 1) {
      setShowAddArrayButton(false);
    } else {
      setShowAddArrayButton(true);
    }
  };
  const numberValue = (value) => {
    const result =
      value === false || value === null || value === undefined || value === ''
        ? null
        : value.length > 9
        ? +value.substring(0, 9)
        : +value;
    return result;
  };

  const config = useMemo(
    () =>
      DeviceParameterDefaultDataValidationConfig({
        minValueNumber: restrict?.restrict?.minValue,
        maxLengthNumber: restrict?.restrict?.maxValue,
        minLengthString: restrict?.restrict?.minLength,
        maxLengthString: restrict?.restrict?.maxValue,
        FromMinValue: restrict?.restrict?.FromMinValue,
        FromMaxValue: restrict?.restrict?.FromMaxValue,
        ToMinValue: restrict?.restrict?.ToMinValue,
        ToMaxValue: restrict?.restrict?.ToMaxValue,
      }),
    [
      restrict?.restricts?.FromMaxValue,
      restrict?.restricts?.FromMinValue,
      restrict?.restricts?.ToMaxValue,
      restrict?.restricts?.ToMinValue,
      restrict?.restricts?.maxValue,
      restrict?.restricts?.minLength,
      restrict?.restricts?.minValue,
    ],
  );

  let component;
  switch (fieldType) {
    case 'Boolean':
      component = (
        <FormControl
          fullWidth
          sx={{ mt: 2 }}
          error={validateErrors?.defaultValueBoolean?.text}
          disabled={disabledField}
        >
          <InputLabel id="demo-simple-select-label" disabled={disabledField}>
            Значение по умолчанию
          </InputLabel>
          <Select
            disabled={disabledField}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={restrict?.defaultValue || false}
            label="Значение по умолчанию"
            name={validateErrors?.defaultValueBoolean?.text}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) =>
              changeHandler({
                ...restrict,
                defaultValue: target.value == 'true' ? true : false,
              })
            }
          >
            <MenuItem value="true">Истина</MenuItem>
            <MenuItem value="false">Ложь</MenuItem>
          </Select>
          {validateErrors?.defaultValueBoolean?.text && (
            <FormHelperText>
              {validateErrors?.defaultValueBoolean?.text}
            </FormHelperText>
          )}
        </FormControl>
      );
      break;
    case 'Number':
      component = (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <Stack direction="row" spacing={2}>
            <TextField
              disabled={disabledField}
              type="number"
              fullWidth
              onWheel={(e) => e.target.blur()}
              value={numberValue(restrict?.restricts?.minValue) || null}
              label="Минимальное значение"
              name="minValueNumber"
              required={config.minValueNumber.isRequired}
              error={
                validateErrors?.minValueNumber?.text ||
                errorValidate?.minValueNumber?.error
              }
              helperText={
                validateErrors?.minValueNumber?.text ||
                errorValidate?.minValueNumber?.text
              }
              InputLabelProps={{
                shrink: true,
              }}
              maxValue={numberValue(restrict?.restricts?.maxValue)}
              onChange={({ target }) =>
                changeHandler({
                  ...restrict,
                  restricts: {
                    ...restrict.restricts,
                    minValue: numberValue(target.value),
                  },
                })
              }
            />
            <TextField
              disabled={disabledField}
              type="number"
              fullWidth
              onWheel={(e) => e.target.blur()}
              value={numberValue(restrict?.restricts?.maxValue) || null}
              label="Максимальное значение"
              InputLabelProps={{
                shrink: true,
              }}
              minValue={numberValue(restrict?.restricts?.minValue)}
              name="maxLengthNumber"
              required={config.maxLengthNumber.isRequired}
              error={validateErrors?.maxLengthNumber?.text}
              helperText={validateErrors?.maxLengthNumber?.text}
              onChange={({ target }) =>
                changeHandler({
                  ...restrict,
                  restricts: {
                    ...restrict?.restricts,
                    maxValue: numberValue(target.value),
                  },
                })
              }
            />
            <TextField
              disabled={disabledField}
              type="number"
              fullWidth
              onWheel={(e) => e.target.blur()}
              value={numberValue(restrict?.defaultValue) || null}
              label="Значение по умолчанию"
              name="defaultValueNumber"
              required={config.defaultValueNumber.isRequired}
              error={errorValidate?.defaultValue?.error}
              helperText={errorValidate?.defaultValue?.text}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ mt: 2 }}
              onChange={({ target }) => {
                changeHandler({
                  ...restrict,
                  defaultValue: numberValue(target.value),
                });
              }}
            />
          </Stack>
        </FormControl>
      );
      break;
    case 'String':
      component = (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <Stack direction="row" spacing={2}>
            <TextField
              disabled={disabledField}
              type="number"
              fullWidth
              onWheel={(e) => e.target.blur()}
              value={numberValue(restrict?.restricts?.minLength)}
              label="Минимальная длина"
              name="minLengthString"
              required={config.minLengthString.isRequired}
              error={
                validateErrors?.minLengthString?.text ||
                errorValidate?.minLengthString?.error
              }
              helperText={
                validateErrors?.minLengthString?.text ||
                errorValidate?.minLengthString?.text
              }
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => {
                changeHandler({
                  ...restrict,
                  restricts: {
                    ...restrict?.restricts,
                    minLength: numberValue(target.value),
                  },
                });
              }}
            />
            <TextField
              disabled={disabledField}
              type="number"
              fullWidth
              onWheel={(e) => e.target.blur()}
              value={numberValue(restrict?.restricts?.maxLength)}
              label="Максимальная длина"
              name="maxLengthString"
              required={config.maxLengthString.isRequired}
              error={validateErrors?.maxLengthString?.text}
              helperText={validateErrors?.maxLengthString?.text}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) =>
                changeHandler({
                  ...restrict,
                  restricts: {
                    ...restrict?.restricts,
                    maxLength: numberValue(target.value),
                  },
                })
              }
            />
            <TextField
              disabled={disabledField}
              type="text"
              fullWidth
              value={restrict?.defaultValue || ''}
              label="Значение по умолчанию"
              name="defaultValueString"
              required={config.defaultValueString.isRequired}
              error={errorValidate?.defaultValue?.error}
              helperText={errorValidate?.defaultValue?.text}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ mt: 2 }}
              onChange={({ target }) => {
                changeHandler({
                  ...restrict,
                  defaultValue: target.value || '',
                });
              }}
            />
          </Stack>
        </FormControl>
      );
      break;
    case 'NumberRange':
      component = (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <Stack direction="row" spacing={2}>
            <TextField
              disabled={disabledField}
              type="number"
              onWheel={(e) => e.target.blur()}
              value={numberValue(restrict?.restricts?.FromMinValue)}
              label="От (Минимальное значение)"
              name="FromMinValue"
              fullWidth
              required={config.FromMinValue.isRequired}
              error={
                validateErrors?.FromMinValue?.text ||
                errorValidate?.FromMinValue?.text
              }
              helperText={
                validateErrors?.FromMinValue?.text ||
                errorValidate?.FromMinValue?.text
              }
              sx={{ width: '50%' }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => {
                const r = {
                  ...restrict,
                  restricts: {
                    ...restrict?.restricts,
                    FromMinValue: numberValue(target.value),
                  },
                };

                validateNumberRangeFromDefaultData(
                  r,
                  numberValue(r?.defaultValue?.from),
                );
                changeHandler(r);
              }}
            />
            <TextField
              disabled={disabledField}
              type="number"
              onWheel={(e) => e.target.blur()}
              value={numberValue(restrict?.restricts?.FromMaxValue)}
              name="FromMaxValue"
              fullWidth
              required={config.FromMaxValue.isRequired}
              error={validateErrors?.FromMaxValue?.text}
              helperText={validateErrors?.FromMaxValue?.text}
              sx={{ width: '50%' }}
              label="От (Максимальное значение)"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => {
                const r = {
                  ...restrict,
                  restricts: {
                    ...restrict?.restricts,
                    FromMaxValue: numberValue(target.value),
                  },
                };

                validateNumberRangeFromDefaultData(
                  r,
                  numberValue(r?.defaultValue?.from),
                );
                changeHandler(r);
              }}
            />
            <TextField
              disabled={disabledField}
              type="number"
              onWheel={(e) => e.target.blur()}
              value={numberValue(restrict?.defaultValue?.from)}
              name="fromErrorDefaultValue"
              fullWidth
              required={config.fromErrorDefaultValue.isRequired}
              error={validateErrors?.fromErrorDefaultValue?.text || fromError}
              helperText={
                validateErrors?.fromErrorDefaultValue?.text ||
                (fromError && 'Число не входит в указанный диапазон')
              }
              sx={{ width: '50%' }}
              label="Зн. по умолчанию (От)"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) =>
                validateNumberRangeFromDefaultData(
                  restrict,
                  numberValue(target.value),
                )
              }
            />
          </Stack>
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <TextField
              disabled={disabledField}
              type="number"
              onWheel={(e) => e.target.blur()}
              value={numberValue(restrict?.restricts?.ToMinValue)}
              label="До (Минимальное значение)"
              name="ToMinValue"
              fullWidth
              required={config.ToMinValue.isRequired}
              error={
                validateErrors?.ToMinValue?.text ||
                errorValidate?.ToMinValue?.text
              }
              helperText={
                validateErrors?.ToMinValue?.text ||
                errorValidate?.ToMinValue?.text
              }
              sx={{ width: '50%' }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => {
                const r = {
                  ...restrict,
                  restricts: {
                    ...restrict?.restricts,
                    ToMinValue: numberValue(target.value),
                  },
                };

                validateNumberRangeToDefaultData(
                  r,
                  numberValue(r?.defaultValue?.to),
                );
                changeHandler(r);
              }}
            />
            <TextField
              disabled={disabledField}
              type="number"
              onWheel={(e) => e.target.blur()}
              value={numberValue(restrict?.restricts?.ToMaxValue)}
              label="До (Максимальное значение)"
              name="ToMaxValue"
              fullWidth
              required={config.ToMaxValue.isRequired}
              error={validateErrors?.ToMaxValue?.text}
              helperText={validateErrors?.ToMaxValue?.text}
              sx={{ width: '50%' }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => {
                const r = {
                  ...restrict,
                  restricts: {
                    ...restrict?.restricts,
                    ToMaxValue: numberValue(target.value),
                  },
                };

                validateNumberRangeToDefaultData(
                  r,
                  numberValue(r?.defaultValue?.to),
                );
                changeHandler(r);
              }}
            />
            <TextField
              disabled={disabledField}
              type="number"
              onWheel={(e) => e.target.blur()}
              value={numberValue(restrict?.defaultValue?.to)}
              label="Зн. по умолчанию (До)"
              name="toErrorDefaultValue"
              fullWidth
              required={config.toErrorDefaultValue.isRequired}
              error={
                validateErrors?.toErrorDefaultValue?.text ||
                (toError && 'Число не входит в указанный диапазон')
              }
              helperText={
                validateErrors?.toErrorDefaultValue?.text ||
                (toError && 'Число не входит в указанный диапазон')
              }
              sx={{ width: '50%' }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) =>
                validateNumberRangeToDefaultData(
                  restrict,
                  numberValue(target.value),
                )
              }
            />
          </Stack>
        </FormControl>
      );
      break;
    case 'Array':
      component = (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <TextField
            disabled={disabledField}
            type="text"
            onWheel={(e) => e.target.blur()}
            value={restrict?.restricts?.maxCount}
            name="maxCount"
            required={config.maxCount.isRequired}
            error={validateErrors?.maxCount?.text}
            helperText={validateErrors?.maxCount?.text}
            label="Максимальное количество элементов в списке"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) => {
              target.value = target.value.replace(/[^0-9]/gm, '');
              const lenght =
                +target.value > 0 ? numberValue(+target.value) : '';
              validateArrayDefaultData(restrict, lenght);

              if (lenght !== '') {
                changeHandler({
                  ...restrict,
                  restricts: {
                    ...restrict.restricts,
                    maxCount: lenght,
                  },

                  defaultValue: restrict?.defaultValue?.slice(0, lenght) || [],
                });
              } else {
                changeHandler({
                  ...restrict,
                  restricts: {
                    ...restrict.restricts,
                    maxCount: lenght,
                  },
                });
              }
            }}
          />

          {!!restrict?.defaultValue?.length &&
            restrict?.defaultValue?.map((dad, index) => (
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 2, alignItems: 'center' }}
              >
                <TextField
                  disabled={disabledField}
                  key={index}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={numberValue(dad)}
                  fullWidth
                  label="Значение по умолчанию"
                  required={config.defaultValueString.isRequired}
                  error={errorValidate?.defaultValue?.error}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target }) =>
                    changeHandler({
                      ...restrict,
                      defaultValue: [
                        ...restrict?.defaultValue?.map((d, i) => {
                          if (i == index) {
                            d = numberValue(target.value);
                          }
                          return d;
                        }),
                      ],
                    })
                  }
                />
                <div>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    disabled={disabledField}
                    onClick={() => {
                      if (
                        restrict?.defaultValue.length <=
                        restrict?.restricts?.maxCount
                      ) {
                        setShowAddArrayButton(true);
                      }
                      changeHandler({
                        ...restrict,
                        defaultValue: restrict?.defaultValue?.filter(
                          (d, i) => i !== index,
                        ),
                      });
                    }}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </div>
              </Stack>
            ))}
          <div>
            {(!restrict?.restricts?.maxCount ||
              (restrict?.defaultValue?.length < restrict?.restricts?.maxCount &&
                showAddArrayButton &&
                !disabledField)) && (
              <Button
                color="primary"
                variant="text"
                sx={{ my: 2 }}
                startIcon={<AddIcon />}
                onClick={() => {
                  changeHandler({
                    ...restrict,
                    defaultValue: [...(restrict?.defaultValue || []), 0],
                  });
                  if (
                    [...(restrict?.defaultValue || []), 0].length ==
                    restrict?.restricts?.maxCount
                  ) {
                    setShowAddArrayButton(false);
                  }
                }}
              >
                Добавить значение
              </Button>
            )}
          </div>
        </FormControl>
      );
      break;
    case 'ArrayNumberRange':
      component = (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <TextField
            disabled={disabledField}
            type="number"
            onWheel={(e) => e.target.blur()}
            value={numberValue(restrict?.restricts?.maxCount)}
            label="Максимальное количество элементов в списке"
            name="maxCount"
            required={config.maxCount.isRequired}
            error={validateErrors?.maxCount?.text}
            helperText={validateErrors?.maxCount?.text}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) => {
              const lenght =
                +target.value > 0 ? numberValue(+target.value) : '';
              validateArrayDefaultData(restrict, lenght);

              if (lenght !== '') {
                changeHandler({
                  ...restrict,
                  restricts: {
                    ...(restrict?.restricts || {}),
                    maxCount: lenght,
                  },
                  defaultValue: restrict?.defaultValue?.slice(0, lenght) || [],
                });
              } else {
                changeHandler({
                  ...restrict,
                  restricts: {
                    ...(restrict?.restricts || {}),
                    maxCount: lenght,
                  },
                });
              }
            }}
          />

          {(typeof restrict?.defaultValue == 'object'
            ? restrict?.defaultValue
            : []
          )?.map((dad, index) => (
            <Stack
              key={index}
              direction="row"
              spacing={2}
              sx={{ mt: 2, alignItems: 'center' }}
            >
              <TextField
                disabled={disabledField}
                type="number"
                onWheel={(e) => e.target.blur()}
                value={numberValue(dad?.from)}
                label="Зн. по умолчанию (От)"
                sx={{ width: '50%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target }) =>
                  changeHandler({
                    ...restrict,
                    defaultValue: [
                      ...restrict?.defaultValue?.map((d, i) => {
                        if (i == index) {
                          d = {
                            ...restrict?.defaultValue?.find(
                              (d, i) => i === index,
                            ),
                            from: numberValue(target.value),
                          };
                        }
                        return d;
                      }),
                    ],
                  })
                }
              />
              <TextField
                disabled={disabledField}
                type="number"
                onWheel={(e) => e.target.blur()}
                value={numberValue(dad?.to)}
                label="Зн. по умолчанию (До)"
                sx={{ width: '50%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target }) =>
                  changeHandler({
                    ...restrict,
                    defaultValue: [
                      ...restrict?.defaultValue?.map((d, i) => {
                        if (i == index) {
                          d = {
                            ...restrict?.defaultValue?.find(
                              (d, i) => i === index,
                            ),
                            to: numberValue(target.value),
                          };
                        }
                        return d;
                      }),
                    ],
                  })
                }
              />
              <div>
                <IconButton
                  aria-label="delete"
                  size="small"
                  disabled={disabledField}
                  onClick={() => {
                    if (
                      restrict?.defaultValue.length <=
                      restrict?.restricts?.maxCount
                    ) {
                      setShowAddArrayButton(true);
                    }
                    changeHandler({
                      ...restrict,
                      defaultValue: restrict?.defaultValue?.filter(
                        (d, i) => i !== index,
                      ),
                    });
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </div>
            </Stack>
          ))}

          <div>
            {(!restrict?.restricts?.maxCount ||
              (restrict?.defaultValue?.length < restrict?.restricts?.maxCount &&
                showAddArrayButton &&
                !disabledField)) &&
              !disabledField && (
                <Button
                  color="primary"
                  variant="text"
                  sx={{ my: 2 }}
                  startIcon={<AddIcon />}
                  onClick={() => {
                    changeHandler({
                      ...restrict,
                      defaultValue: [
                        ...(restrict?.defaultValue || []),
                        { from: 0, to: 0 },
                      ],
                    });
                    if (
                      [
                        ...(restrict?.defaultValue || []),
                        { from: null, to: null },
                      ].length == restrict?.restricts?.maxCount
                    ) {
                      setShowAddArrayButton(false);
                    }
                  }}
                >
                  Добавить значение
                </Button>
              )}
          </div>
        </FormControl>
      );
      break;

    default:
      break;
  }

  return (
    <FormValidator
      name={`parameter-default-data-form-name-${parameterIndex}`}
      validationConfig={config}
      changed={restrict}
      setErrorsHandler={(data) => {
        setDisabled(
          errorValidate?.defaultValue?.error ||
            errorValidate?.defaultValue?.fromError ||
            errorValidate?.defaultValue?.toError ||
            false,
        );
        handlerError(data);
      }}
    >
      {component}
    </FormValidator>
  );
};
