import { Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';
import { useApi } from '../../hooks/useApi';
import { removeGroup } from '../../api/UserApi';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../utils/urls.util';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/alert.store';
import { FooterButtonBar } from '../Default/FooterButtonBar/FooterButtonBar';

export const RoleFooterButtons = ({
  role,
  canBeRemove,
  showSuccessButton = false,
  successButtonText = 'создать',
  handleSuccessAction,
  isReadOnly,
  successDisabled,
  permission,
  showCloseButton = true,
  loading,
}) => {
  const [modal, setModal] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const removeGroupApi = useApi(removeGroup);
  const removeRole = async () => {
    await removeGroupApi.sendRequest(role.id);
    dispatch(
      setAlert({
        status: 201,
        text: `Роль «${
          role?.attributes?.RoleName[0] || role?.name
        }» успешно удалена`,
      }),
    );
    navigate(URLS.ROLES);
  };
  return (
    <>
      <FooterButtonBar
        createButtonText={successButtonText}
        createButtonHandler={handleSuccessAction}
        deleteButtonText="Удалить роль"
        deleteButtonHandler={() => {
          if (canBeRemove) {
            setModal({
              title: 'Вы действительно хотите удалить данную роль?',
              target: role,
              open: true,
              handleClose: () => setModal({ ...modal, open: false }),
              actionButtonText: 'Удалить',
              handleSuccess: () => {
                removeRole();
                setModal({ ...modal, open: false });
              },
              showDescription: false,
            });
          } else {
            setModal({
              title:
                'Вы не можете удалить роль, пока она назначена хотя бы одному пользователю',

              open: true,
              handleClose: () => setModal({ ...modal, open: false }),
              actionButtonText: 'Понятно',
              showCloseButton: false,
              handleSuccess: () => {
                setModal({ ...modal, open: false });
              },
              showDescription: false,
            });
          }
        }}
        cancelButtonText="Отмена"
        cancelButtonHandler={() => navigate(URLS.ROLES)}
        showCreateButton={showSuccessButton && permission?.create}
        showUpdateButton={false}
        showDeleteButton={role?.id && !isReadOnly && permission?.delete}
        showCancelButton={showCloseButton}
        disabledUpdateButton={true}
        disabledCreateButton={
          !!!role?.attributes?.RoleName[0] ||
          successDisabled ||
          !permission?.create
        }
        disabledDeleteButton={!permission?.delete}
        loading={removeGroupApi?.loading || loading}
      />

      <ActionApproveV2
        target={modal.target}
        open={modal.open}
        title={modal.title}
        handleClose={modal.handleClose}
        actionButtonText={modal.actionButtonText}
        handleSuccess={modal.handleSuccess}
        showDescription={modal.showDescription}
        showCloseButton={modal?.showCloseButton}
      />
    </>
  );
};
