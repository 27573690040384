import { Button, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createFaqCategory, updateFaqCategory } from '../../api/FaqApi';
import { useApi } from '../../hooks/useApi';
import { setAlert } from '../../store/alert.store';
import { URLS } from '../../utils/urls.util';
import { FormValidator } from '../FormValidator/FormValidator';
import { FaqCategoryValidationConfig } from '../../utils/formValidatorConfigs/config';

export const FaqCategoryForm = ({ category, handlerRemove, permission }) => {
  const [categoryState, setFaqCategoryState] = useState({});
  const [validateErrors, setValidateErrors] = useState([]);

  const updateApi = useApi(updateFaqCategory);
  const createApi = useApi(createFaqCategory);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    setFaqCategoryState(category);
  }, [category]);

  const updateHandler = async () => {
    await updateApi.sendRequest(categoryState);
    dispatch(setAlert({ status: 201, text: 'Раздел обновлен' }));

    navigate(URLS.FAQ_CATEGORIES);
  };

  const createHandler = async () => {
    await createApi.sendRequest(categoryState);
    dispatch(setAlert({ status: 201, text: 'Раздел добавлен' }));

    navigate(URLS.FAQ_CATEGORIES);
  };

  const handlerError = (data) => {
    setValidateErrors(data);
  };
  const config = useMemo(() => FaqCategoryValidationConfig(), []);

  return (
    <>
      <FormValidator
        name="faq-category-form-name"
        validationConfig={config}
        changed={categoryState}
        setErrorsHandler={(data) => handlerError(data)}
      >
        <TextField
          fullWidth
          label="Название раздела вопроса"
          variant="outlined"
          name="title"
          error={validateErrors?.title?.text}
          helperText={validateErrors?.title?.text}
          required={config?.title?.isRequired}
          sx={{ mt: 2 }}
          value={categoryState?.title}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={({ target }) =>
            setFaqCategoryState({ ...categoryState, title: target.value })
          }
          disabled={!permission?.edit}
        />

        {category?.id ? (
          <>
            {permission?.edit && (
              <Button
                color="success"
                variant="outlined"
                disabled={
                  Object.keys(validateErrors).length ||
                  !categoryState?.title ||
                  !permission?.edit
                }
                sx={{ mt: 3 }}
                onClick={updateHandler}
              >
                Обновить
              </Button>
            )}
            {permission?.delete && (
              <Button
                color="error"
                variant="outlined"
                sx={{ mt: 3 }}
                onClick={() => handlerRemove()}
                disabled={!permission?.delete}
              >
                Удалить
              </Button>
            )}
          </>
        ) : (
          permission?.create && (
            <Button
              color="success"
              variant="outlined"
              sx={{ mt: 3 }}
              onClick={createHandler}
              disabled={!permission?.create}
            >
              Сохранить
            </Button>
          )
        )}
      </FormValidator>
    </>
  );
};
