import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import styles from './Header.module.scss';
import { Logo } from '../Logo/Logo';
import { metaConverter } from '../../../utils/metaConverter';
import { URLS } from '../../../utils/urls.util';
import { Link } from 'react-router-dom';
import AuthService from '../../../api/KeycloakApi';

export const Header = ({ me }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [name, setName] = useState('');

  useEffect(() => {
    const firstName = metaConverter(me.meta, 'firstName');
    const lastName = metaConverter(me.meta, 'lastName');
    const fullName = firstName + ' ' + lastName;
    setName(fullName.trim());
  }, []);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const logout = () => {
    AuthService.doLogout();
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      variant="outlined"
      className={styles.Header}
    >
      <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
        <Logo />

        <Box sx={{ flexGrow: 0 }}>
          <IconButton
            onClick={handleOpenUserMenu}
            sx={{ p: 0 }}
            color="primary"
          >
            <Avatar alt={name || me.email} src="/static/images/avatar/2.jpg" />
          </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem>
              <a
                href={URLS.USERS + '/' + me.id}
                style={{ textDecoration: 'none', color: '#000' }}
              >
                <Typography textAlign="center">Мой профиль</Typography>
              </a>
            </MenuItem>
            <MenuItem onClick={() => logout()}>
              <Typography textAlign="center">Выход</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
