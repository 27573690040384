import React from 'react';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';
import { URLS } from '../utils/urls.util';

export const MainPage = () => {
  return (
    <div>
      <Navigate to={URLS.DASHBOARD} replace={true} />
      MainPage
    </div>
  );
};
