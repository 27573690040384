import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

import { AppBar, Container, Grid, Stack, Toolbar } from '@mui/material';

export const FooterButtonBar = ({
  createButtonText = 'Создать',
  createButtonHandler = () => console.log(''),
  updateButtonText = 'Обновить',
  updateButtonHandler = () => console.log(''),
  deleteButtonText = 'Удалить',
  deleteButtonHandler = () => console.log(''),
  cancelButtonText = 'Отмена',
  cancelButtonHandler = () => console.log(''),

  showCreateButton = true,
  showUpdateButton = false,
  showDeleteButton = false,
  showCancelButton = false,
  disabledUpdateButton = false,
  disabledCreateButton = false,
  disabledDeleteButton = false,

  loading = true,
}) => {
  return (
    <AppBar
      position="fixed"
      color="inherit"
      sx={{ top: 'auto', bottom: 0, pt: 0.5, boxShadow: 'none' }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Toolbar
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                pr: '0 !important',
                pl: '0 !important',
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                sx={{ width: '100%' }}
              >
                {showDeleteButton ? (
                  <LoadingButton
                    variant="outlined"
                    color="error"
                    disabled={disabledDeleteButton}
                    onClick={() => deleteButtonHandler()}
                    loading={loading}
                  >
                    {deleteButtonText}
                  </LoadingButton>
                ) : (
                  <div></div>
                )}
                <Stack direction="row" spacing={2}>
                  {showCancelButton && (
                    <LoadingButton
                      variant="outlined"
                      color="inherit"
                      onClick={() => {
                        cancelButtonHandler();
                      }}
                    >
                      {cancelButtonText}
                    </LoadingButton>
                  )}

                  {showUpdateButton && (
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        updateButtonHandler();
                      }}
                      loading={loading}
                      disabled={disabledUpdateButton}
                    >
                      {updateButtonText}
                    </LoadingButton>
                  )}
                  {showCreateButton && (
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        createButtonHandler();
                      }}
                      loading={loading}
                      disabled={disabledCreateButton}
                    >
                      {createButtonText}
                    </LoadingButton>
                  )}
                </Stack>
              </Stack>
            </Toolbar>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};
