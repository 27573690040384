import React, { useState } from 'react';
import { BigNavigation } from '../components/BigNavigation/BigNavigation';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';

export const DashboardPage = () => {
  const [me, setMe] = useState({});

  return (
    <MainContainer setMeInfo={setMe}>
      <BigNavigation adminPermissions={me?.permissions?.admin} />
    </MainContainer>
  );
};
