import { Button, Grid } from '@mui/material';
import React from 'react';
import { FooterButtonBar } from '../Default/FooterButtonBar/FooterButtonBar';

export const RtspServerFormActionButtons = ({
  rtspServer,
  formDisabled,
  handlerSaveNewRtspServer,
  handlerUpdateRtspServer,
  handlerRemoveRtspServer,
  handlerChancel,
  showDeleteButton = true,
  permission,
  loading,
}) => {
  return (
    <>
      <FooterButtonBar
        showDeleteButton={
          permission.delete && rtspServer?.id && showDeleteButton
        }
        disabledDeleteButton={!permission.delete}
        deleteButtonHandler={() => handlerRemoveRtspServer()}
        cancelButtonHandler={() => handlerChancel()}
        showCancelButton={true}
        showUpdateButton={permission.edit && rtspServer?.id}
        updateButtonHandler={() => handlerUpdateRtspServer()}
        disabledUpdateButton={!permission.edit || formDisabled}
        disabledCreateButton={!permission.create || formDisabled}
        createButtonHandler={() => handlerSaveNewRtspServer()}
        showCreateButton={permission.create && !rtspServer?.id}
        loading={loading}
      />
    </>
  );
};
