import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as EltisLogoImg } from '../../../assets/Images/Eltis.svg';
import { URLS } from '../../../utils/urls.util';
import styles from './Logo.module.scss';

export const Logo = ({ ...props }) => {
  return (
    <Link to={URLS.DASHBOARD} className={styles.Logo} {...props}>
      <EltisLogoImg />
    </Link>
  );
};
