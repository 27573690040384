import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { useParams, useNavigate } from 'react-router-dom';
import { SingleUser } from '../components/SingleUser/SingleUser';
import { pagesIds } from '../utils/pages';
import { URLS } from '../utils/urls.util';
import { useCookies } from 'react-cookie';
import AuthService from '../api/KeycloakApi';
import { getMe } from '../api/AuthApi';
import { useApi } from '../hooks/useApi';

export const SingleUserPage = () => {
  const { id } = useParams();

  const [permission, setPermission] = useState({});
  const [itsMe, setItsMe] = useState(true);
  const getMeApi = useApi(getMe);

  const navigate = useNavigate();

  useEffect(() => {
    getMeApi.sendRequest().then((result) => {
      if (result.id !== id) {
        setItsMe(false);
        if (permission && Object.keys(permission).length && !permission.view) {
          navigate(URLS.DASHBOARD);
        }
      } else {
        setItsMe(true);
      }
    });
  }, [permission]);

  return (
    <MainContainer
      pageId={pagesIds.users}
      noRedirect={itsMe}
      setPermission={setPermission}
    >
      <SingleUser permission={{ ...permission, view: true }} id={id} />
    </MainContainer>
  );
};
