export const countryIsoCodeAlpha3 = [
  {
    iso: 'afg',
    name: 'Афганистан',
  },
  {
    iso: 'alb',
    name: 'Албания',
  },
  {
    iso: 'dza',
    name: 'Алжир',
  },
  {
    iso: 'and',
    name: 'Андорра',
  },
  {
    iso: 'ago',
    name: 'Ангола',
  },
  {
    iso: 'atg',
    name: 'Антигуа и Барбуда',
  },
  {
    iso: 'arg',
    name: 'Аргентина',
  },
  {
    iso: 'arm',
    name: 'Армения',
  },
  {
    iso: 'aus',
    name: 'Австралия',
  },
  {
    iso: 'aut',
    name: 'Австрия',
  },
  {
    iso: 'aze',
    name: 'Азербайджан',
  },
  {
    iso: 'bhs',
    name: 'Багамские Острова',
  },
  {
    iso: 'bhr',
    name: 'Бахрейн',
  },
  {
    iso: 'bgd',
    name: 'Бангладеш',
  },
  {
    iso: 'brb',
    name: 'Барбадос',
  },
  {
    iso: 'blr',
    name: 'Беларусь',
  },
  {
    iso: 'bel',
    name: 'Бельгия',
  },
  {
    iso: 'blz',
    name: 'Белиз',
  },
  {
    iso: 'ben',
    name: 'Бенин',
  },
  {
    iso: 'btn',
    name: 'Бутан',
  },
  {
    iso: 'bol',
    name: 'Боливия',
  },
  {
    iso: 'bih',
    name: 'Босния и Герцеговина',
  },
  {
    iso: 'bwa',
    name: 'Ботсвана',
  },
  {
    iso: 'bra',
    name: 'Бразилия',
  },
  {
    iso: 'brn',
    name: 'Бруней',
  },
  {
    iso: 'bgr',
    name: 'Болгария',
  },
  {
    iso: 'bfa',
    name: 'Буркина-Фасо',
  },
  {
    iso: 'bdi',
    name: 'Бурунди',
  },
  {
    iso: 'khm',
    name: 'Камбоджа',
  },
  {
    iso: 'cmr',
    name: 'Камерун',
  },
  {
    iso: 'can',
    name: 'Канада',
  },
  {
    iso: 'cpv',
    name: 'Кабо-Верде',
  },
  {
    iso: 'caf',
    name: 'ЦАР',
  },
  {
    iso: 'tcd',
    name: 'Чад',
  },
  {
    iso: 'chl',
    name: 'Чили',
  },
  {
    iso: 'chn',
    name: 'Китай (Китайская Народная Республика)',
  },
  {
    iso: 'col',
    name: 'Колумбия',
  },
  {
    iso: 'com',
    name: 'Коморы',
  },
  {
    iso: 'cog',
    name: 'Республика Конго',
  },
  {
    iso: 'cod',
    name: 'ДР Конго',
  },
  {
    iso: 'cri',
    name: 'Коста-Рика',
  },
  {
    iso: 'civ',
    name: 'Кот-д’Ивуар',
  },
  {
    iso: 'hrv',
    name: 'Хорватия',
  },
  {
    iso: 'cub',
    name: 'Куба',
  },
  {
    iso: 'cyp',
    name: 'Кипр',
  },
  {
    iso: 'cze',
    name: 'Чехия',
  },
  {
    iso: 'dnk',
    name: 'Дания',
  },
  {
    iso: 'dji',
    name: 'Джибути',
  },
  {
    iso: 'dma',
    name: 'Доминика',
  },
  {
    iso: 'dom',
    name: 'Доминиканская Республика',
  },
  {
    iso: 'ecu',
    name: 'Эквадор',
  },
  {
    iso: 'egy',
    name: 'Египет',
  },
  {
    iso: 'slv',
    name: 'Сальвадор',
  },
  {
    iso: 'gnq',
    name: 'Экваториальная Гвинея',
  },
  {
    iso: 'eri',
    name: 'Эритрея',
  },
  {
    iso: 'est',
    name: 'Эстония',
  },
  {
    iso: 'eth',
    name: 'Эфиопия',
  },
  {
    iso: 'fji',
    name: 'Фиджи',
  },
  {
    iso: 'fin',
    name: 'Финляндия',
  },
  {
    iso: 'fra',
    name: 'Франция',
  },
  {
    iso: 'gab',
    name: 'Габон',
  },
  {
    iso: 'gmb',
    name: 'Гамбия',
  },
  {
    iso: 'geo',
    name: 'Грузия',
  },
  {
    iso: 'deu',
    name: 'Германия',
  },
  {
    iso: 'gha',
    name: 'Гана',
  },
  {
    iso: 'grc',
    name: 'Греция',
  },
  {
    iso: 'grd',
    name: 'Гренада',
  },
  {
    iso: 'gtm',
    name: 'Гватемала',
  },
  {
    iso: 'gin',
    name: 'Гвинея',
  },
  {
    iso: 'gnb',
    name: 'Гвинея-Бисау',
  },
  {
    iso: 'guy',
    name: 'Гайана',
  },
  {
    iso: 'hti',
    name: 'Гаити',
  },
  {
    iso: 'hnd',
    name: 'Гондурас',
  },
  {
    iso: 'hun',
    name: 'Венгрия',
  },
  {
    iso: 'isl',
    name: 'Исландия',
  },
  {
    iso: 'ind',
    name: 'Индия',
  },
  {
    iso: 'idn',
    name: 'Индонезия',
  },
  {
    iso: 'irn',
    name: 'Иран',
  },
  {
    iso: 'irq',
    name: 'Ирак',
  },
  {
    iso: 'irl',
    name: 'Ирландия',
  },
  {
    iso: 'isr',
    name: 'Израиль',
  },
  {
    iso: 'ita',
    name: 'Италия',
  },
  {
    iso: 'jam',
    name: 'Ямайка',
  },
  {
    iso: 'jpn',
    name: 'Япония',
  },
  {
    iso: 'jor',
    name: 'Иордания',
  },
  {
    iso: 'kaz',
    name: 'Казахстан',
  },
  {
    iso: 'ken',
    name: 'Кения',
  },
  {
    iso: 'kir',
    name: 'Кирибати',
  },
  {
    iso: 'prk',
    name: 'КНДР (Корейская Народно-Демократическая Республика)',
  },
  {
    iso: 'kor',
    name: 'Республика Корея',
  },
  {
    iso: 'kwt',
    name: 'Кувейт',
  },
  {
    iso: 'kgz',
    name: 'Киргизия',
  },
  {
    iso: 'lao',
    name: 'Лаос',
  },
  {
    iso: 'lva',
    name: 'Латвия',
  },
  {
    iso: 'lbn',
    name: 'Ливан',
  },
  {
    iso: 'lso',
    name: 'Лесото',
  },
  {
    iso: 'lbr',
    name: 'Либерия',
  },
  {
    iso: 'lby',
    name: 'Ливия',
  },
  {
    iso: 'lie',
    name: 'Лихтенштейн',
  },
  {
    iso: 'ltu',
    name: 'Литва',
  },
  {
    iso: 'lux',
    name: 'Люксембург',
  },
  {
    iso: 'mkd',
    name: 'Северная Македония',
  },
  {
    iso: 'mdg',
    name: 'Мадагаскар',
  },
  {
    iso: 'mwi',
    name: 'Малави',
  },
  {
    iso: 'mys',
    name: 'Малайзия',
  },
  {
    iso: 'mdv',
    name: 'Мальдивы',
  },
  {
    iso: 'mli',
    name: 'Мали',
  },
  {
    iso: 'mlt',
    name: 'Мальта',
  },
  {
    iso: 'mhl',
    name: 'Маршалловы Острова',
  },
  {
    iso: 'mrt',
    name: 'Мавритания',
  },
  {
    iso: 'mus',
    name: 'Маврикий',
  },
  {
    iso: 'mex',
    name: 'Мексика',
  },
  {
    iso: 'fsm',
    name: 'Микронезия',
  },
  {
    iso: 'mar',
    name: 'Марокко',
  },
  {
    iso: 'mda',
    name: 'Молдавия',
  },
  {
    iso: 'mco',
    name: 'Монако',
  },
  {
    iso: 'mng',
    name: 'Монголия',
  },
  {
    iso: 'mne',
    name: 'Черногория',
  },
  {
    iso: 'moz',
    name: 'Мозамбик',
  },
  {
    iso: 'mmr',
    name: 'Мьянма',
  },
  {
    iso: 'nam',
    name: 'Намибия',
  },
  {
    iso: 'nru',
    name: 'Науру',
  },
  {
    iso: 'npl',
    name: 'Непал',
  },
  {
    iso: 'nld',
    name: 'Нидерланды',
  },
  {
    iso: 'nzl',
    name: 'Новая Зеландия',
  },
  {
    iso: 'nic',
    name: 'Никарагуа',
  },
  {
    iso: 'ner',
    name: 'Нигер',
  },
  {
    iso: 'nga',
    name: 'Нигерия',
  },
  {
    iso: 'nor',
    name: 'Норвегия',
  },
  {
    iso: 'omn',
    name: 'Оман',
  },
  {
    iso: 'pak',
    name: 'Пакистан',
  },
  {
    iso: 'plw',
    name: 'Палау',
  },
  {
    iso: 'pan',
    name: 'Панама',
  },
  {
    iso: 'png',
    name: 'Папуа — Новая Гвинея',
  },
  {
    iso: 'pry',
    name: 'Парагвай',
  },
  {
    iso: 'per',
    name: 'Перу',
  },
  {
    iso: 'phl',
    name: 'Филиппины',
  },
  {
    iso: 'pol',
    name: 'Польша',
  },
  {
    iso: 'prt',
    name: 'Португалия',
  },
  {
    iso: 'qat',
    name: 'Катар',
  },
  {
    iso: 'rou',
    name: 'Румыния',
  },
  {
    iso: 'rus',
    name: 'Россия',
  },
  {
    iso: 'rwa',
    name: 'Руанда',
  },
  {
    iso: 'kna',
    name: 'Сент-Китс и Невис',
  },
  {
    iso: 'lca',
    name: 'Сент-Люсия',
  },
  {
    iso: 'vct',
    name: 'Сент-Винсент и Гренадины',
  },
  {
    iso: 'wsm',
    name: 'Самоа',
  },
  {
    iso: 'smr',
    name: 'Сан-Марино',
  },
  {
    iso: 'stp',
    name: 'Сан-Томе и Принсипи',
  },
  {
    iso: 'sau',
    name: 'Саудовская Аравия',
  },
  {
    iso: 'sen',
    name: 'Сенегал',
  },
  {
    iso: 'srb',
    name: 'Сербия',
  },
  {
    iso: 'syc',
    name: 'Сейшельские Острова',
  },
  {
    iso: 'sle',
    name: 'Сьерра-Леоне',
  },
  {
    iso: 'sgp',
    name: 'Сингапур',
  },
  {
    iso: 'svk',
    name: 'Словакия',
  },
  {
    iso: 'svn',
    name: 'Словения',
  },
  {
    iso: 'slb',
    name: 'Соломоновы Острова',
  },
  {
    iso: 'som',
    name: 'Сомали',
  },
  {
    iso: 'zaf',
    name: 'ЮАР',
  },
  {
    iso: 'ssd',
    name: 'Южный Судан',
  },
  {
    iso: 'esp',
    name: 'Испания',
  },
  {
    iso: 'lka',
    name: 'Шри-Ланка',
  },
  {
    iso: 'sdn',
    name: 'Судан',
  },
  {
    iso: 'sur',
    name: 'Суринам',
  },
  {
    iso: 'swz',
    name: 'Эсватини',
  },
  {
    iso: 'swe',
    name: 'Швеция',
  },
  {
    iso: 'che',
    name: 'Швейцария',
  },
  {
    iso: 'syr',
    name: 'Сирия',
  },
  {
    iso: 'tjk',
    name: 'Таджикистан',
  },
  {
    iso: 'tza',
    name: 'Танзания',
  },
  {
    iso: 'tha',
    name: 'Таиланд',
  },
  {
    iso: 'tls',
    name: 'Восточный Тимор',
  },
  {
    iso: 'tgo',
    name: 'Того',
  },
  {
    iso: 'ton',
    name: 'Тонга',
  },
  {
    iso: 'tto',
    name: 'Тринидад и Тобаго',
  },
  {
    iso: 'tun',
    name: 'Тунис',
  },
  {
    iso: 'tur',
    name: 'Турция',
  },
  {
    iso: 'tkm',
    name: 'Туркменистан',
  },
  {
    iso: 'tuv',
    name: 'Тувалу',
  },
  {
    iso: 'uga',
    name: 'Уганда',
  },
  {
    iso: 'ukr',
    name: 'Украина',
  },
  {
    iso: 'are',
    name: 'ОАЭ',
  },
  {
    iso: 'gbr',
    name: 'Великобритания',
  },
  {
    iso: 'usa',
    name: 'США',
  },
  {
    iso: 'ury',
    name: 'Уругвай',
  },
  {
    iso: 'uzb',
    name: 'Узбекистан',
  },
  {
    iso: 'vut',
    name: 'Вануату',
  },
  {
    iso: 'ven',
    name: 'Венесуэла',
  },
  {
    iso: 'vnm',
    name: 'Вьетнам',
  },
  {
    iso: 'yem',
    name: 'Йемен',
  },
  {
    iso: 'zmb',
    name: 'Замбия',
  },
  {
    iso: 'zwe',
    name: 'Зимбабве',
  },
];
