import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getOneRtspServer } from '../../api/RtspServerApi';
import { URLS } from '../../utils/urls.util';
import { RtspServerFrom } from '../RtspServerFrom/RtspServerFrom';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/alert.store';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';

export const VideoServersSingleRtspServer = ({ id, permission }) => {
  const [isCreate, setIsCreate] = useState(false);
  const [rtspServer, setRtspServer] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== 'create') {
      getOneRtspServer(id, [])
        .then((rtspServer) => setRtspServer(rtspServer))
        .catch((error) =>
          dispatch(setAlert([{ type: 'error', text: error.message }])),
        );
    }
  }, []);

  useState(() => {
    if (id == 'create') {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [id]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="text.primary"
      to={URLS.VIDEO_SERVERS_RTSP}
    >
      Настройки камеры
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="text.primary"
      to={URLS.VIDEO_SERVERS_RTSP}
    >
      RTSP сервера
    </Link>,
    <Typography key="3" color="text.primary">
      {isCreate ? 'Добавить RTSP сервер' : rtspServer.name}
    </Typography>,
  ];

  return (
    (id || isCreate) && (
      <FullPageLayout
        seoTitle={
          'RTSP сервера - ' + (rtspServer?.name || 'Добавить RTSP сервер')
        }
        pageTitle={isCreate ? 'Добавить RTSP сервер' : rtspServer?.name}
        obj={rtspServer}
        showStatus={false}
        breadcrumbs={breadcrumbs}
        isCreated={true}
      >
        <RtspServerFrom
          prevPage={URLS.VIDEO_SERVERS_RTSP}
          isCreate={isCreate}
          rtspServer={rtspServer}
          permission={permission}
        />
      </FullPageLayout>
    )
  );
};
