import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './UploadFileModal.module.scss';

import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';

export const UploadFileDeviceModal = ({
  open,
  handleClose,
  fileAddedHandler,
  errorText,
  helperText,
  fileTypeValidation,
  maxFileSize = 999999999,
}) => {
  const [file, setFile] = useState();
  const [uploadError, setUploadError] = useState(false);

  useEffect(() => {
    if (file) {
      if (
        (fileTypeValidation && !fileTypeValidation.includes(file.type)) ||
        file.size > maxFileSize
      ) {
        setFile(null);
        document.getElementById('fileId').value = '';
        setUploadError(true);
      } else {
        setUploadError(false);
      }
    }
  }, [file]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box className={styles.UploadFileModal}>
        <Typography id="modal-modal-title" variant="h4" component="h4">
          Добавить файл
        </Typography>

        <div
          className={styles.UploadFileField}
          style={uploadError ? { borderColor: 'red' } : {}}
        >
          <input
            type="file"
            name="file"
            id="fileId"
            onChange={({ target }) => setFile(target.files[0])}
          />
          <div
            style={{
              width: '70%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {!file ? (
              <p>Перетащите файл сюда или воспользуйтесь кнопкой</p>
            ) : (
              <p>Загружен файл - {file?.name}</p>
            )}
            {!file ? (
              <Typography
                color="primary"
                sx={{
                  textTransform: 'uppercase',
                  color: '#1976d2 !important',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <AttachFileOutlinedIcon sx={{ mb: '-5px' }} /> Загрузить
              </Typography>
            ) : (
              <Button
                variant="text"
                color="error"
                onClick={() => {
                  setFile(null);
                  setUploadError(false);
                  document.getElementById('fileId').value = '';
                }}
              >
                Удалить файл
              </Button>
            )}
          </div>
        </div>
        {uploadError && (
          <p
            style={{
              display: 'block',
              textAlign: 'center',
              fontSize: '12px',
              fontWeight: 'bold',
              color: 'red',
            }}
          >
            {errorText}
          </p>
        )}
        <p
          style={{
            display: 'block',
            textAlign: 'center',
            fontSize: '12px',
            color: '#aaa',
          }}
        >
          {helperText}
        </p>

        <Button
          variant="outlined"
          color="primary"
          sx={{ mt: 3, ml: 'auto' }}
          onClick={handleClose}
        >
          Отмена
        </Button>
        <Button
          variant="contained"
          color="success"
          sx={{ ml: 1, mt: 3 }}
          onClick={() => {
            fileAddedHandler(file);

            handleClose();
          }}
          disabled={!file || uploadError}
        >
          Добавить
        </Button>
      </Box>
    </Modal>
  );
};
