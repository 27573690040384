import { Button, Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMe } from '../../../api/AuthApi';
import AuthService from '../../../api/KeycloakApi';
import { useApi } from '../../../hooks/useApi';
import { AlertsList } from '../AlertsList/AlertsList';
import { Header } from '../Header/Header';
import { HttpError } from '../HttpError/HttpError';
import { MetaData } from '../MetaData/MetaData';
import { Title } from '../Title/Title';
import { useNavigate, useParams } from 'react-router-dom';
import { URLS } from '../../../utils/urls.util';

export const MainContainer = ({
  title,
  breadcrumbs,
  actionButton,
  hideHeader,
  children,
  titleAs,
  titleStyles = {},
  setPermission = () => {},
  pageId = '',
  setMeInfo = () => {},
  noRedirect = false,
}) => {
  const getMeApi = useApi(getMe);
  const token = AuthService.getToken();
  const [forbidden, setForbidden] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const alerts = useSelector((state) => state.alerts);
  const [me, setMe] = useState({});
  const params = useParams();

  useEffect(() => {
    if (token) {
      getMeApi
        .sendRequest(['meta'])
        .then((me) => {
          setMe(me);
          setMeInfo(me);
          const permission = me?.permissions?.admin?.find(
            (item) => item.key === pageId,
          );

          if (me?.groups?.some((g) => g.name.includes('Eltis-admin:'))) {
            setForbidden(false);
            if (permission) {
              if (noRedirect) {
                setPermission({ ...permission, view: true });
              } else {
                setPermission(permission);
              }
            }
            if (!permission.view) {
              setRedirect(true);
            } else if (
              !permission?.create &&
              Object.values(params)?.includes('create') &&
              Object.keys(params)?.length == 1
            ) {
              setRedirect(true);
            }
          } else {
            setForbidden(true);
          }
        })
        .catch((error) => {
          if (error?.response?.status == 403) {
            setForbidden(true);
            setLoading(false);
          } else {
            setForbidden(false);
            setLoading(false);
          }
        });
    }
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    if (redirect && !noRedirect) navigate(URLS.DASHBOARD);
  }, [redirect]);
  return (
    <>
      {!forbidden ? (
        !loading ? (
          <>
            <MetaData title={title} />
            <Container maxWidth="lg" sx={{ pb: 10 }}>
              <AlertsList alerts={alerts} />
              {!hideHeader && <Header me={me} />}
              {title && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <Grid item xs={actionButton?.text ? 9 : 12}>
                    <Title
                      breadcrumbs={breadcrumbs}
                      titleAs={titleAs}
                      titleStyles={titleStyles}
                    >
                      {title}
                    </Title>
                  </Grid>
                  {actionButton?.text && (
                    <Grid item xs={3} sx={{ display: 'flex' }}>
                      <Button
                        variant="outlined"
                        color="success"
                        sx={{ ml: 'auto' }}
                        onClick={actionButton.action}
                      >
                        {actionButton.text}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
              {children}
            </Container>
          </>
        ) : (
          <></>
        )
      ) : (
        <HttpError
          statusCode={403}
          showAction={true}
          messageText={`У вас нет доступа к этой странице`}
        />
      )}
    </>
  );
};
