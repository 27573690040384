import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { SingleFaq } from '../components/SingleFaq/SingleFaq';
import { useApi } from '../hooks/useApi';
import { getOneFaq } from '../api/FaqApi';
import { useParams, useNavigate } from 'react-router-dom';
import { pagesIds } from '../utils/pages';
import { URLS } from '../utils/urls.util';

export const SingleFaqPage = () => {
  const [faq, setFaq] = useState({});
  const getOneFaqApi = useApi(getOneFaq);
  const { id } = useParams();

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);


  useEffect(() => {
    if (id != 'create') {
      getOneFaqApi.sendRequest(id, ['category']).then((result) => {
        setFaq(result);
      });
    }
  }, []);
  return (
    <MainContainer pageId={pagesIds.faq} setPermission={setPermission}>
      <SingleFaq permission={permission} id={id} faq={faq} />
    </MainContainer>
  );
};
