import axios from 'axios';
import Cookies from 'js-cookie';
import { ENV } from '../utils/env';
import AuthService from './KeycloakApi';

export const getAllFaqCategories = async (
  relations,
  limit = 16,
  offset = 0,
) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/faq/get-all-categories`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        limit: limit,
        offset: offset,
        relations,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const deleteFaqCategory = async (id) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'delete',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/faq/remove-category`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        id,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createFaqCategory = async (category) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/faq/create-category`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: category,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateFaqCategory = async (category) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'patch',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/faq/update-category`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: category,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getAllFaq = async (
  relations,
  limit = 16,
  offset = 0,
  categoryId = null,
  search = '',
  orderObj = {},
) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/faq/get-all`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        limit: limit,
        offset: offset,
        relations,
        categoryId,
        search,
        order: orderObj,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getOneFaq = async (id, relations) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/faq/get-one/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        relations: relations || [],
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const getOneFaqCategory = async (id) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/faq/get-one-category/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFaq = async (id) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'delete',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/faq/remove`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        id,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createFaq = async (category) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/faq/create`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: category,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateFaq = async (category) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'patch',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/faq/update`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: category,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
