import { Pagination, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { getAllCompanies } from '../api/CompanyApi';
import { CompaniesBigList } from '../components/CompaniesBigList/CompaniesBigList';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { useApi } from '../hooks/useApi';
import { URLS } from '../utils/urls.util';
import { pagesIds } from '../utils/pages';

export const CompaniesPage = () => {
  const companyApi = useApi(getAllCompanies);
  const [companies, setCompanies] = useState([]);
  const [limit, setLimit] = useState(15);
  const [selectState, setSelectState] = useState(false);
  const [companiesCount, setCompaniesCount] = useState(0);
  const [page, setPage] = useState(1);

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  const location = useLocation();
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Typography key="2" color="text.primary">
      Организации
    </Typography>,
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const handleChange = (event, value) => {
    navigate(URLS.COMPANIES + '?page=' + value);
  };

  const fetchCompanies = async (offset = 0) => {
    const [result, count] = await companyApi.sendRequest(limit, offset);
    setCompanies(result);
    setCompaniesCount(Math.ceil(count / limit));
  };

  const handlerSelectButtonAction = () => {
    setSelectState(!selectState);
  };

  useEffect(() => {
    const p = searchParams.get('page');
    setPage(p || 1);
    if (+p > 1) {
      fetchCompanies(limit * (p - 1));
    } else {
      fetchCompanies();
    }
  }, [location]);

  return (
    <MainContainer
      title={'Список организаций'}
      breadcrumbs={breadcrumbs}
      selectButtonAction={handlerSelectButtonAction}
      pageId={pagesIds.company}
      setPermission={setPermission}
    >
      <CompaniesBigList
        permission={permission}
        companies={companies}
        selectState={selectState}
        page={page}
      />

      {companiesCount > 0 && (
        <Pagination
          count={companiesCount}
          page={+page}
          onChange={handleChange}
          sx={{ mt: 3, mb: 4 }}
        />
      )}
    </MainContainer>
  );
};
