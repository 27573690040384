import React, { useState } from 'react';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { VideoServersBase } from '../components/VideoServers/VideoServersBase';
import { pagesIds } from '../utils/pages';

export const VideoServersBasePage = () => {
  const [permission, setPermission] = useState({});
  return (
    <MainContainer
      pageId={pagesIds.defaultCameraSettings}
      setPermission={setPermission}
    >
      <VideoServersBase permission={permission} />
    </MainContainer>
  );
};
