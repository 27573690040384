import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getAllFaqCategories } from '../api/FaqApi';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { FaqCategoriesTable } from '../components/FaqCategoriesTable/FaqCategoriesTable';
import { useApi } from '../hooks/useApi';
import { URLS } from '../utils/urls.util';
import { pagesIds } from '../utils/pages';

export const FaqCategoriesPage = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Typography key="2" color="text.primary">
      Разделы частых вопросов
    </Typography>,
  ];
  const [categories, setCategories] = useState([]);
  const categoriesApi = useApi(getAllFaqCategories);

  const location = useLocation();

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  useEffect(() => {
    categoriesApi
      .sendRequest([], 999, 0)
      .then((result) => setCategories(result[0]));
  }, []);

  return (
    <MainContainer
      pageId={pagesIds.faq}
      setPermission={setPermission}
      title="Разделы частых вопросов"
      breadcrumbs={breadcrumbs}
    >
      {permission?.create && (
        <Button
          color="success"
          variant="outlined"
          sx={{ mb: 3 }}
          disabled={!permission?.create}
          onClick={() => {
            navigate(URLS.FAQ_CATEGORIES + '/create');
          }}
        >
          Добавить раздел
        </Button>
      )}
      <Link
        to={URLS.FAQ}
        style={{ textDecoration: 'none', marginLeft: '10px' }}
      >
        <Button
          color="primary"
          variant="outlined"
          sx={{ mb: 3, textDecoration: 'none' }}
        >
          Список вопросов
        </Button>
      </Link>
      <FaqCategoriesTable
        permission={permission}
        updateCategoryListHandler={(data) => setCategories(data)}
        categories={categories}
      />
    </MainContainer>
  );
};
