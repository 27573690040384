import React, { useEffect, useState } from 'react';
import { SingleCompany } from '../components/SingleCompany/SingleCompany';
import { useParams, useNavigate } from 'react-router-dom';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { pagesIds } from '../utils/pages';
import { URLS } from '../utils/urls.util';

const companiesTabs = ['details', 'rates', 'employees'];
export const SingleCompanyPage = () => {
  const { id, tab } = useParams();

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  return (
    <MainContainer pageId={pagesIds.company} setPermission={setPermission}>
      <SingleCompany permission={permission} id={id} tab={companiesTabs.findIndex((i) => i == tab)} />
    </MainContainer>
  );
};
