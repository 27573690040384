import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { deleteFaq } from '../../api/FaqApi';
import { useApi } from '../../hooks/useApi';
import { setAlert } from '../../store/alert.store';
import { URLS } from '../../utils/urls.util';
import { ActionApprove } from '../Default/ActionApprove/ActionApprove';
import { FaqForm } from '../FaqForm/FaqForm';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';

export const SingleFaq = ({ id, faq, permission }) => {
  const deleteApi = useApi(deleteFaq);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCreate, setIsCreate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const deleteFaqCategoryHandler = async () => {
    try {
      setOpenModal(false);
      await deleteApi.sendRequest(faq?.id);
      navigate(URLS.FAQ);

      dispatch(setAlert({ status: 200, text: 'Вопрос удален' }));
    } catch (error) {}
  };

  const clickToRemove = (item) => {
    setOpenModal(true);
  };

  useState(() => {
    if (id == 'create') {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [id]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={URLS.FAQ}>
      Частые вопросы (FAQ)
    </Link>,
    <Typography key="3" color="text.primary">
      {isCreate ? 'Новый вопрос' : faq.title}
    </Typography>,
  ];
  return (
    <FullPageLayout
      seoTitle={'Частые вопросы - ' + (faq?.title || 'Добавление вопроса')}
      pageTitle={isCreate ? 'Добавить вопрос' : faq?.title}
      obj={faq}
      showComapnyId={false}
      showAwsStatus={false}
      showStatus={false}
      breadcrumbs={breadcrumbs}
      isCreated={isCreate}
    >
      <ActionApproveV2
        target={faq}
        title={`Вы действительно хотите удалить данный вопрос?`}
        open={openModal}
        showDescription={false}
        handleSuccessText="Удалить"
        handleCloseText="Отмена"
        handleSuccess={(data) => {
          deleteFaqCategoryHandler();
        }}
        handleClose={() => setOpenModal(false)}
      />

      <FaqForm
        permission={permission}
        faq={faq}
        handlerDelete={() => clickToRemove()}
      />
    </FullPageLayout>
  );
};
