import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';
import { useApi } from '../../hooks/useApi';
import { getKeyWriterLink, removeKeyWriter } from '../../api/KeyWriter';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/alert.store';

export const KeyWriterTable = ({
  rows,
  updateRows,
  sortableVersionAsc,
  sortableVersionDesc,
  sortableDateAsc,
  sortableDateDesc,
  sortTableVersion,
  setSortTableVersion,
  permission,
}) => {
  const [selectTarget, setSelectTarget] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const [sortTableDate, setSortTableDate] = useState(false);
  const removeApi = useApi(removeKeyWriter);
  const getLink = useApi(getKeyWriterLink);

  const dispatch = useDispatch();
  const removeKeyWriterHandler = async (id) => {
    await removeApi.sendRequest(id);
    dispatch(setAlert({ status: 200, text: 'Версия удалена' }));
    updateRows(rows.filter((r) => r.id !== id));
    setOpenModal(false);
  };

  const dowloadFile = async (id) => {
    const url = await getLink.sendRequest(id);

    if (url) {
      window.open(url);
    }
  };
  return (
    <>
      <ActionApproveV2
        target={selectTarget}
        open={openModal}
        title="Вы действительно хотите удалить эту версию?"
        handleClose={() => setOpenModal(false)}
        handleSuccess={(data) => removeKeyWriterHandler(data.id)}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <TableSortLabel
                  active={true}
                  direction={sortTableVersion ? 'desc' : 'asc'}
                  onClick={() => {
                    if (sortTableVersion) {
                      sortableVersionDesc();
                      setSortTableVersion(false);
                    } else {
                      sortableVersionAsc();
                      setSortTableVersion(true);
                    }
                  }}
                >
                  <b>Версия</b>
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={true}
                  direction={sortTableDate ? 'desc' : 'asc'}
                  onClick={() => {
                    if (sortTableDate) {
                      sortableDateDesc();
                      setSortTableDate(false);
                    } else {
                      sortableDateAsc();
                      setSortTableDate(true);
                    }
                  }}
                >
                  <b>Дата загрузки</b>
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <b>Кто загрузил</b>
              </TableCell>
              <TableCell align="left">
                <b>Действие</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={{ padding: '15px' }}>
                  {row.version}
                </TableCell>
                <TableCell align="left" sx={{ padding: '15px' }}>
                  {format(new Date(row.date), 'dd.MM.yyyy в HH:mm:ss')}
                </TableCell>
                <TableCell align="left" sx={{ padding: '15px' }}>
                  {row.uploadedBy}
                </TableCell>
                <TableCell align="left" sx={{ padding: '15px' }}>
                  <Button
                    // download
                    // href={row.url}
                    variant="text"
                    color="primary"
                    startIcon={<DownloadOutlinedIcon />}
                    onClick={() => dowloadFile(row.id)}
                  >
                    Скачать
                  </Button>
                  {permission?.delete && (
                    <Button
                      variant="text"
                      color="error"
                      sx={{ ml: 2 }}
                      startIcon={<DeleteOutlinedIcon />}
                      onClick={() => {
                        setSelectTarget(row);
                        setOpenModal(true);
                      }}
                      disabled={!permission?.delete}
                    >
                      Удалить
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
