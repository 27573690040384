import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import {
  editInstruction,
  getAllInstructionCategories,
  uploadInstructionFile,
} from '../../api/InstructionApi';
import { useApi } from '../../hooks/useApi';
import { setAlert } from '../../store/alert.store';
import { URLS } from '../../utils/urls.util';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './InstructionForm.module.scss';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { FormValidator } from '../FormValidator/FormValidator';
import { InstuctionValidationConfig } from '../../utils/formValidatorConfigs/config';
import { UploadFileDeviceModal } from '../UploadFileModal/UploadFileDeviceModal';
import { FooterButtonBar } from '../Default/FooterButtonBar/FooterButtonBar';

export const InstructionForm = ({ instruction, handlerDelete, permission }) => {
  const [instructionState, setInstructionState] = useState({});
  const [categories, setCategories] = useState([]);
  const [editorError, setEditorError] = useState({});
  const [fileError, setFileError] = useState({});
  const [file, setFile] = useState();

  const [filename, setFilename] = useState('');
  const [validateErrors, setValidateErrors] = useState([]);
  const [wasChange, setChange] = useState(false);
  const config = useMemo(() => InstuctionValidationConfig(), []);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );

  const editInstructionApi = useApi(editInstruction);
  const uploadFileApi = useApi(uploadInstructionFile);
  const getInstructionCategoriesApi = useApi(getAllInstructionCategories);
  const [openAddFileModal, setOpenAddFileModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    getInstructionCategoriesApi
      .sendRequest([], 999, 0)
      .then((result) => setCategories(result[0]));
  }, []);

  useEffect(() => {
    if (
      file &&
      (![
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
      ].includes(file.type) ||
        file.size > 10000000)
    ) {
      setFileError({
        title:
          'Файл должен иметь расширение doc, docx, pdf и размер не более 10 мб.',
      });
    } else {
      setFileError({});
    }
    if (file?.name) {
      setFilename(file.name);
    }
  }, [file]);
  useEffect(() => {
    setInstructionState(instruction);
    const contentBlock = htmlToDraft(instruction?.description || '');
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const editor = EditorState.createWithContent(contentState);
      setEditorState(editor);
    }
  }, [instruction]);

  useEffect(() => {
    let id = {};
    if (instructionState?.id || instruction.id) {
      id = {
        id: instructionState?.id || instruction.id,
      };
    }
    let description = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    );
    description = description.replace(/s?style=("(.+?);")|("(.+?);")/i, '');
    if (description == '<p></p>' || description == '<p></p>\n') {
      setEditorError({ title: 'Поле обязательно для заполнения' });
    } else {
      setEditorError({});
    }
    const data = {
      ...id,
      title: instructionState?.title || instruction.title || '',
      fileUrl: instruction.fileUrl,
      fileName: instruction.fileName,
      category: {
        id: instructionState?.category?.id || instruction?.category?.id || '',
      },
      description: description,
    };
    for (const d in data) {
      if (!data[d]) {
        delete data[d];
      }
      if (d === 'category') {
        if (!data[d]?.id) {
          delete data[d];
        }
      }
    }
    setInstructionState(data);
  }, [editorState, instruction]);

  const removeFileHAndler = async () => {
    setInstructionState({ ...instructionState, fileUrl: null, fileName: null });
  };

  const editHandler = async () => {
    let uploadFile = {};
    const id = instructionState?.id || uuidv4();

    if (file) {
      uploadFile = await uploadFileApi.sendRequest(id, file);
    }

    if (Object.keys(uploadFile)?.length) {
      uploadFile = {
        fileUrl: uploadFile?.location || '',
        fileName: uploadFile?.originalname || '',
      };
    } else {
      uploadFile = {
        fileUrl: instructionState?.FileUrl || '',
        fileName: instructionState?.FileName || '',
      };
    }
    await editInstructionApi.sendRequest({
      id,
      title: instructionState.title,
      description: instructionState.description,
      category: {
        id: instructionState.category.id,
      },
      ...uploadFile,
    });
    if (!instructionState?.id) {
      dispatch(setAlert({ status: 201, text: 'Инструкция добавлена' }));
    } else {
      dispatch(setAlert({ status: 201, text: 'Инструкция обновлена' }));
    }

    navigate(URLS.INSTRUCTION);
  };

  const handlerError = (data) => {
    setValidateErrors(data);
  };

  return (
    <>
      <FormValidator
        name="rtsp-server-form-name"
        validationConfig={config}
        changed={instructionState}
        setErrorsHandler={(data) => handlerError(data)}
      >
        <Grid container spacing={2}>
          <Grid xs={5} item>
            <FormControl fullWidth sx={{ mt: 2 }} disabled={!permission?.edit}>
              <InputLabel
                required={true}
                id="demo-simple-select-label"
                disabled={!permission?.edit}
              >
                Раздел
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="category"
                error={validateErrors?.category?.text}
                helperText={validateErrors?.category?.text}
                value={instructionState?.category?.id || ''}
                label="Раздел"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target }) => {
                  setInstructionState({
                    ...instructionState,
                    category: { id: target.value },
                  });
                  setChange(true);
                }}
                disabled={!permission?.edit}
              >
                {categories.map((c) => (
                  <MenuItem value={c.id}>{c.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={7} item>
            <TextField
              fullWidth
              label="Название инструкции"
              variant="outlined"
              name="title"
              required={config?.title?.isRequired}
              error={validateErrors?.title?.text}
              helperText={validateErrors?.title?.text}
              sx={{ mt: 2 }}
              value={instructionState?.title}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => {
                setChange(true);
                setInstructionState({
                  ...instructionState,
                  title: target.value,
                });
              }}
              onPaste={({ target }) => {
                setChange(true);
                setInstructionState({
                  ...instructionState,
                  title: target.value,
                });
              }}
              disabled={!permission?.edit}
            />
          </Grid>
          <Grid xs={12} item>
            <div className={styles.InstructionForm__Editor}>
              <Editor
                editorState={editorState}
                onEditorStateChange={(data) => {
                  if (permission?.edit) {
                    setEditorState(data);
                    setChange(true);
                  }
                }}
                placeholder="Текст к инструкции"
                editorClassName={
                  permission?.edit ? 'editor-class' : 'editor-class-disabled'
                }
                name="description"
                readOnly={!permission?.edit}
              />
            </div>
            {editorError.title && (
              <Typography variant="caption" as="div" color="error">
                {editorError.title}
              </Typography>
            )}
          </Grid>
        </Grid>

        {file || instructionState?.fileUrl ? (
          <>
            <Stack
              direction="row"
              spacing={0}
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <GetAppOutlinedIcon color="primary" />
              {instructionState?.fileUrl ? (
                <a
                  href={instructionState?.fileUrl}
                  download
                  target="blank"
                  style={{ color: 'rgb(25, 118, 210)' }}
                >
                  <Typography as="p" variant="p" color="primary">
                    {((filename || instructionState?.fileName) &&
                      instructionState?.fileName) ||
                      'Файл не добавлен'}
                  </Typography>
                </a>
              ) : (
                <Typography as="p" variant="p" color="primary">
                  {filename || instructionState?.fileName || 'Файл не добавлен'}
                </Typography>
              )}

              {(filename || instructionState?.fileName) && permission?.edit && (
                <IconButton
                  color="inherit"
                  size="small"
                  sx={{ opacity: 0.3, ml: 1 }}
                  onClick={() => {
                    removeFileHAndler();
                    setChange(true);
                    setFile(null);
                  }}
                  disabled={!permission?.edit}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>
            {fileError.title && (
              <Typography variant="caption" as="div" color="error">
                {fileError.title}
              </Typography>
            )}
          </>
        ) : (
          <>
            <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
              {permission?.edit && (
                <Button
                  variant="outlined"
                  color={'success'}
                  onClick={() => setOpenAddFileModal(true)}
                  disabled={!permission?.edit}
                >
                  Добавить файл
                </Button>
              )}
            </Stack>
            <UploadFileDeviceModal
              fileAddedHandler={(file) => {
                setChange(true);
                setFile(file);
              }}
              helperText=""
              errorText="Не подходящий формат файл"
              handleClose={() => {
                setOpenAddFileModal(false);
              }}
              open={openAddFileModal}
            />
          </>
        )}

        <FooterButtonBar
          showDeleteButton={permission?.delete && instruction?.id}
          disabledDeleteButton={!permission?.delete}
          deleteButtonHandler={() => handlerDelete()}
          cancelButtonHandler={() => navigate(URLS.INSTRUCTION)}
          showCancelButton={true}
          showUpdateButton={permission?.edit && instruction?.id}
          disabledUpdateButton={
            !!validateErrors?.title ||
            !!editorError?.title ||
            !!fileError?.title ||
            !instructionState?.title ||
            !permission?.edit ||
            !wasChange
          }
          updateButtonHandler={editHandler}
          showCreateButton={permission?.create && !instruction?.id}
          disabledCreateButton={
            !!validateErrors?.title ||
            !!editorError?.title ||
            !!fileError?.title ||
            !instructionState?.title ||
            !instructionState?.category?.id ||
            !permission?.create
          }
          createButtonHandler={editHandler}
          loading={
            editInstructionApi.loading ||
            uploadFileApi.loading ||
            getInstructionCategoriesApi.loading
          }
        />
      </FormValidator>
    </>
  );
};
