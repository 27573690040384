import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Pagination,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useRef, useState } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { useApi } from '../../hooks/useApi';
import { getFile, getInstruction } from '../../api/InstructionApi';
export const FaqInstructionLayoutV2 = ({
  categories,
  items,
  setSelectCategory,
  addCategoryActionButtonHandler,
  editCategoryActionButtonHandler,
  deleteCategoryActionButtonHandler,
  onSortEnd,
  deleteItemActionButtonHandler,
  paginationCount,
  page,
  paginationHandler,
  editItemActionButtonHandler,
  searchActionHandler,
  search,
  categoryLoading,
  itemsLoading,
  permission,
  sortItemsHandler,
  selectCategory,
  entityName = 'Вопрос',
  searchLabel = 'Поиск по вопросу',
}) => {
  const [expanded, setExpanded] = useState(false);
  const [createdSort, setCreatedSort] = useState(false);
  const [updatedSort, setUpdatedSort] = useState(false);
  const GetInstructionApi = useApi(getInstruction);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const splitTitle = (title) => {
    if (title?.length > 23) {
      return title?.slice(0, 23) + '...';
    } else {
      return title;
    }
  };
  const ref = useRef(null);

  const downloadFile = async (instructionId) => {
    try {
      const result = await GetInstructionApi.sendRequest(instructionId);
      const link = document.createElement('a');
      link.href = result.fileUrl;
      link.download = result.fileName;
      link.click();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            sx={{ mb: 2 }}
            label={searchLabel}
            value={search}
            onChange={({ target: { value } }) => searchActionHandler(value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {!categoryLoading ? (
            <Card
              variant="outlined"
              sx={{
                minHeight: '80vh',

                overflow: 'auto',
                mb: 3,
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                sx={{ px: 2, py: 1 }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  {!!search && (
                    <div>
                      <IconButton
                        onClick={() => searchActionHandler('')}
                        size="small"
                      >
                        <ArrowBackOutlinedIcon sx={{ fontSize: '17px' }} />
                      </IconButton>
                    </div>
                  )}
                  <Typography sx={{ fontWeight: 'bold' }}>Раздел</Typography>
                </Stack>
                {permission?.create && (
                  <div>
                    <IconButton
                      onClick={() => addCategoryActionButtonHandler()}
                    >
                      <AddCircleOutlineOutlinedIcon />
                    </IconButton>
                  </div>
                )}
              </Stack>
              <Divider />
              <SortableList
                onSortEnd={onSortEnd}
                className="list"
                draggedItemClassName="dragged"
              >
                {!search ? (
                  categories?.map((category, index) => (
                    <SortableItem ref={ref} key={index}>
                      <div>
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ py: 1 }}
                          onClick={() => setSelectCategory(category.id)}
                          className={`hover-stack ${
                            selectCategory == category.id ? 'active-stack' : ''
                          }`}
                        >
                          <Typography sx={{ pl: 2 }}>
                            {splitTitle(category.title)}
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ pr: 2.5 }}
                            alignItems="center"
                          >
                            {permission?.edit && (
                              <div>
                                <IconButton
                                  size="small"
                                  disabled={!permission?.edit}
                                  onClick={() =>
                                    editCategoryActionButtonHandler(category)
                                  }
                                >
                                  <CreateOutlinedIcon
                                    sx={{ fontSize: '17px' }}
                                  />
                                </IconButton>
                              </div>
                            )}
                            {permission?.delete && (
                              <div>
                                <IconButton
                                  size="small"
                                  disabled={!permission?.delete}
                                  onClick={() =>
                                    deleteCategoryActionButtonHandler(category)
                                  }
                                >
                                  <DeleteOutlineOutlinedIcon
                                    sx={{ fontSize: '17px' }}
                                  />
                                </IconButton>
                              </div>
                            )}
                            {permission?.edit && (
                              <div>
                                <SortableKnob>
                                  <IconButton
                                    disabled={!permission?.edit}
                                    aria-label="dragndrop"
                                    size="small"
                                    sx={{ fontSize: '17px' }}
                                    ref={ref}
                                  >
                                    <DragIndicatorOutlinedIcon />
                                  </IconButton>
                                </SortableKnob>
                              </div>
                            )}
                          </Stack>
                        </Stack>

                        <Divider />
                      </div>
                    </SortableItem>
                  ))
                ) : (
                  <Typography sx={{ p: 2 }}>
                    Вывод результатов поиска
                  </Typography>
                )}
              </SortableList>
            </Card>
          ) : (
            <CircularProgress />
          )}
        </Grid>
        <Grid item xs={8}>
          {!itemsLoading ? (
            !!items?.length ? (
              <>
                <Card
                  variant="outlined"
                  sx={{
                    minHeight: '80vh',
                    mb: paginationCount < 1 ? 5 : 0,
                    overflow: 'auto',
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={0}
                    sx={{ px: 2, py: 1 }}
                    justifyContent="space-between"
                  >
                    <Typography sx={{ fontWeight: 'bold', width: '35%' }}>
                      {entityName}
                    </Typography>
                    <Stack direction="row" spacing={1} justifyContent="start">
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        Дата создания
                      </Typography>
                      <IconButton
                        size="small"
                        disabled={!permission?.edit}
                        onClick={() => {
                          const st = !createdSort ? 1 : 0;
                          setCreatedSort(st);
                          sortItemsHandler('created', st);
                        }}
                      >
                        <SwapVertOutlinedIcon sx={{ fontSize: '17px' }} />
                      </IconButton>
                    </Stack>
                    <Stack direction="row" spacing={1} justifyContent="start">
                      <div>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                          }}
                        >
                          Дата изменений
                        </Typography>
                      </div>
                      <IconButton
                        size="small"
                        disabled={!permission?.edit}
                        onClick={() => {
                          const st = !updatedSort ? 1 : 0;
                          setUpdatedSort(st);
                          sortItemsHandler('updated', st);
                        }}
                      >
                        <SwapVertOutlinedIcon sx={{ fontSize: '17px' }} />
                      </IconButton>
                    </Stack>
                    <Typography sx={{ fontWeight: 'bold', width: '80px' }}>
                      Действие
                    </Typography>
                  </Stack>

                  {items?.map((i) => (
                    <Accordion
                      onChange={handleChange(i.id)}
                      expanded={expanded === i.id}
                      sx={{
                        py: 0,
                        m: 0,
                        background: expanded !== i.id ? 'none' : '#FAFAFA',
                        boxShadow: 'none',
                        borderBottom: 'solid 1px #E6E6E6',
                      }}
                    >
                      <AccordionSummary
                        className="hover-stack"
                        key={i.id}
                        aria-controls={i.id}
                        id={i.id}
                        sx={{
                          px: 0,
                          py: 0,
                          background:
                            expanded !== i.id ? 'none' : '#FAFAFA !important',
                          color: expanded !== i.id ? '#000' : '#222 !important',
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{ px: 2, py: 1, width: '100%' }}
                          // justifyContent="space-between"
                        >
                          <div>
                            <IconButton
                              size="small"
                              onClick={handleChange(i.id)}
                            >
                              {expanded !== i.id ? (
                                <ArrowDropDownOutlinedIcon
                                  sx={{ fontSize: '17px' }}
                                />
                              ) : (
                                <ArrowDropUpOutlinedIcon
                                  sx={{ fontSize: '17px' }}
                                />
                              )}
                            </IconButton>
                          </div>
                          <div
                            style={{
                              width:
                                expanded !== i.id ? '30%' : 'calc(35% + 400px)',
                              pr: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight:
                                  expanded !== i.id ? 'normal' : 'bold',
                                ml: 0,
                                // marginLeft:
                                //   expanded !== i.id ? '-20px !important' : 0,
                              }}
                            >
                              {expanded !== i.id
                                ? splitTitle(i?.title)
                                : i?.title}
                            </Typography>
                          </div>
                          {expanded !== i.id && (
                            <>
                              <div
                                style={{
                                  display: 'block',
                                  textAlign: 'left',
                                }}
                              >
                                <Typography
                                  sx={{
                                    width: '150px',
                                    ml: '2px',
                                  }}
                                >
                                  {format(
                                    new Date(i?.created),
                                    'dd.MM.yyyy HH:mm:ss',
                                  )}
                                </Typography>
                              </div>
                              <div
                                style={{
                                  display: 'block',
                                  textAlign: 'left',
                                }}
                              >
                                <Typography
                                  sx={{
                                    width: '150px',
                                    m: 0,
                                    ml: '12px',
                                  }}
                                >
                                  {format(
                                    new Date(i?.updated),
                                    'dd.MM.yyyy HH:mm:ss',
                                  )}
                                </Typography>
                              </div>
                            </>
                          )}
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ width: '80px' }}
                            justifyContent="center"
                          >
                            {permission?.edit && (
                              <div>
                                <IconButton
                                  size="small"
                                  disabled={!permission?.edit}
                                  onClick={() => editItemActionButtonHandler(i)}
                                  sx={{ ml: 3 }}
                                >
                                  <CreateOutlinedIcon
                                    sx={{ fontSize: '17px' }}
                                  />
                                </IconButton>
                              </div>
                            )}
                            {permission?.delete && (
                              <div>
                                <IconButton
                                  size="small"
                                  disabled={!permission?.delete}
                                >
                                  <DeleteOutlineOutlinedIcon
                                    onClick={() =>
                                      deleteItemActionButtonHandler(i)
                                    }
                                    sx={{ fontSize: '17px' }}
                                  />
                                </IconButton>
                              </div>
                            )}
                          </Stack>
                        </Stack>
                      </AccordionSummary>

                      <AccordionDetails
                        dangerouslySetInnerHTML={{
                          __html: i?.description,
                        }}
                      ></AccordionDetails>
                      {i?.fileUrl && (
                        <p
                          onClick={() => downloadFile(i.id)}
                          target="blank"
                          style={{
                            color: 'rgb(25, 118, 210)',
                            marginLeft: '15px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: '15px',
                            marginTop: '-10px',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          <DownloadOutlinedIcon />
                          <Typography as="p" variant="p" color="primary">
                            {i?.fileName || i?.fileUrl}
                          </Typography>
                        </p>
                      )}
                    </Accordion>
                  ))}
                </Card>

                <Stack sx={{ width: '100%', my: 3 }} justifyContent="end">
                  {paginationCount > 1 && (
                    <Pagination
                      count={paginationCount}
                      page={page}
                      onChange={paginationHandler}
                      sx={{ ml: 'auto' }}
                      variant="outlined"
                      shape="rounded"
                    />
                  )}
                </Stack>
              </>
            ) : (
              <Typography variant="h6" sx={{ textAlign: 'center', mt: 7 }}>
                Данный раздел еще не содержит записей
              </Typography>
            )
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Grid>
    </>
  );
};
