import { CurrencyInput } from 'react-currency-mask';
import { TextField } from '@mui/material';

export const CurrencyInputComponent = ({
  maskOptions,
  onChangeValue,
  onInput,
  defaultValue,
  label,
  ...props
}) => {
  return (
    <CurrencyInput
      label={label}
      hideSymbol={true}
      max={999999.99}
      value={props.value}
      onInput={onInput}
      InputLabelProps={{
        shrink: true,
      }}
      onChangeValue={(event, originalValue, maskedValue) => {
        onChangeValue({ target: { value: originalValue } });
      }}
      InputElement={
        <TextField
          onChange={({ target: { value } }) => console.log({ fv: value })}
          {...props}
        />
      }
    />
  );
};
