import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { useApi } from '../hooks/useApi';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { URLS } from '../utils/urls.util';
import {
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { pagesIds } from '../utils/pages';
import { getAllDevicesUpdates } from '../api/DeviceUpdatesApi';
import styles from '../components/DeviceBigList/DeviceBigList.module.scss';
import { format } from 'date-fns';

export const DeviceUpdatesListPage = () => {
  const deviceUpdatesApi = useApi(getAllDevicesUpdates);
  const [limit, setLimit] = useState(15);
  const [deviceUpdates, setDeviceUpdates] = useState([]);
  const [page, setPage] = useState(0);

  const location = useLocation();

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Typography key="2" color="text.primary">
      Обновления оборудования
    </Typography>,
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const handleChange = (event, value) => {
    navigate(URLS.DEVICE_UPDATES_PAGE + '?page=' + value);
  };
  const p = searchParams.get('page');
  useEffect(() => {
    setPage(p);
    if (+p > 1) {
      fetchDevices(p - 1);
    } else {
      fetchDevices();
    }
  }, [location]);

  const fetchDevices = async (
    offset = 0,
    field = 'createdDate',
    descending = true,
  ) => {
    // limit, offset
    const result = await deviceUpdatesApi.sendRequest({
      sortFields: [{ field: field, descending: descending }],
      page: +offset,
      pageSize: limit,
    });
    setDeviceUpdates(result);
  };

  const [sortTable, setSortTable] = useState(false);

  const sortAsc = (key) => {
    fetchDevices(p > 0 ? p - 1 : 0, key, false);
  };
  const sortDesc = (key) => {
    fetchDevices(p > 0 ? p - 1 : 0, key, true);
  };

  const clickRowHandler = (id) => {
    navigate(URLS.DEVICE_UPDATES_PAGE + '/' + id + '?page=' + page);
  };

  return (
    <MainContainer
      title="Список обновлений оборудования"
      breadcrumbs={breadcrumbs}
      titleAs="h4"
      titleStyles={{ marginTop: '30px' }}
      actionButton={
        permission.create
          ? {
              text: 'Добавить обновление',
              action: () =>
                navigate(URLS.DEVICE_UPDATES_PAGE + '/create?page=' + page),
            }
          : null
      }
      pageId={pagesIds.devices}
      setPermission={setPermission}
    >
      <Grid container spacing={2} sx={{ mt: 2, mb: 5 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <TableSortLabel
                    active={true}
                    direction={sortTable?.name ? 'desc' : 'asc'}
                    onClick={() => {
                      if (sortTable?.name) {
                        sortDesc('name');
                        setSortTable({ ...sortTable, name: false });
                      } else {
                        sortAsc('name');
                        setSortTable({ ...sortTable, name: true });
                      }
                    }}
                  >
                    <b>Название</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  <TableSortLabel
                    active={true}
                    direction={sortTable?.deviceType ? 'desc' : 'asc'}
                    onClick={() => {
                      if (sortTable?.deviceType) {
                        sortDesc('deviceType');
                        setSortTable({
                          ...sortTable,
                          deviceType: false,
                        });
                      } else {
                        sortAsc('deviceType');
                        setSortTable({
                          ...sortTable,
                          deviceType: true,
                        });
                      }
                    }}
                  >
                    <b>Тип оборудования</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  <TableSortLabel
                    active={true}
                    direction={sortTable?.version ? 'desc' : 'asc'}
                    onClick={() => {
                      if (sortTable?.version) {
                        sortDesc('version');
                        setSortTable({
                          ...sortTable,
                          version: false,
                        });
                      } else {
                        sortAsc('version');
                        setSortTable({
                          ...sortTable,
                          version: true,
                        });
                      }
                    }}
                  >
                    <b>Версия</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  <TableSortLabel
                    active={true}
                    direction={sortTable?.createdDate ? 'desc' : 'asc'}
                    onClick={() => {
                      if (sortTable?.createdDate) {
                        sortDesc('createdDate');
                        setSortTable({
                          ...sortTable,
                          createdDate: false,
                        });
                      } else {
                        sortAsc('createdDate');
                        setSortTable({
                          ...sortTable,
                          createdDate: true,
                        });
                      }
                    }}
                  >
                    <b>Дата создания</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  <TableSortLabel
                    active={true}
                    direction={sortTable?.updatedDate ? 'desc' : 'asc'}
                    onClick={() => {
                      if (sortTable?.updatedDate) {
                        sortDesc('updatedDate');
                        setSortTable({
                          ...sortTable,
                          updatedDate: false,
                        });
                      } else {
                        sortAsc('updatedDate');
                        setSortTable({
                          ...sortTable,
                          updatedDate: true,
                        });
                      }
                    }}
                  >
                    <b>Дата изменения</b>
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deviceUpdates?.items?.map((device) => (
                <TableRow
                  key={device?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => clickRowHandler(device?.id)}
                  className={styles?.DeviceBigList__Row}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: '15px' }}
                  >
                    {device?.name}
                  </TableCell>
                  <TableCell align="left" sx={{ padding: '15px' }}>
                    {device?.deviceType}
                  </TableCell>
                  <TableCell align="left" sx={{ padding: '15px' }}>
                    {device?.version}
                  </TableCell>
                  <TableCell align="left" sx={{ padding: '15px' }}>
                    {format(
                      new Date(device?.createdDate),
                      'dd.MM.yyyy HH:mm:ss',
                    )}
                  </TableCell>
                  <TableCell align="left" sx={{ padding: '15px' }}>
                    {format(
                      new Date(device?.updatedDate),
                      'dd.MM.yyyy HH:mm:ss',
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {Math.ceil(deviceUpdates?.totalCount / deviceUpdates?.pageSize) > 1 && (
        <Grid container spacing={2} justifyContent="flex-end">
          <Pagination
            sx={{ mt: 3, mb: 5 }}
            count={Math.ceil(
              deviceUpdates?.totalCount / deviceUpdates?.pageSize,
            )}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
          />
        </Grid>
      )}
    </MainContainer>
  );
};
