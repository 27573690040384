import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { DeviceFormActionButtons } from '../DeviceFormActionButtons/DeviceFormActionButtons';
import { getDeviceCategories } from '../../api/DeviceApi';
import { FormValidator } from '../FormValidator/FormValidator';
import { DeviceValidationConfig } from '../../utils/formValidatorConfigs/config';

export const DeviceForm = ({ device, setIsCreate, permission }) => {
  const [deviceState, setDeviceState] = useState({});
  const [deviceCategories, setDeviceCategories] = useState([]);
  const [addNewCategory, setAddNewCategory] = useState(false);
  const [validateErrors, setValidateErrors] = useState({});
  const [formDisabled, setFormDisabled] = useState(true);
  const [wasChange, setChange] = useState(false);

  const [changedStateChecker, setChangedStateChecker] = useState(false);
  const [parameterCheckerList, setParameterCheckerList] = useState([]);
  const config = useMemo(() => DeviceValidationConfig(), []);

  useEffect(() => {
    let inputs = document.getElementsByTagName('input');
    let elemets = Array.prototype.slice.call(inputs);
    setChangedStateChecker(elemets?.some((el) => el?.required && !el.value));
  }, [
    deviceState,
    deviceCategories,
    addNewCategory,
    validateErrors,
    formDisabled,
    wasChange,
    parameterCheckerList,
  ]);
  useEffect(() => {
    const configKeys = Object.keys(config);

    if (wasChange && Object.keys(validateErrors).length === 0) {
      let canSave = true;
      configKeys.forEach((key) => {
        if (canSave && config[key]?.isRequired && !(key in deviceState)) {
          canSave = false;
        }
      });
      setFormDisabled(!canSave);
    } else if (!formDisabled) {
      setFormDisabled(true);
    }
  }, [deviceState, validateErrors]);

  useEffect(() => {
    setDeviceState(device);
    getDeviceCategories().then(([result, count]) => {
      setDeviceCategories(result);
    });
  }, [device]);

  const onChange = (value, fieldName) => {
    if (!wasChange) {
      setChange(true);
    }
    setDeviceState({ ...deviceState, [fieldName]: value });
  };

  useEffect(() => {
    if (
      deviceState?.deviceSeriesId === 'b923fe31-a23b-4731-8aaf-2e9875eb7000' &&
      (deviceState?.deviceCategoryId ===
        'b923fe31-a23b-4731-8aaf-2e9875eb6f02' ||
        deviceState?.deviceCategoryId ===
          'b923fe31-a23b-4731-8aaf-2e9875eb6f03')
    ) {
      onChange(null, 'deviceCategoryId');
    }
  }, [deviceState]);

  return (
    <FormValidator
      id="device-form"
      name="device-form-name"
      validationConfig={config}
      changed={deviceState}
      setErrorsHandler={(data) => setValidateErrors(data)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Название"
            variant="outlined"
            sx={{ mt: 2 }}
            name="name"
            value={deviceState?.name}
            required={config?.name?.isRequired}
            error={!!validateErrors?.name?.text}
            helperText={validateErrors?.name?.text}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) => onChange(target.value, 'name')}
            disabled={!permission?.edit}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Код"
            variant="outlined"
            sx={{ mt: 2 }}
            name="code"
            value={deviceState?.code}
            required={config?.code?.isRequired}
            error={!!validateErrors?.code?.text}
            helperText={validateErrors?.code?.text}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) => onChange(target.value, 'code')}
            disabled={!permission?.edit}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl
            error={validateErrors?.deviceSeriesId?.text}
            helperText={validateErrors?.deviceSeriesId?.text}
            required={config?.deviceSeriesId?.isRequired}
            fullWidth
            sx={{ mt: 2 }}
            name="deviceSeriesId"
            disabled={!permission?.edit}
          >
            <InputLabel id="device-type">Серия оборудования</InputLabel>
            <Select
              labelId="device-type"
              id="device-type-select"
              value={deviceState?.deviceSeriesId || ''}
              label="Серия оборудования"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => {
                onChange(target.value, 'deviceSeriesId');
              }}
              disabled={!permission?.edit || device?.id}
            >
              <MenuItem value="b923fe31-a23b-4731-8aaf-2e9875eb5000">
                ELTIS5000
              </MenuItem>
              <MenuItem value="b923fe31-a23b-4731-8aaf-2e9875eb7000">
                ELTIS IP
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl
            error={validateErrors?.deviceCategoryId?.text}
            helperText={validateErrors?.deviceCategoryId?.text}
            required={config?.deviceCategoryId?.isRequired}
            fullWidth
            sx={{ mt: 2 }}
            name="deviceCategoryId"
            disabled={!permission?.edit}
          >
            <InputLabel id="device-type">Категория оборудования</InputLabel>
            <Select
              labelId="device-type"
              id="device-type-select"
              value={deviceState?.deviceCategoryId || ''}
              label="Категория оборудования"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) =>
                onChange(target.value, 'deviceCategoryId')
              }
              disabled={
                !permission?.edit || !deviceState?.deviceSeriesId || device?.id
              }
            >
              {deviceState?.deviceSeriesId ===
              'b923fe31-a23b-4731-8aaf-2e9875eb7000'
                ? deviceCategories
                    ?.filter(
                      (d) =>
                        d.id !== 'b923fe31-a23b-4731-8aaf-2e9875eb6f02' &&
                        d.id !== 'b923fe31-a23b-4731-8aaf-2e9875eb6f03',
                    )
                    ?.map((category) => (
                      <MenuItem key={category?.id} value={category?.id}>
                        {category.name}
                      </MenuItem>
                    ))
                : deviceCategories?.map((category) => (
                    <MenuItem key={category?.id} value={category?.id}>
                      {category.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            label="Описание/комментарий"
            variant="outlined"
            sx={{ mt: 2 }}
            name="description"
            value={deviceState?.description}
            error={validateErrors?.description?.text}
            helperText={validateErrors?.description?.text}
            required={config?.description?.isRequired}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) => onChange(target.value, 'description')}
            disabled={!permission?.edit}
          />
        </Grid>
      </Grid>

      {changedStateChecker && (
        <Typography
          variant="caption"
          color="error"
          as="div"
          sx={{ mt: 3, mb: '-30px' }}
        >
          Не все обязательные поля заполнены
        </Typography>
      )}

      <DeviceFormActionButtons
        disabled={formDisabled || changedStateChecker}
        setIsCreate={(data) => setIsCreate(data)}
        device={deviceState}
        permission={permission}
      />
    </FormValidator>
  );
};
