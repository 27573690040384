import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { useApi } from '../hooks/useApi';
import { getOneFaqCategory } from '../api/FaqApi';
import { useParams, useNavigate } from 'react-router-dom';
import { SingleFaqCategory } from '../components/SingleFaqCategory/SingleFaqCategory';
import { pagesIds } from '../utils/pages';
import { URLS } from '../utils/urls.util';

export const SingleFaqCategoryPage = () => {
  const [faqCategory, setFaqCategory] = useState({});
  const getOneFaqCategoryApi = useApi(getOneFaqCategory);
  const { id } = useParams();

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);


  useEffect(() => {
    if (id != 'create') {
      getOneFaqCategoryApi.sendRequest(id).then((result) => {
        setFaqCategory(result);
      });
    }
  }, []);

  return (
    <MainContainer pageId={pagesIds.faq} setPermission={setPermission}>
      <SingleFaqCategory permission={permission} id={id} category={faqCategory} />
    </MainContainer>
  );
};
