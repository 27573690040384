import axios from 'axios';
import { ENV } from '../utils/env';
import AuthService from './KeycloakApi';

export const getAllUsers = async (
  limit = 16,
  offset = 0,
  filter = {},
  groupList = [],
) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/get-all`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        limit,
        offset,
        ...filter,
        groupList,
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getAllUsersFromAdmin = async (
  limit = 16,
  offset = 0,
  filter = {},
  groupList = [],
) => {
  try {
    const token = AuthService.getToken();
    if (!filter?.search) {
      delete filter?.search;
    }

    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/get-all-users-from-admin`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        limit,
        offset,
        ...filter,
        groupList,
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getAllKeycloakGroups = async (dto) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/get-all-groups`,
      params: dto || {},
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const checkUserCanBeRemove = async (id) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/check-user-can-be-remove/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const checkGroupMembers = async (id) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/check-group-members/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getAllUsersByGroupId = async (id, dto) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/get-group/${id}/users`,
      params: dto || {},
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const removeUserFromGroup = async (userId, groupId) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'delete',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/remove-user-from-group/${userId}/${groupId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const removeGroup = async (groupId) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'delete',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/remove-group/${groupId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createRole = async (data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/create-role`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const updateRole = async (data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'put',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/update-role`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const getOneKeycloakGroup = async (id, prepare) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/get-group/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        prepare,
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const addNewUserInGroup = async (userId, groupId) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'put',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/add-new-user-in-group/${groupId}/${userId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getAllUsersByOrganizationId = async (
  limit = 16,
  offset = 0,
  otherParams = {},
  groupList = [],
) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/get-all-by-organization-id`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        limit,
        offset,
        ...otherParams,
        groupList,
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getOneUser = async (id) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/get/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {},
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'delete',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/remove`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'patch',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/update`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const inviteUser = async (data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/invite-user`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const inviteUserByCompany = async (data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/invite-user-by-company`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const inviteUserAgain = async (id) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/invite-user-again/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const checkAdministratorChangeRole = async (
  id,
  roleName = 'Eltis-admin:Администратор платформы',
) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/user/change-user-admin-role/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        roleName,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
