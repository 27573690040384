import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { URLS } from '../../utils/urls.util';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';
import { VideoServersNavigation } from './VideoServersNavigation';
import { VideoServersSettingsFrom } from '../VideoServersCameraForm/VideoServersSettingsFrom';

export const VideoServersBase = ({ permission }) => {
  const [wasChange, setChange] = useState(false);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="text.primary">
      Настройки камеры
    </Link>,
  ];

  return (
    <FullPageLayout
      seoTitle="Настройки камеры"
      pageTitle="Настройки камеры"
      breadcrumbs={breadcrumbs}
      showHiddenData={true}
      isCreated={true}
    >
      <VideoServersNavigation activeTab={1} wasChange={wasChange} />
      <VideoServersSettingsFrom
        wasChange={wasChange}
        setChange={setChange}
        permission={permission}
      />
    </FullPageLayout>
  );
};
