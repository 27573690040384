import React from 'react';
import { Helmet } from 'react-helmet';

export const MetaData = ({ title }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Eltis - {title || 'Панель администратора'}</title>

      <link rel="icon" href="/favicon.ico" type="image/x-icon" />
    </Helmet>
  );
};
