import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  deleteUser,
  getAllUsersFromAdmin,
  inviteUser,
  inviteUserAgain,
  updateUser,
  inviteUserByCompany,
  checkAdministratorChangeRole,
} from '../../api/UserApi';
import { useApi } from '../../hooks/useApi';
import { UserValidationConfig } from '../../utils/formValidatorConfigs/config';
import { URLS } from '../../utils/urls.util';
import { UserFormActionButtons } from '../UserFormActionButtons/UserFormActionButtons';
import { FormValidator } from '../FormValidator/FormValidator';
import InputMask from 'react-input-mask';
import { setAlert } from '../../store/alert.store';
import { useDispatch } from 'react-redux';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

export const UserFrom = ({ isCreate, user, groups, permission, ...props }) => {
  const inviteUserApi = useApi(inviteUser);

  const updateUserApi = useApi(updateUser);
  const deleteUserApi = useApi(deleteUser);
  const [userState, setUserState] = useState({ groups: [] });
  const [validateErrors, setValidateErrors] = useState([]);
  const navigate = useNavigate();
  const [formDisabled, setFormDisabled] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [wasChange, setChange] = useState(false);
  const config = useMemo(() => UserValidationConfig(), []);
  const getAllUsersFromAdminApi = useApi(getAllUsersFromAdmin);
  const inviteUserAgainApi = useApi(inviteUserAgain);
  const checkAdministratorChangeRoleApi = useApi(checkAdministratorChangeRole);

  const dispatch = useDispatch();
  const handlerInviteUser = async () => {
    try {
      delete userState?.position;
      delete userState?.phone;
      await inviteUserApi.sendRequest(userState);
      dispatch(
        setAlert({
          text: 'Пользователь добавлен. Приглашение отправлено на почту пользователя',
          status: 201,
        }),
      );
      navigate(URLS.USERS);
    } catch (error) {}
  };

  const handlerDeleteUser = async () => {
    try {
      await deleteUserApi.sendRequest({ id: user.id });
      navigate(URLS.USERS);
      dispatch(setAlert({ status: 200, text: 'Пользователь удален' }));
    } catch (error) {}
  };

  const handlerUpdateUser = async () => {
    try {
      delete userState?.position;
      delete userState?.phone;
      delete userState?.originalGroups;
      delete userState?.permissions;
      await updateUserApi.sendRequest(userState);
      navigate(URLS.USERS);
    } catch (error) {}
  };

  useEffect(() => {
    const configKeys = Object.keys(config);
    const canSave = [];
    configKeys?.forEach((key) => {
      if (
        (config[key]?.isRequired && key in userState && !validateErrors[key]) ||
        !config[key]?.isRequired
      ) {
        canSave.push({ key, canSave: true });
      } else {
        canSave.push({ key, canSave: false });
      }
    });
    if (
      (config?.phone?.isRequired &&
        userState?.attributes?.phone &&
        !validateErrors?.phone) ||
      !config?.phone?.isRequired
    ) {
      canSave.map((c) => {
        if (c.key == 'phone') {
          c.canSave = true;
        }
        return c;
      });
    } else {
      canSave.map((c) => {
        if (c.key == 'phone') {
          c.canSave = false;
        }
        return c;
      });
    }
    setFormDisabled(
      canSave.some((c) => c.canSave === false) || !!!userState?.groups?.length,
    );
  }, [userState, validateErrors]);

  useEffect(() => {
    setUserState({
      ...user,
      groups: isCreate ? [] : user?.groups,
      enabled: isCreate ? true : user?.enabled,
    });
  }, [user, isCreate]);

  const changeMetaData = (attributes, key, value) => {
    attributes[key] = [value];
    return attributes;
  };

  const handlerError = (data) => {
    setValidateErrors(data);
  };

  const onChange = (value, fieldName) => {
    if (!user[fieldName] === value) {
      setChange(false);
    } else {
      setChange(true);
    }

    setUserState({ ...userState, [fieldName]: value });
  };

  const onChangeMeta = (value, fieldName) => {
    if (!wasChange) {
    }
    setChange(true);
    setUserState({
      ...userState,
      [fieldName]: value,
      attributes: changeMetaData(userState?.attributes || {}, fieldName, value),
    });
  };

  const fieldInObject = (name) =>
    !!userState?.meta?.find((item) => item.key === name);
  const splitGroupName = (g) => {
    const splitName = g?.split(':') || [];
    return splitName[splitName?.length - 1] || '-';
  };

  const invateAgainHandler = async () => {
    await inviteUserAgainApi.sendRequest(userState?.id);
    dispatch(
      setAlert({
        text: 'Приглашение повторно отправлено на почту пользователя.',
        status: 200,
      }),
    );
  };
  return (
    <div {...props}>
      <FormValidator
        name="user-form-name"
        validationConfig={config}
        changed={userState}
        setErrorsHandler={(data) => handlerError(data)}
        fieldInObject={fieldInObject}
      >
        {!userState?.emailVerified && userState?.id && permission?.edit && (
          <Button
            variant="outlined"
            onClick={() => invateAgainHandler()}
            sx={{ mt: 2 }}
          >
            Пригласить повторно
          </Button>
        )}

        <Stack direction="row" spacing={2} sx={{ mt: 5 }}>
          <TextField
            fullWidth
            label="Фамилия"
            variant="outlined"
            name="lastName"
            required={config.lastName.isRequired}
            error={validateErrors?.lastName?.text}
            helperText={validateErrors?.lastName?.text}
            value={userState?.lastName}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) => onChange(target.value, 'lastName')}
            disabled={!permission?.edit}
          />
          <TextField
            fullWidth
            label="Имя"
            variant="outlined"
            sx={{ mt: 2 }}
            name="firstName"
            required={config.firstName.isRequired}
            error={validateErrors?.firstName?.text}
            helperText={validateErrors?.firstName?.text}
            value={userState?.firstName}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) => onChange(target.value, 'firstName')}
            disabled={!permission?.edit}
          />
          <TextField
            fullWidth
            label="Отчество"
            variant="outlined"
            sx={{ mt: 2 }}
            name="middleName"
            required={config?.middleName?.isRequired}
            error={validateErrors?.middleName?.text}
            helperText={validateErrors?.middleName?.text}
            value={userState?.attributes?.middleName}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) => onChangeMeta(target.value, 'middleName')}
            disabled={!permission?.edit}
          />
        </Stack>

        <Stack direction="row" spacing={2} sx={{ mt: 5 }}>
          <FormControl disabled={!permission?.edit} sx={{ width: '33%' }}>
            <InputLabel
              disabled={!permission?.edit}
              id="demo-multiple-checkbox-label"
              required={true}
            >
              Роль
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={userState?.groups || []} //
              onChange={async ({ target: { value } }) => {
                setUserState({
                  ...userState,
                  groups: groups?.filter((g) => value?.includes(g)) || [],
                });
              }}
              input={<OutlinedInput label="Роль" />}
              renderValue={(selected) => {
                return selected
                  ?.map((g) =>
                    typeof g == 'string'
                      ? splitGroupName(g)
                      : splitGroupName(g?.name),
                  )
                  ?.join(', ');
              }}
              MenuProps={MenuProps}
              disabled={!permission?.edit}
            >
              {groups.map((group, index) => (
                <MenuItem key={index} value={group}>
                  <Checkbox
                    checked={userState?.groups?.some((g) => g == group)}
                  />

                  <ListItemText primary={splitGroupName(group)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="email"
            name="email"
            variant="outlined"
            required={config.email.isRequired}
            sx={{ mt: 2, width: '33%' }}
            error={validateErrors?.email?.text}
            helperText={validateErrors?.email?.text}
            value={userState?.email}
            disabled={!!user?.email || !permission?.edit}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) => onChange(target.value, 'email')}
          />
          <InputMask
            mask="+7(999)999-99-99"
            value={userState?.attributes?.phone}
            fullWidth
            label="Телефон"
            variant="outlined"
            sx={{ mt: 2, width: '33%' }}
            name="phone"
            required={config.phone.isRequired}
            error={!!validateErrors?.phone?.text}
            helperText={validateErrors?.phone?.text}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) =>
              permission?.edit && onChangeMeta(target.value, 'phone')
            }
            disabled={!permission?.edit}
          >
            {(props) => <TextField disabled={!permission?.edit} {...props} />}
          </InputMask>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 5 }}>
          <TextField
            fullWidth
            label="Должность"
            variant="outlined"
            sx={{ width: '33%' }}
            name="position"
            required={config.position.isRequired}
            error={validateErrors?.position?.text}
            helperText={validateErrors?.position?.text}
            value={userState?.attributes?.position}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) => onChangeMeta(target.value, 'position')}
            disabled={!permission?.edit}
          />
          <FormControlLabel
            sx={{ width: '33%' }}
            control={
              <Switch
                checked={userState.enabled}
                disabled={getAllUsersFromAdminApi.loading || !permission?.edit}
                onChange={async ({ target: { checked } }) => {
                  setUserState({ ...userState, enabled: checked });
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={userState.enabled ? 'Активен' : 'Неактивен'}
          />
        </Stack>
        <ActionApproveV2
          title="Вы действительно хотите удалить этого пользователя?"
          open={openApprove}
          handleClose={() => setOpenApprove(false)}
          handleSuccess={handlerDeleteUser}
          showDescription={false}
          handleSuccessText="Удалить"
        />

        <UserFormActionButtons
          disabled={formDisabled}
          permission={permission}
          user={user}
          handlerSaveNewUser={() => handlerInviteUser()}
          handlerUpdateUser={() => handlerUpdateUser()}
          handlerDeleteUser={() => setOpenApprove(true)}
          loading={
            inviteUserApi?.loading ||
            updateUserApi?.loading ||
            deleteUserApi?.loading ||
            getAllUsersFromAdminApi?.loading ||
            inviteUserAgainApi?.loading
          }
        />
      </FormValidator>
    </div>
  );
};
