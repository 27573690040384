import { Button, Stack } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { URLS } from '../../utils/urls.util';
import { useNavigate } from 'react-router-dom';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';

export const VideoServersNavigation = ({
  activeTab,
  wasChange,
}) => {
  const [openApprove, setOpenApprove] = useState(false);
  const [navigateLink, setNavigateLink] = useState('');
  const navigate = useNavigate();

  const onClickNavigateBtn = useCallback((path) => () => {
    if (wasChange) {
      setNavigateLink(path);
      setOpenApprove(true);
    } else {
      navigate(path);
    }
  }, [navigate, wasChange]);

  return (
    <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
      <Button
        variant={activeTab == 1 ? 'outlined' : 'text'}
        color="primary"
        onClick={onClickNavigateBtn(URLS.VIDEO_SERVERS_BASE)}
      >
        Основное
      </Button>
      <Button
        variant={activeTab == 2 ? 'outlined' : 'text'}
        color="primary"
        onClick={onClickNavigateBtn(URLS.VIDEO_SERVERS_RTSP)}
      >
        RTSP сервера
      </Button>
      <Button
        variant={activeTab == 3 ? 'outlined' : 'text'}
        color="primary"
        onClick={onClickNavigateBtn(URLS.VIDEO_SERVERS)}
      >
        Видео сервера
      </Button>

      <ActionApproveV2
        open={openApprove}
        title="У вас есть несохраненные данные. Перейти без сохранения?"
        handleClose={() => setOpenApprove(false)}
        showDescription={false}
        handleSuccess={() => navigate(navigateLink)}
        handleSuccessText="Да"
        handleCloseText="Нет"
      />
    </Stack>
  );
};
