import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { URLS } from '../utils/urls.util';
import { useApi } from '../hooks/useApi';
import {
  addNewUserInGroup,
  checkAdministratorChangeRole,
  checkUserCanBeRemove,
  getAllUsers,
  getAllUsersByGroupId,
  getAllUsersFromAdmin,
  getOneKeycloakGroup,
  removeUserFromGroup,
} from '../api/UserApi';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ActionApproveV2 } from '../components/Default/ActionApprove/ActionApproveV2';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/alert.store';
import { RoleFooterButtons } from '../components/RoleFooterButtons/RoleFooterButtons';
import ClearIcon from '@mui/icons-material/Clear';
import { FullPageLayout } from '../components/Default/Layout/FullPageLayout';
import { RoleInfo } from '../components/RoleInfo/RoleInfo';
import { pagesIds } from '../utils/pages';

export const SingleRoleUsersPage = () => {
  const [role, setRole] = useState({});
  const [page, setPage] = useState(1);
  const [max, setMax] = useState(10);
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);

  const [allUsers, setAllUsers] = useState([]);
  const [allUserCount, setAllUserCount] = useState(0);
  const [allUsersPage, setAllUsersPage] = useState(1);

  const [modal, setModal] = useState({});
  const [openModalAddUser, setOpenModalAddUser] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [filter, setFilter] = useState({
    groups: [],
    status: 'any',
    search: '',
  });
  const [isReadOnly, setIsReadOnly] = useState(true);

  const getOneKeycloakGroupApi = useApi(getOneKeycloakGroup);
  const getAllUsersByGroupIdApi = useApi(getAllUsersByGroupId);
  const removeUserFromGroupApi = useApi(removeUserFromGroup);
  const addNewUserInGroupApi = useApi(addNewUserInGroup);
  const userApi = useApi(getAllUsersFromAdmin);
  const checkUserCanBeRemoveApi = useApi(checkUserCanBeRemove);
  const checkAdministratorChangeRoleApi = useApi(checkAdministratorChangeRole);

  const { id } = useParams();
  const dispatch = useDispatch();

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  useEffect(() => {
    getOneKeycloakGroupApi.sendRequest(id).then((result) => {
      setRole(result);
      setIsReadOnly(
        result?.attributes?.IsReadOnly
          ? result?.attributes?.IsReadOnly[0] == 'True'
            ? true
            : false
          : false,
      );
    });

    fetchUsers(0);
  }, []);

  const fetchUsers = async (p = page) => {
    const [result, count] = await getAllUsersByGroupIdApi.sendRequest(id, {
      first: p,
      max,
    });
    setUsersCount(count);
    fetchAllUsers(filter, 0, id);
    setUsers(result);
  };

  const fetchAllUsers = async (f = filter, p = 0) => {
    const [result, count] = await userApi.sendRequest(max, p * max, {
      ...f,
      groupId: id,
    });

    setAllUsers(result);
    setAllUserCount(Math.abs(Math.ceil(count / max)));
  };

  const removeUser = async (userId) => {
    await removeUserFromGroupApi.sendRequest(userId, role.id);
    setUsers(users.filter((u) => u.id !== userId));
    dispatch(
      setAlert({
        status: 201,
        text: 'Пользователь успешно удален',
      }),
    );
  };
  const closeAddModal = () => {
    setOpenModalAddUser(false);
    setFilter({
      groups: [],
      status: 'any',
      search: '',
    });
    setPage(0);
    fetchUsers(0);
    fetchAllUsers({
      groups: [],
      status: 'any',
      search: '',
    });
  };

  const addNewUsersInGroup = async () => {
    for (const selectUser of selectedUsers) {
      await addNewUserInGroupApi.sendRequest(selectUser, role.id);
    }
    dispatch(
      setAlert({
        status: 201,
        text: 'Пользователи успешно добавлены',
      }),
    );
    closeAddModal();
  };

  const splitName = (name) => {
    if (name?.length > 30) {
      return (
        <Tooltip title={name}>
          <Typography>{name.slice(0, 30) + '...'}</Typography>
        </Tooltip>
      );
    } else {
      return name;
    }
  };
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="1" color="inherit" to={URLS.ROLES}>
      Роли
    </Link>,
    <Typography key="2" color="text.primary">
      {role?.attributes?.RoleName[0] || role?.name}
    </Typography>,
  ];
  return (
    <MainContainer pageId={pagesIds.roles} setPermission={setPermission}>
      <FullPageLayout
        titleAs="h4"
        obj={{}}
        titleStyles={{ marginTop: '30px' }}
        infoComponent={<RoleInfo role={role} />}
        seoTitle={
          'Роль - ' +
          (role?.attributes?.RoleName[0] || role?.name || 'Новая роль')
        }
        pageTitle={role?.attributes?.RoleName[0] || role?.name}
        isCreated={id == 'create'}
        breadcrumbs={breadcrumbs}
        showHiddenData={false}
      ></FullPageLayout>
      <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
        <Button
          variant="text"
          color="primary"
          onClick={() => navigate(URLS.ROLES + '/' + role?.id)}
        >
          Основная информация
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate(URLS.ROLES + '/' + role?.id + '/users')}
        >
          Пользователи
        </Button>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={9}>
          {permission?.create && (
            <Button
              variant="outlined"
              color="success"
              sx={{ mb: 4 }}
              onClick={() => {
                setOpenModalAddUser(true);
                fetchAllUsers({
                  groups: [],
                  status: 'any',
                  search: '',
                });
              }}
            >
              Добавить пользователя
            </Button>
          )}

          {!!users?.length && (
            <TableContainer component={Paper} sx={{ mb: 4 }}>
              <Table className="hover-data-table" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>ФИО</b>
                    </TableCell>
                    <TableCell>
                      <b>Email</b>
                    </TableCell>
                    <TableCell>
                      <b>Действие</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users?.map((item, index) => (
                    <TableRow
                      key={item.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontSize: '16px' }}
                        onClick={() => navigate(URLS.USERS + '/' + item?.id)}
                      >
                        {splitName(
                          item?.firstName && item?.lastName
                            ? item?.firstName +
                                ' ' +
                                (item?.attributes?.middleName
                                  ? item?.attributes?.middleName
                                  : '') +
                                ' ' +
                                item?.lastName
                            : item?.username,
                        )}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: '16px' }}
                        onClick={() => navigate(URLS.USERS + '/' + item?.id)}
                      >
                        {item?.email}
                      </TableCell>
                      <TableCell>
                        {permission?.delete && (
                          <Button
                            variant="text"
                            color="error"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            onClick={async () => {
                              const { isLastAdmin, hasAnotherRole } =
                                await checkAdministratorChangeRoleApi.sendRequest(
                                  item.id,
                                  role.name,
                                );

                              if (isLastAdmin) {
                                setModal({
                                  title:
                                    'Невозможно удалить последнего администратора платформы. Необходимо задать эту роль другому пользователю',
                                  target: item,
                                  open: true,
                                  handleClose: () =>
                                    setModal({ ...modal, open: false }),
                                  actionButtonText: 'Понятно',
                                  showCloseButton: false,
                                  handleSuccess: () => {
                                    setModal({ ...modal, open: false });
                                  },
                                  showDescription: false,
                                });
                              } else if (!hasAnotherRole) {
                                setModal({
                                  title:
                                    'Вы не можете удалить единственную роль у пользователя. Задайте пользователю другую роль, чтобы открепить от текущей',
                                  target: item,
                                  open: true,
                                  handleClose: () =>
                                    setModal({ ...modal, open: false }),
                                  actionButtonText: 'Понятно',
                                  handleSuccess: () => {
                                    setModal({ ...modal, open: false });
                                  },
                                  showCloseButton: false,
                                  showDescription: false,
                                });
                              } else {
                                setModal({
                                  title:
                                    'Вы действительно хотите удалить назначение этого пользователя данной роли?',
                                  target: item,
                                  open: true,
                                  handleClose: () =>
                                    setModal({ ...modal, open: false }),
                                  actionButtonText: 'Удалить',
                                  handleSuccess: () => {
                                    removeUser(item.id);
                                    setModal({ ...modal, open: false });
                                  },
                                  showDescription: false,
                                });
                              }
                            }}
                          >
                            Удалить
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {usersCount / max > 1 && (
            <Pagination
              count={Math.ceil(usersCount / max)}
              page={page}
              onChange={(event, value) => {
                setPage(+value);
                fetchUsers((+value - 1) * max);
              }}
              sx={{ mt: 3, mb: 4, ml: 'auto', display: 'block' }}
            />
          )}
        </Grid>
      </Grid>
      <ActionApproveV2
        target={modal.target}
        open={modal.open}
        title={modal.title}
        handleClose={modal.handleClose}
        actionButtonText={modal.actionButtonText}
        handleSuccess={modal.handleSuccess}
        showDescription={modal.showDescription}
        showCloseButton={modal?.showCloseButton}
      />
      <Modal
        open={openModalAddUser}
        onClose={() => {
          setAllUsersPage(1);
          setSelectedUsers([]);
          closeAddModal();
          setFilter({ ...filter, search: '' });
        }}
        sx={{
          width: '100%',
          overflow: 'scroll',
        }}
      >
        <Box
          sx={{
            maxWidth: '840px',
            margin: '4% auto',
            width: '100%',
            borderRadius: '8px',
            background: '#fff',
            padding: '20px',
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 3 }}
          >
            <Typography as="p" variant="h5">
              Добавить пользователя
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                setAllUsersPage(1);
                setSelectedUsers([]);
                closeAddModal();
                setFilter({ ...filter, search: '' });
              }}
            >
              <ClearIcon />
            </IconButton>
          </Stack>
          <TextField
            variant="outlined"
            fullWidth
            label="Поиск по ФИО, e-mail"
            sx={{ mb: 3 }}
            onChange={({ target: { value } }) => {
              setAllUsersPage(1);
              setFilter({ ...filter, search: value });
              fetchAllUsers({ ...filter, search: value });
            }}
          />
          {userApi.loading ? (
            <CircularProgress />
          ) : (
            <TableContainer component={Paper} sx={{ mb: 4 }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <b>ФИО</b>
                    </TableCell>
                    <TableCell>
                      <b>Email</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allUsers
                    ?.filter((item) => !users?.some((u) => u.id == item?.id))
                    .map((item, index) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ maxWidth: '250px' }}
                        >
                          <Checkbox
                            disabled={!!users?.some((u) => u.id == item?.id)}
                            checked={
                              !!selectedUsers?.some((id) => id == item.id) ||
                              !!users?.some((u) => u.id == item?.id)
                            }
                            onChange={({ target: { checked } }) => {
                              if (checked) {
                                setSelectedUsers([...selectedUsers, item.id]);
                              } else {
                                setSelectedUsers(
                                  selectedUsers?.filter((id) => id != item.id),
                                );
                              }
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ maxWidth: '250px' }}
                        >
                          {splitName(
                            item?.firstName && item?.lastName
                              ? item?.firstName + ' ' + item?.lastName
                              : item?.username,
                          )}
                        </TableCell>
                        <TableCell>{item?.email}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Stack direction="row" spacing={2} justifyContent="space-between">
            <div>
              {!userApi.loading && allUserCount > 1 && (
                <Pagination
                  count={allUserCount}
                  page={allUsersPage}
                  onChange={(event, value) => {
                    setAllUsersPage(+value);
                    fetchAllUsers(filter, +value - 1);
                  }}
                  sx={{ mt: 3, mb: 4, ml: 'auto', display: 'block' }}
                />
              )}
            </div>
            <div>
              <Button
                color="inherit"
                variant="outlined"
                sx={{ mr: 2 }}
                onClick={() => {
                  setAllUsersPage(1);
                  setSelectedUsers([]);
                  closeAddModal();
                  setFilter({ ...filter, search: '' });
                }}
              >
                Отмена
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={!selectedUsers?.length}
                onClick={() => {
                  setAllUsersPage(1);
                  setSelectedUsers([]);
                  addNewUsersInGroup();
                  setFilter({ ...filter, search: '' });
                }}
              >
                Добавить
              </Button>
            </div>
          </Stack>
        </Box>
      </Modal>
      <RoleFooterButtons
        role={role}
        showCloseButton={false}
        canBeRemove={!!!users?.length}
        isReadOnly={isReadOnly}
        permission={permission}
      />
    </MainContainer>
  );
};
