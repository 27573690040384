import { Pagination, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { getAllDevices } from '../api/DeviceApi';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { DeviceBigList } from '../components/DeviceBigList/DeviceBigList';
import { useApi } from '../hooks/useApi';
import { URLS } from '../utils/urls.util';
import { pagesIds } from '../utils/pages';

export const DevicesPage = () => {
  const deviceApi = useApi(getAllDevices);
  const [limit, setLimit] = useState(15);
  const [devices, setDevices] = useState([]);
  const [page, setPage] = useState(0);

  const location = useLocation();

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Typography key="2" color="text.primary">
      Список типов оборудования
    </Typography>,
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const handleChange = (event, value) => {
    navigate(URLS.DEVICES + '?page=' + value);
  };
  const p = searchParams.get('page');
  useEffect(() => {
    setPage(p);
    if (+p > 1) {
      fetchDevices(p - 1);
    } else {
      fetchDevices();
    }
  }, [location]);

  const fetchDevices = async (
    offset = 0,
    field = 'Name',
    descending = true,
  ) => {
    // limit, offset
    const result = await deviceApi.sendRequest({
      sortFields: [{ field: field, descending: descending }],
      page: +offset,
      pageSize: limit,
    });
    setDevices(result);
  };

  return (
    <MainContainer
      title="Список типов оборудования"
      breadcrumbs={breadcrumbs}
      actionButton={
        permission.create
          ? {
              text: 'Добавить тип оборудования',
              action: () =>
                navigate(URLS.DEVICES + '/create/details?page=' + page),
            }
          : null
      }
      pageId={pagesIds.devices}
      setPermission={setPermission}
    >
      <DeviceBigList
        devices={devices.items}
        page={page}
        sortableDevices={(data) =>
          fetchDevices(p, data?.field, data?.descending)
        }
      />
      {}
      {devices.totalCount > 0 && (
        <Pagination
          count={Math.ceil(devices.totalCount / devices.pageSize)}
          page={+devices.page + 1}
          onChange={handleChange}
          sx={{ mt: 3, mb: 4 }}
        />
      )}
    </MainContainer>
  );
};
