import axios from 'axios';
import { ENV } from '../utils/env';
import AuthService from './KeycloakApi';

export const getDefaultTariffs = async () => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/tariff/default/get`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getTariffDependencies = async () => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/tariff/dependencies`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const editDefaultTariffs = async (data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/tariff/default/edit`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data,
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
