import React from 'react';
import { MetaData } from '../components/Default/MetaData/MetaData';

export const NotFoundPage = () => {
  return (
    <>
      <MetaData title="Eltis - Страница не найдена" />
      <div>NotFound</div>
    </>
  );
};
