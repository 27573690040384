import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { useApi } from '../hooks/useApi';
import { getOneInstruction } from '../api/InstructionApi';
import { useParams, useNavigate } from 'react-router-dom';
import { SingleInstruction } from '../components/SingleInstruction/SingleInstruction';
import { pagesIds } from '../utils/pages';
import { URLS } from '../utils/urls.util';

export const SingleInstructionPage = () => {
  const [instruction, setInstruction] = useState({});
  const getOneInstructionApi = useApi(getOneInstruction);
  const { id } = useParams();

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  useEffect(() => {
    if (id != 'create') {
      getOneInstructionApi.sendRequest(id, ['category']).then((result) => {
        setInstruction(result);
      });
    }
  }, []);

  return (
    <MainContainer pageId={pagesIds.instructions} setPermission={setPermission}>
      <SingleInstruction
        permission={permission}
        id={id}
        instruction={instruction}
      />
    </MainContainer>
  );
};
