import {
  Button,
  Chip,
  CircularProgress,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { getAllKeycloakGroups, getAllUsersFromAdmin } from '../api/UserApi';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { useApi } from '../hooks/useApi';
import { URLS } from '../utils/urls.util';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { pagesIds } from '../utils/pages';

export const UsersPage = () => {
  const userApi = useApi(getAllUsersFromAdmin);
  const getAllKeycloakGroupsApi = useApi(getAllKeycloakGroups);
  const [limit, setLimit] = useState(10);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [page, setPage] = useState(0);

  const location = useLocation();
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Typography key="2" color="text.primary">
      Пользователи
    </Typography>,
  ];

  const handleChange = (event, value) => {
    setPage(+value || 0);
    fetchUsers(limit * (+value - 1));
  };
  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    const [result, count] = await getAllKeycloakGroupsApi.sendRequest({
      first: 0,
      max: -1,
    });
    setGroups(result);
  };
  const fetchUsers = async (offset = 0) => {
    const [result, count] = await userApi.sendRequest(limit, offset, filter);
    setUsers(result);
    setUsersCount(Math.ceil(count / limit));
  };
  const nameTrim = (user) => {
    const firstName = user.firstName;
    const lastName = user.lastName;
    const middleName = user?.attributes?.middleName
      ? user?.attributes?.middleName
      : '';
    let fullName =
      firstName && lastName
        ? firstName + ' ' + middleName + ' ' + lastName
        : '';
    fullName = fullName.trim();
    return fullName?.length > 35 ? fullName.slice(0, 35) + '...' : fullName;
  };

  const goToUserPage = (id) => {
    navigate(URLS.USERS + '/' + id);
  };

  const [filter, setFilter] = useState({
    groups: [],
    status: 'any',
    search: '',
  });
  useEffect(() => {
    setPage(0);
    fetchUsers(limit * 0);
  }, [filter]);

  return (
    <MainContainer
      pageId={pagesIds.users}
      setPermission={setPermission}
      title="Список пользователей"
      breadcrumbs={breadcrumbs}
      titleAs="h3"
      actionButton={
        permission?.create
          ? {
              text: 'Добавить пользователя',
              action: () => navigate(URLS.USERS + '/create'),
            }
          : null
      }
    >
      <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
        <TextField
          id="lastname-query"
          label="Поиск по имени, фамилии и email"
          variant="outlined"
          fullWidth
          onChange={({ target: { value } }) => {
            setFilter({ ...filter, search: value });
          }}
        />
      </Stack>
      <>
        {userApi?.loading ? (
          <CircularProgress />
        ) : (
          !!users?.length && (
            <TableContainer component={Paper} sx={{ mb: 4 }}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="users table"
                className="hover-data-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>ФИО</b>
                    </TableCell>
                    <TableCell>
                      <b>Email</b>
                    </TableCell>
                    <TableCell>
                      <b>Должность</b>
                    </TableCell>
                    <TableCell>
                      <b>Роль</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow
                      key={user.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => goToUserPage(user.id)}
                    >
                      <TableCell component="th" scope="row">
                        <Stack direction="row" spacing={1} alignItems="center">
                          {user.enabled ? (
                            <Tooltip
                              title="Пользователь активен"
                              placement="left"
                            >
                              <CheckCircleOutlineOutlinedIcon
                                fontSize="13px"
                                color="success"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="Пользователь неактивен"
                              placement="left"
                            >
                              <HighlightOffOutlinedIcon
                                fontSize="13px"
                                color="error"
                              />
                            </Tooltip>
                          )}
                          {!user.emailVerified && (
                            <Tooltip
                              title="Email пользователя не подтвержден"
                              placement="left"
                            >
                              <WarningAmberOutlinedIcon
                                fontSize="13px"
                                color="warning"
                              />
                            </Tooltip>
                          )}
                          <Typography>{nameTrim(user) || '-'}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        {user?.attributes?.position
                          ? user?.attributes?.position[0]
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {user?.roleString?.split(',') &&
                          user?.roleString?.split(',')[0]}
                        {user?.roleString?.split(',')?.length > 1 && (
                          <Tooltip title={user?.roleString} sx={{ ml: 2 }}>
                            <Chip
                              size="small"
                              label={`+${
                                user?.roleString?.split(',')?.length - 1
                              }`}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        )}
      </>

      {!userApi?.loading && usersCount > 1 && (
        <Pagination
          count={usersCount}
          page={page}
          onChange={handleChange}
          sx={{ mt: 3, mb: 4, ml: 'auto', display: 'block' }}
        />
      )}
    </MainContainer>
  );
};
