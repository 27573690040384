import React, { useEffect, useState } from 'react';
import validator from 'validator';
import _ from 'lodash';

export const FormValidator = ({
  id,
  children,
  validationConfig,
  name,
  changed,
  setErrorsHandler,
  fieldInObject = () => {},
}) => {
  const [errors, setErrors] = useState({});

  const validateValue = (value, name, validationParams, allValues) => {
    const errors = [];

    for (const param in validationParams) {
      if (param === 'maxLength' && value.length > validationParams[param]) {
        errors.push(
          `Максимальная длина строки не должна превышать ${validationParams[param]} символов`,
        );
      }
      if (param === 'minLength' && value.length < validationParams[param]) {
        errors.push(
          `Минимальная длина строки должна быть не менее ${validationParams[param]} символов`,
        );
      }

      if (
        param === 'isEmail' &&
        value &&
        validationParams[param] === true &&
        !validator.isEmail(value)
      ) {
        errors.push(`Поле должно содержать email`);
      }
      if (name == 'email') console.log({ c: changed });
      if (
        param === 'isRequired' &&
        validationParams[param] === true &&
        (value === null || value === undefined || value === '')
      ) {
        errors.push(`Поле обязательно для заполнения`);
      }
      if (param === 'onlyCyrilic' && !/^\p{sc=Cyrillic}*$/u.test(value)) {
        errors.push(`Поле может содержать только кириллические символы`);
      }
      if (param === 'onlyLat' && !/^\p{sc=Latin}*$/u.test(value)) {
        errors.push(`Поле может содержать только латинские символы`);
      }
      if (
        param === 'onlyCyrilicAndNumbers' &&
        !/^[а-яА-ЯёЁ0-9\s\-_]+$/.test(value)
      ) {
        errors.push(
          `Поле может содержать только кириллические символы и цифры`,
        );
      }
      if (param === 'withoutSpaces' && /\s/g.test(value)) {
        errors.push(`Поле не может содержать пробелы`);
      }
      if (param === 'onlyLatAndNumbers' && !/^[A-Za-z0-9\s\-_]+$/.test(value)) {
        errors.push(`Поле может содержать только латинские символы и цифры`);
      }

      if (param === 'onlyNumbers' && !/^[0-9]+$/.test(value) && value != '') {
        errors.push(`Поле может содержать только цифры`);
      }

      if (
        param === 'isUniq' &&
        allValues.filter((av) => av.name === name && av.value === value)
          .length > 1
      ) {
        errors.push(`Значение поля должно быть уникальным`);
      }

      if (param === 'isIp' && !validator.isIP(value)) {
        errors.push(`Поле должно содержать IP адрес`);
      }

      if (param === 'isInn' && value?.length === 11) {
        errors.push(`Поле должно содержать 10 или 12 цифр`);
      }

      if (param === 'isUrl' && !validator.isURL(value)) {
        errors.push(`Поле должно содержать url`);
      }
      if (
        value &&
        param === 'isIpOrUrl' &&
        (!validator.isURL(value) || !validator.isURL(value))
      ) {
        errors.push(`Поле должно содержать url или IP адрес`);
      }
      if (
        param === 'maxValue' &&
        parseFloat(value) > +validationParams[param]
      ) {
        errors.push(
          `Максимальное значение в поле может быть ${validationParams[param]}`,
        );
      }
      if (param === 'minValue' && +value < validationParams[param]) {
        errors.push(
          `Минимальное значение в поле может быть ${validationParams[param]}`,
        );
      }

      if (
        param === 'requireUppercaseSymbol' &&
        (!Array.from(value).some((s) => s === s.toUpperCase()) ||
          Array.from(value).filter((s) => s === s.toUpperCase()).length ===
            value.length)
      ) {
        errors.push(`Строка должна содержать заглавные и прописные буквы`);
      }

      if (
        param === 'requireNumberSymbol' &&
        !/(?=.*\d)(?=.*[a-z])/i.test(value)
      ) {
        errors.push(`Строка должна содержать буквы и цифры`);
      }

      if (
        param === 'isIpMask' &&
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          value,
        )
      ) {
        errors.push(`Строка должна содержать ip маску`);
      }

      if (
        param === 'oneOfList' &&
        validationParams[param]?.length &&
        !validationParams[param]?.filter((item) => value === item).length > 1
      ) {
        errors.push(
          `Значение поля должно быть одним из - ${validationParams[param].join(
            ', ',
          )}`,
        );
      }

      if (
        param === 'customUniq' &&
        validationParams[param]?.length &&
        validationParams[param]?.filter((item) => item === value).length > 1
      ) {
        errors.push(`Поле должно быть уникальным в рамках оборудования`);
      }
      if (
        value &&
        param === 'joinStringIsIP' &&
        value.split(',').some((s) => !validator.isIP(s))
      ) {
        errors.push(`Ip адреса должны быть указаны через запятую без пробелов`);
      }

      if (
        param === 'isMobilePhone' &&
        value &&
        (value.indexOf('+') !== 0 || value.replaceAll('_', '').length < 16)
      ) {
        errors.push(`Поле должно содержать номер телефона`);
      }
      if (
        param === 'isPort' &&
        validationParams[param] === true &&
        !validator.isPort(value)
      ) {
        errors.push(`Поле должно содержать порт`);
      }

      if (param === 'matchWith' && validationParams[param] !== value) {
        errors.push(`Пароли не совпадают`);
      }
    }
    return errors.length
      ? {
          [name]: {
            text: errors.join('; '),
          },
        }
      : null;
  };
  const formValidationHandler = () => {
    let inputs = document.forms[name].getElementsByTagName('input');
    let elemets = Array.prototype.slice.call(inputs);
    const errorArray = [];
    if (elemets.length) {
      elemets = elemets.filter((i) => i.name);

      const allValues = elemets.map((i) => ({ name: i.name, value: i.value }));

      for (const input of elemets) {
        if (input.name in changed || fieldInObject(input.name)) {
          const config = validationConfig[input.name];

          const result = validateValue(
            input.value,
            input.name,
            config,
            allValues,
          );
          if (result) {
            errorArray.push(result);
          }
        }
      }
    }
    let final = {};

    for (const e of errorArray) {
      final = { ...final, ...e };
    }

    setErrors(final);
  };

  useEffect(() => {
    setErrorsHandler(errors);
  }, [errors]);

  return (
    <form id={id} name={name} onChange={() => formValidationHandler()}>
      {children}
    </form>
  );
};
