import { Button, TextField, Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveNewSetting } from '../../api/SettingsApi';
import { useApi } from '../../hooks/useApi';
import { setAlert } from '../../store/alert.store';
import { SettingsValidationConfig } from '../../utils/formValidatorConfigs/config';
import { FormValidator } from '../FormValidator/FormValidator';
import { getAllSettings } from '../../api/SettingsApi';
import { FooterButtonBar } from '../Default/FooterButtonBar/FooterButtonBar';

export const VideoServersSettingsFrom = ({
  wasChange,
  setChange,
  permission,
  ...props
}) => {
  const [settingsState, setSettingsState] = useState({});
  const [validateErrors, setValidateErrors] = useState([]);
  const settingsApi = useApi(saveNewSetting);

  const [formDisabled, setFormDisabled] = useState(true);

  const config = useMemo(() => SettingsValidationConfig(), []);

  const [settings, setSettings] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    getAllSettings()
      .then((settings) => setSettings(settings))
      .catch((error) =>
        dispatch(setAlert([{ type: 'error', text: error.message }])),
      );
  }, []);

  useEffect(() => {
    const configKeys = Object.keys(config);

    if (wasChange && Object.keys(validateErrors).length === 0) {
      let canSave = true;
      configKeys.forEach((key) => {
        if (canSave && config[key]?.isRequired && !(key in settingsState)) {
          canSave = false;
        }
      });
      setFormDisabled(!canSave);
    } else if (!formDisabled) {
      setFormDisabled(true);
    }
  }, [settingsState, validateErrors]);

  const handlerSaveSettings = async () => {
    try {
      await settingsApi.sendRequest(settingsState);
      setChange(false);
      setFormDisabled(true);
      dispatch(
        setAlert({ type: 'success', text: 'Настройки обновлены', status: 200 }),
      );
    } catch (error) {}
  };

  useEffect(() => {
    setSettingsState(settings);
  }, [settings]);

  const handlerError = (data) => {
    setValidateErrors(data);
  };

  const onChange = (value, fieldName) => {
    if (!wasChange) {
      setChange(true);
    }
    setSettingsState({ ...settingsState, [fieldName]: value });
  };

  return (
    <div>
      <FormValidator
        name="rtsp-server-form-name"
        validationConfig={config}
        changed={settingsState}
        setErrorsHandler={(data) => handlerError(data)}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Логин доступа к камере"
              disabled={!permission?.edit}
              variant="outlined"
              sx={{ mt: 2 }}
              name="ipCamLogin"
              required={config.ipCamLogin.isRequired}
              error={validateErrors?.ipCamLogin?.text}
              helperText={validateErrors?.ipCamLogin?.text}
              value={settingsState.ipCamLogin}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'ipCamLogin')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Пароль доступа к камере"
              disabled={!permission?.edit}
              variant="outlined"
              sx={{ mt: 2 }}
              name="ipCamPassword"
              required={config.ipCamPassword.isRequired}
              error={validateErrors?.ipCamPassword?.text}
              helperText={validateErrors?.ipCamPassword?.text}
              value={settingsState.ipCamPassword}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'ipCamPassword')}
            />
          </Grid>
        </Grid>

        <FooterButtonBar
          showDeleteButton={false}
          showUpdateButton={false}
          showCancelButton={false}
          createButtonText="Сохранить"
          showCreateButton={permission?.edit}
          disabledCreateButton={!permission?.edit || formDisabled}
          createButtonHandler={() => handlerSaveSettings()}
          loading={settingsApi.loading}
        />
      </FormValidator>
    </div>
  );
};
