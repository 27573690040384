import {
  Box,
  Button,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './UploadFileModal.module.scss';
import InputMask from 'react-input-mask';
import { KeyWriterValidationConfig } from '../../utils/formValidatorConfigs/config';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { useApi } from '../../hooks/useApi';
import { uploadKeyWriterFile } from '../../api/KeyWriter';
import { setAlert } from '../../store/alert.store';
import { useDispatch } from 'react-redux';
import { URLS } from '../../utils/urls.util';
import { useNavigate } from 'react-router-dom';

export const UploadFileModal = ({ open, handleClose, versionList }) => {
  const [version, setVersion] = useState();
  const [validateErrors, setValidateErrors] = useState([]);
  const [file, setFile] = useState();
  const [versionValidationError, setVersionValidationError] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const uploadKeyWriterFileApi = useApi(uploadKeyWriterFile);
  const dispatch = useDispatch();

  useEffect(() => {
    const regex = new RegExp(/^((\d{1,3})\.)((\d{1,3})\.)(\*|(\d{1,3}))$/gm);

    if (version) {
      if (!regex.test(version)) {
        setVersionValidationError(
          'Версия должна соответствовать диапазону от 0.0.0 до 999.999.999',
        );
      } else {
        const splitVersion = version.split('.');
        if (splitVersion.some((v) => v.length > 1 && v.charAt(0) == '0')) {
          setVersionValidationError(
            'Число до точки больше одного символа. Ставить ноль в начало числа нельзя.',
          );
        } else if (versionList.includes(version)) {
          setVersionValidationError('Такой номер версии уже существует');
        } else {
          setVersionValidationError(false);
        }
      }
    }
  }, [version]);
  const sendKeyWriter = async () => {
    await uploadKeyWriterFileApi.sendRequest(version, file);
    dispatch(setAlert({ status: 200, text: 'Версия загружена' }));
    handleClose();
  };

  useEffect(() => {
    if (file) {
      if (
        ![
          'application/vnd.microsoft.portable-executable',
          'application/octet-stream',
          'application/x-msdos-program',
          'application/x-msdownload',
        ].includes(file.type)
      ) {
        setFile(null);
        setUploadError(true);
      } else {
        setUploadError(false);
      }
    }
  }, [file]);
  const config = useMemo(() => KeyWriterValidationConfig(), []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box className={styles.UploadFileModal}>
        <Typography id="modal-modal-title" variant="h4" component="h4">
          Добавление версии
        </Typography>

        <div
          className={styles.UploadFileField}
          style={uploadError ? { borderColor: 'red' } : {}}
        >
          <input
            type="file"
            name="file"
            onChange={({ target }) => setFile(target.files[0])}
          />
          <div
            style={{
              width: '70%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {!file ? (
              <p>Перетащите файл сюда или воспользуйтесь кнопкой</p>
            ) : (
              <p>Загружен файл - {file?.name}</p>
            )}
            {!file ? (
              <Typography
                color="primary"
                sx={{
                  textTransform: 'uppercase',
                  color: '#1976d2 !important',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <AttachFileOutlinedIcon sx={{ mb: '-5px' }} /> Загрузить
              </Typography>
            ) : (
              <Button
                variant="text"
                color="error"
                onClick={() => {
                  setFile(null);
                  setUploadError(false);
                }}
              >
                Удалить файл
              </Button>
            )}
          </div>
        </div>
        {uploadError && (
          <p
            style={{
              display: 'block',
              textAlign: 'center',
              fontSize: '12px',
              fontWeight: 'bold',
              color: 'red',
            }}
          >
            Файл может быть только в формате .exe
          </p>
        )}
        <p
          style={{
            display: 'block',
            textAlign: 'center',
            fontSize: '12px',
            color: '#aaa',
          }}
        >
          Принимаются файлы в формате .exe
        </p>

        <Tooltip
          placement="top-start"
          title="Номер версии должен соответствовать типу 0.0.0, где максимальное количество символов до точки равно трем. Если символ до точки один, то число может быть равно нулю. Если число до точки больше одного символа, то ставить ноль в начало числа запрещено. Пример корректной записи: 0.11.111 или 301.0.55. Пример ошибки: 01.011.111 или 008.00.20."
        >
          <TextField
            value={version}
            fullWidth
            label="Номер версии"
            variant="outlined"
            placeholder="0.0.0"
            sx={{ mt: 2 }}
            name="phone"
            required={config?.version?.isRequired}
            error={!!validateErrors?.version?.text || versionValidationError}
            helperText={validateErrors?.version?.text || versionValidationError}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) => setVersion(target.value)}
          />
        </Tooltip>

        <Button
          variant="outlined"
          color="primary"
          sx={{ mt: 3, ml: 'auto' }}
          onClick={handleClose}
        >
          Отмена
        </Button>

        <LoadingButton
          variant="contained"
          color="success"
          loading={uploadKeyWriterFileApi.loading}
          sx={{ ml: 1, mt: 3 }}
          onClick={() => sendKeyWriter()}
          disabled={!version || !file || uploadError || versionValidationError}
        >
          Добавить
        </LoadingButton>
      </Box>
    </Modal>
  );
};
