import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { URLS } from '../../utils/urls.util';
import { getAllKeycloakGroups, getOneUser } from '../../api/UserApi';
import { UserFrom } from '../UserFrom/UserFrom';
import { useApi } from '../../hooks/useApi';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';
import { UserInfo } from '../UserInfo/UserInfo';
import { getMe } from '../../api/AuthApi';

export const SingleUser = ({ id, permission }) => {
  const getOneUserApi = useApi(getOneUser);
  const getAllKeycloakGroupsApi = useApi(getAllKeycloakGroups);
  const getMeApi = useApi(getMe);
  const [isCreate, setIsCreate] = useState(false);
  const [user, setUser] = useState({});
  const [groups, setGroups] = useState([]);
  const [name, setName] = useState('');

  useEffect(() => {
    if (id !== 'create') {
      getMeApi.sendRequest().then((result) => {
        if (result.id !== id) {
          getOneUserApi.sendRequest(id).then((user) => setUser(user));
        } else {
          if (typeof result?.groups[0] == 'object') {
            result.groups = result?.groups?.map((g) => g.name);
          }
          setUser(result);
        }
      });
    } else {
      setIsCreate(true);
    }
    getAllKeycloakGroupsApi
      .sendRequest({ first: 0, max: -1 })
      .then(([result, count]) => setGroups(result.map((r) => r.name)));
  }, []);

  useEffect(() => {
    const firstName = user.firstName;
    const lastName = user.lastName;

    const fullName =
      (firstName ? firstName : '') + ' ' + (lastName ? lastName : '');

    setName(fullName?.trim() || '');
  }, [user]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={URLS.USERS}>
      Пользователи
    </Link>,
    <Typography key="3" color="text.primary">
      {name || 'Новый пользователь'}
    </Typography>,
  ];
  return (
    (id || isCreate) && (
      <FullPageLayout
        seoTitle={'Пользователь - ' + (name || 'Новый пользователь')}
        pageTitle={name || 'Добавить пользователя'}
        obj={{}}
        infoComponent={<UserInfo user={user} />}
        isCreated={isCreate}
        breadcrumbs={breadcrumbs}
      >
        <UserFrom
          permission={permission}
          isCreate={isCreate}
          user={user}
          groups={groups}
          key="1"
        />
      </FullPageLayout>
    )
  );
};
