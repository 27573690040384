import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import AuthService from '../../../api/KeycloakApi';

export const HttpError = ({ statusCode, messageText, showAction }) => {
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid item xs={6} sx={{ textAlign: 'center' }}>
        <Typography as="h1" sx={{ fontWeight: 'bold', fontSize: '30px' }}>
          {statusCode}
        </Typography>
        <Typography>{messageText}</Typography>
        {showAction && (
          <Button
            variant="outlined"
            color="inherit"
            sx={{ mt: 3 }}
            onClick={() => AuthService.doLogout()}
          >
            Сменить аккаунт
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
