import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { SingleCompanyRates } from '../components/SingleCompany/SingleCompanyRates';
import { pagesIds } from '../utils/pages';
import { URLS } from '../utils/urls.util';

export const SingleCompanyRatesPage = () => {
  const { id } = useParams();

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  return (
    <MainContainer pageId={pagesIds.company} setPermission={setPermission}>
      <SingleCompanyRates permission={permission} id={id} tab={1} />
    </MainContainer>
  );
};
