import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { SingleDeviceRevisionsTable } from '../components/SingleDeviceRevisionsTable/SingleDeviceRevisionsTable';
import { pagesIds } from '../utils/pages';
import { URLS } from '../utils/urls.util';

export const SingleDeviceRevisionsPage = () => {
  const { id } = useParams();

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);


  return (
    <MainContainer pageId={pagesIds.devices} setPermission={setPermission}>
      <SingleDeviceRevisionsTable permission={permission} id={id} />
    </MainContainer>
  );
};
