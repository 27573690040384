import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { URLS } from '../utils/urls.util';
import {
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { setAlert } from '../store/alert.store';
import { useDispatch } from 'react-redux';
import { useApi } from '../hooks/useApi';
import {
  createDeviceUpdate,
  deleteDeviceUpdates,
  getOneDeviceUpdates,
  updateDeviceUpdate,
  uploadDeviceUpdatesFile,
} from '../api/DeviceUpdatesApi';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { pagesIds } from '../utils/pages';
import { UploadFileDeviceModal } from '../components/UploadFileModal/UploadFileDeviceModal';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { DeviceUpdatesValidationConfig } from '../utils/formValidatorConfigs/config';
import { FormValidator } from '../components/FormValidator/FormValidator';
import { ActionApproveV2 } from '../components/Default/ActionApprove/ActionApproveV2';
import { FooterButtonBar } from '../components/Default/FooterButtonBar/FooterButtonBar';

export const SingleDeviceUpdatesPage = () => {
  const { id } = useParams();
  const [openApprove, setOpenApprove] = useState(false);
  const [permission, setPermission] = useState({});
  const [isCreate, setIsCreate] = useState(true);
  const [deviceUpdate, setDeviceUpdate] = useState({});
  const [openAddFileModal, setOpenAddFileModal] = useState(false);
  const [file, setFile] = useState(null);
  const getOneDeviceUpdatesApi = useApi(getOneDeviceUpdates);
  const [validateErrors, setValidateErrors] = useState({});
  const uploadDeviceUpdatesFileApi = useApi(uploadDeviceUpdatesFile);
  const createDeviceUpdateApi = useApi(createDeviceUpdate);
  const updateDeviceUpdateApi = useApi(updateDeviceUpdate);
  const deleteDeviceUpdatesApi = useApi(deleteDeviceUpdates);

  const config = useMemo(() => DeviceUpdatesValidationConfig(), []);

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (id !== 'create') {
      getOneDeviceUpdatesApi
        .sendRequest(id)
        .then((device) => setDeviceUpdate(device))
        .catch((error) =>
          dispatch(setAlert([{ type: 'error', text: error.message }])),
        );
      setIsCreate(false);
    } else if (id == 'create') {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [id]);

  const createDeviceUpdateHandler = async () => {
    const { location, originalname, fileName } =
      await uploadDeviceUpdatesFileApi.sendRequest(id, file);

    if (location && originalname) {
      const result = await createDeviceUpdateApi.sendRequest({
        Name: deviceUpdate?.name,
        DeviceType: deviceUpdate?.deviceType,
        Version: deviceUpdate?.version,
        FileUrl: location,
        FileName: fileName,
      });
      if (result) {
        dispatch(
          setAlert({
            text: 'Обновление оборудования успешно добавлено',
            status: 201,
          }),
        );
        navigate(URLS.DEVICE_UPDATES_PAGE);
      }
    }
  };

  const updateDeviceUpdateHandler = async () => {
    let location;
    let originalname;
    let fileName;
    if (file) {
      const res = await uploadDeviceUpdatesFileApi.sendRequest(id, file);
      location = res.location;
      originalname = res.originalname;
      fileName = res.fileName;
    } else if (deviceUpdate?.fileName) {
      location = deviceUpdate?.fileUrl;
      originalname = deviceUpdate?.fileName;
      fileName = deviceUpdate?.fileName;
    }

    const result = await updateDeviceUpdateApi.sendRequest(deviceUpdate?.id, {
      Name: deviceUpdate?.name,
      DeviceType: deviceUpdate?.deviceType,
      Version: deviceUpdate?.version,
      FileUrl: location,
      FileName: fileName,
    });
    dispatch(
      setAlert({
        text: 'Обновление оборудования успешно обновлено',
        status: 201,
      }),
    );
    navigate(URLS.DEVICE_UPDATES_PAGE);
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={URLS.DEVICE_UPDATES_PAGE}
    >
      Обновления оборудования
    </Link>,
    <Typography key="3" color="text.primary">
      {isCreate ? 'Добавление нового обновления' : deviceUpdate.name}
    </Typography>,
  ];
  return (
    <MainContainer
      pageId={pagesIds.devices}
      titleAs="h4"
      titleStyles={{ marginTop: '30px' }}
      title={isCreate ? 'Добавление нового обновления' : deviceUpdate.name}
      setPermission={setPermission}
      breadcrumbs={breadcrumbs}
    >
      <Stack sx={{ minHeight: '65vh' }}>
        <FormValidator
          id="device-updates-form"
          name="device-updates-form-name"
          validationConfig={config}
          changed={deviceUpdate}
          setErrorsHandler={(data) => setValidateErrors(data)}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                label="Название обновления"
                name="name"
                InputLabelProps={{
                  shrink: true,
                }}
                required={config?.name?.isRequired}
                error={!!validateErrors?.name?.text}
                helperText={validateErrors?.name?.text}
                fullWidth
                disabled={isCreate ? !permission?.edit : !permission?.edit}
                value={deviceUpdate?.name}
                onChange={({ target: { value } }) =>
                  setDeviceUpdate({ ...deviceUpdate, name: value })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                name="deviceType"
                label="Тип оборудования"
                InputLabelProps={{
                  shrink: true,
                }}
                required={config?.deviceType?.isRequired}
                error={!!validateErrors?.deviceType?.text}
                helperText={validateErrors?.deviceType?.text}
                fullWidth
                disabled={isCreate ? !permission?.edit : !permission?.edit}
                value={deviceUpdate?.deviceType}
                onChange={({ target: { value } }) =>
                  setDeviceUpdate({ ...deviceUpdate, deviceType: value })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                label="Версия"
                name="version"
                InputLabelProps={{
                  shrink: true,
                }}
                required={config?.version?.isRequired}
                error={!!validateErrors?.version?.text}
                helperText={validateErrors?.version?.text}
                fullWidth
                disabled={isCreate ? !permission?.edit : !permission?.edit}
                value={deviceUpdate?.version}
                onChange={({ target: { value } }) =>
                  setDeviceUpdate({ ...deviceUpdate, version: value })
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ my: 3 }}>
            <>
              {deviceUpdate?.fileUrl || file?.name ? (
                <Stack direction="row" spacing={0} alignItems="center">
                  <GetAppOutlinedIcon color="primary" />
                  {deviceUpdate?.fileUrl ? (
                    <a
                      href={deviceUpdate?.securedFileUrl}
                      download
                      target="blank"
                      style={{ color: 'rgb(25, 118, 210)' }}
                    >
                      <Typography as="p" variant="p" color="primary">
                        {deviceUpdate?.fileName}
                      </Typography>
                    </a>
                  ) : (
                    <Typography as="p" variant="p" color="primary">
                      {deviceUpdate?.fileName || file?.name}
                    </Typography>
                  )}

                  {permission?.edit && (
                    <IconButton
                      color="inherit"
                      size="small"
                      disabled={!permission?.edit}
                      sx={{ opacity: 0.3, ml: 1 }}
                      onClick={() => {
                        setFile(null);
                        setDeviceUpdate({
                          ...deviceUpdate,
                          fileUrl: null,
                          fileName: null,
                        });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Stack>
              ) : (
                <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
                  {permission?.edit && (
                    <Button
                      variant="outlined"
                      onClick={() => setOpenAddFileModal(true)}
                      disabled={!permission?.edit}
                    >
                      Добавить файл
                    </Button>
                  )}
                </Stack>
              )}
              <UploadFileDeviceModal
                fileAddedHandler={(file) => {
                  setFile(file);
                }}
                helperText=""
                errorText="Размер файла не должен быть больше 15мб."
                maxFileSize={15000000}
                handleClose={() => {
                  setOpenAddFileModal(false);
                }}
                open={openAddFileModal}
              />
            </>
          </Grid>
        </FormValidator>
      </Stack>

      <ActionApproveV2
        open={openApprove}
        title="Вы действительно хотите удалить это обновление оборудования?"
        handleClose={() => setOpenApprove(false)}
        showDescription={false}
        handleSuccess={() =>
          deleteDeviceUpdatesApi.sendRequest(id).then((result) => {
            dispatch(
              setAlert({
                text: 'Обновление оборудования успешно удалено',
                status: 200,
              }),
            );
            navigate(URLS.DEVICE_UPDATES_PAGE);
          })
        }
        handleSuccessText="Удалить"
        handleCloseText="Отмена"
      />
      <FooterButtonBar
        showDeleteButton={permission?.delete && !isCreate}
        deleteButtonHandler={() => setOpenApprove(true)}
        disabledDeleteButton={!permission?.delete}
        cancelButtonHandler={() => navigate(URLS?.DEVICE_UPDATES_PAGE)}
        showCancelButton={true}
        updateButtonHandler={() => updateDeviceUpdateHandler()}
        showUpdateButton={!isCreate && permission?.edit}
        disabledUpdateButton={
          !permission?.edit ||
          Object?.keys(validateErrors)?.length ||
          !!!deviceUpdate?.version ||
          !!!deviceUpdate?.name ||
          !!!deviceUpdate?.deviceType ||
          !(deviceUpdate?.fileUrl || file?.name)
        }
        showCreateButton={permission?.create && isCreate}
        createButtonHandler={() => createDeviceUpdateHandler()}
        disabledCreateButton={
          !permission?.edit ||
          Object?.keys(validateErrors)?.length ||
          !!!deviceUpdate?.version ||
          !!!deviceUpdate?.name ||
          !!!deviceUpdate?.deviceType ||
          !(deviceUpdate?.fileUrl || file?.name)
        }
        loading={
          getOneDeviceUpdatesApi?.loading ||
          uploadDeviceUpdatesFileApi?.loading ||
          createDeviceUpdateApi?.loading ||
          updateDeviceUpdateApi?.loading ||
          deleteDeviceUpdatesApi?.loading
        }
      />
    </MainContainer>
  );
};
